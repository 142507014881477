import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card } from "@mui/material";
import { tokens, themeSettings } from "../../theme";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { AuthContext } from '../../context/AuthContext';
import { HeadContext } from '../../context/HeadContext';

import config from "../../config";

const CreateGroup = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);

  const [ groupName, setGroupName ] = useState("");
  const [ groupRadio, setGroupRadio ] = useState("");
  const [ ansName, setAnsName ] = useState("");
  const [ ansNumber, setAnsNumber ] = useState("");
  const [ ansEmail, setAnsEmail ] = useState("");

  const save = () => {
    if (groupName === "") {
      return;
    }

    fetch(
      `${config.baseurl}/group/create_group`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          name: groupName,
          radio: groupRadio,
          ansName: ansName,
          ansNumber: ansNumber,
          ansEmail: ansEmail
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Parse the response JSON
    })
    .then((data) => {
      // Handle the response data here
      console.log(data);
      navigate(`/groups/${data.data.id}`);
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      alert("Fetch error:", error);
    });
  }

  return (
    <>
      <Box width="600px" display="flex" flexDirection="column">
        <Typography variant="h4" fontWeight="600" p="0em 0 0.5em 0">
          Gruppe information
        </Typography>
        <TextField 
          label="Gruppe navn"
          variant="outlined" 
          required
          value={groupName}
          onChange={(e) => {setGroupName(e.target.value)}}
          sx={{ pb: "10px" }}
        />
        <br />
        <TextField 
          label="Radio kanal"
          variant="outlined" 
          value={groupRadio}
          onChange={(e) => {setGroupRadio(e.target.value)}}
        />
      </Box>
      <hr />
      <Box width="600px" display="flex" flexDirection="column">
        <Typography variant="h4" fontWeight="600" p="1em 0 0.5em 0">
          Ansvarlig 
        </Typography>
        <TextField 
          label="Navn"
          variant="outlined" 
          value={ansName}
          onChange={(e) => {setAnsName(e.target.value)}}
          sx={{ pb: "10px" }}
        />
        <br />
        <Box display="flex" gap="2em" width="100%">
          <TextField 
            label="Telefonnummer"
            variant="outlined" 
            value={ansNumber}
            onChange={(e) => {setAnsNumber(e.target.value)}}
            sx={{ flex: 1 }}
          sx={{ pb: "10px" }}
          />
          <TextField 
            label="Email"
            variant="outlined" 
            value={ansEmail}
            onChange={(e) => {setAnsEmail(e.target.value)}}
            sx={{ flex: 1 }}
          />
        </Box>
        <br />
        <Button
          variant="contained" 
          size="large" 
          sx={{
            width: "100%",
            height: "30px",
            marginTop: "1em"
          }}
          onClick={save}
        >Opret</Button>
      </Box>
    </>
  )
}

export default CreateGroup;
