import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  TextField,
  Card,
  TableSortLabel,
} from "@mui/material";
import { tokens, themeSettings } from "../../../theme";
import Paper from "@mui/material/Paper";
import { DataGrid, GridFooter } from '@mui/x-data-grid';
import EditShiftModal from "../../../components/editShiftModal";
import { format_seconds } from "../../../utils/numbers";

import { AuthContext } from "../../../context/AuthContext";
import config from "../../../config";

const columns = [
  { 
    field: 'id', 
    headerName: 'ID', 
    type: 'number',
    flex: 0.5,
  },
  {
    field: 'group_name',
    headerName: 'Gruppe',
    editable: false,
    flex: 0.7,
  },
  {
    field: 'person_id',
    headerName: 'Person ID',
    type: 'number',
    editable: false,
    options: {
      customBodyRender: value => <span>{`${value}`.replace(",", "")}%</span>
    },
    flex: 1,
  },
  {
    field: 'person_name',
    headerName: 'Person navn',
    editable: false,
    flex: 1,
  },
  {
    field: 'start',
    headerName: 'Start',
    editable: false,
    flex: 0.5,
  },
  {
    field: 'stop',
    headerName: 'Stop',
    editable: false,
    flex: 0.5,
  },
  {
    field: 'area',
    headerName: 'Område',
    editable: false,
    flex: 1,
  },
  {
    field: 'length',
    headerName: 'Længde',
    type: 'number',
    editable: false,
    flex: 0.5,
    renderCell: (params) => {
      if (params.aggregation && !params.aggregation.hasCellUnit) {
        return params.formattedValue;
      }
      return format_seconds(parseInt(params.formattedValue.replace(",", "")*60), "hours");
    },
  },
  {
    field: 'shiftlimit',
    headerName: 'Vagtbegrænsning',
    editable: false,
    flex: 0.7,
  },
  {
    field: 'notified',
    headerName: 'Sms sendt',
    editable: false,
    flex: 0.5,
    renderCell: (params) => {
      if (params.aggregation && !params.aggregation.hasCellUnit) {
        return params.formattedValue;
      }
      return params.formattedValue == "1" ? "✅" : "❌";
    },
  }
];


function CustomFooter() {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        <Typography 
          pl={2}
          fontWeight={500}
        >Vagt Oversigt</Typography>
      </div>
      <div>
        <GridFooter />
      </div>
    </div>
  );
}

const ShiftTable = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const auth = useContext(AuthContext);

  const [ isOpen, setIsOpen ] = useState(false);
  const [ modalData, setModalData ] = useState({});
  const [ count, setCount ] = useState(0);

  const [data, setData] = useState([]);
  const [ people, setPeople ] = useState([]);

  const [ filterName, setFilterName ] = useState("");
  const [ filterGroup, setFilterGroup ] = useState("");
  const [ filterArea, setFilterArea ] = useState("");

  useEffect(() => {
    fetch(`${config.baseurl}/group/get_shifts`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data);
        setData(data.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        alert("Fetch error:", error);
      });
  }, []);


  useEffect(() => {
    fetch(`${config.baseurl}/person/get_people`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data);
        setPeople(data.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        alert("Fetch error:", error);
      });
  }, []);

  return (
    <>
      <EditShiftModal 
        isOpen={isOpen} 
        setIsOpen={setIsOpen} 
        data={modalData} 
        doReload={() => {setCount(count+1)}} 
        people={people} 
        group={0} 
      />

      <Box display="flex" gap="2em">
        <Box>
          <Typography>
            Frivillig Navn
          </Typography>
          <TextField 
            style={{
              marginBottom: "15px",
              padding: "2px",
              width: "300px",
              height: "30px",
              transform: "translateY(5px)"
            }}
            sx={{
              input: {padding: "0px", paddingLeft: "10px", height: "30px"}
            }}
            variant="outlined" 
            value={filterName}
            onChange={(e) => {setFilterName(e.target.value)}}
          />
        </Box>
        <Box>
          <Typography>
            Gruppe Navn
          </Typography>
          <TextField 
            style={{
              marginBottom: "15px",
              padding: "2px",
              width: "300px",
              height: "30px",
              transform: "translateY(5px)"
            }}
            sx={{
              input: {padding: "0px", paddingLeft: "10px", height: "30px"}
            }}
            variant="outlined" 
            value={filterGroup}
            onChange={(e) => {setFilterGroup(e.target.value)}}
          />
        </Box>
        <Box>
          <Typography>
            Mødested
          </Typography>
          <TextField 
            style={{
              marginBottom: "15px",
              padding: "2px",
              width: "300px",
              height: "30px",
              transform: "translateY(5px)"
            }}
            sx={{
              input: {padding: "0px", paddingLeft: "10px", height: "30px"}
            }}
            variant="outlined" 
            value={filterArea}
            onChange={(e) => {setFilterArea(e.target.value)}}
          />
        </Box>
      </Box>
      <Box
        style={{ height: "calc(100vh - 170px)" }}
      >
        <DataGrid
          rows={
            data.filter((row) => (
              row.person_name.toLowerCase().includes(filterName.toLowerCase()) 
                && row.group_name.toLowerCase().includes(filterGroup.toLowerCase())
                && row.area.toLowerCase().includes(filterArea.toLowerCase())
            ))
          }
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          density="compact"
          pageSizeOptions={[20, 50, 100]}
          disableRowSelectionOnClick
          disableSelectionOnClick
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
             outline: "none !important",
            },
            "& .empty": {
              backgroundColor: colors.grey[900]+"55",
            }
          }}
          onRowDoubleClick={(params, event, details) => {
            console.log(params); 
            setModalData({
              ...params.row,
              personId: params.row.person_id,
              name: params.row.person_name,
              number: params.row.person_number,
            }); 
            setIsOpen(true)
          }}
          components={{
            footer: CustomFooter
          }}
          getCellClassName={(params) => {
            if (params.field === 'person_id' || params.field === 'person_name') {
              return params.value === "" || params.value === null ? 'empty' : '';
            }
          }}
        />
      </Box>
    </>
  );
};

export default ShiftTable;

