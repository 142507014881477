import React, { useState, useEffect, useContext, useCallback } from "react";
import { Typography, useTheme, Chip , Box} from "@mui/material";
import { tokens } from "../theme.js";

const Hider = ({ text }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [ isShowen, setIsShowen ] = useState(false)

  if (isShowen) {
    return (
      <Box
        width="150px"
      > 
        <Typography 
          fontWeight={500}
        >{text}</Typography>
      </Box>
    )
  } else {
    return (
      <Box 
        width="150px"
        height="20px"
        borderRadius="3px"
        backgroundColor={colors.grey[800]}
        onClick={() => (setIsShowen(true))}
        sx={{ "cursor": "pointer", ":hover": {backgroundColor: colors.grey[800]+"cc"} }}
      />
    )
  }
};

export default Hider;
