import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card, FormControlLabel, RadioGroup, Radio, Checkbox, Dialog, DialogTitle, DialogActions, DialogContent, Select, MenuItem, Modal } from "@mui/material";
import { tokens, themeSettings } from "../../../theme";
import { useState, useEffect, useContext, useRef } from "react";

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import LoadingButton from '@mui/lab/LoadingButton';

import { AuthContext } from '../../../context/AuthContext';
import { HeadContext } from '../../../context/HeadContext';
import { useNavigate, useParams } from 'react-router-dom';

import { format_seconds } from "../../../utils/numbers";
import { DataGrid, GridFooter } from '@mui/x-data-grid';
import Ticket from "../../../components/ticket";

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

import config from "../../../config";

const columns = [
  { 
    field: 'id', 
    headerName: 'ID', 
    type: 'number',
    flex: 1,
  },
  {
    field: 'code',
    headerName: 'Kode',
    editable: false,
    flex: 2,
  },
  {
    field: 'name',
    headerName: 'Deltager Navn',
    editable: false,
    flex: 2,
  },
  {
    field: 'email',
    headerName: 'Deltager Email',
    editable: false,
    flex: 2,
  },
  {
    field: 'last_scan',
    headerName: 'Sidst scannet',
    editable: false,
    flex: 1,
  },
  {
    field: 'emailed',
    headerName: 'Emailed',
    editable: false,
    flex: 1,
  }
];



const TicketLookup = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const params = useParams();

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);

  const [ data, setData ] = useState([]);
  const [ isOpen, setIsOpen ] = useState(false);
  const [ modalData, setModalData ] = useState({});
  const [ update, setUpdate ] = useState(false);

  const [ code, setCode ] = useState("");
  const [ ticketId, setTicketId ] = useState(0);
  const [ attendeeName, setAttendeeName ] = useState("");
  const [ attendeeEmail, setAttendeeEmail ] = useState("");
  const [ ticketData, setTicketData ] = useState("");

  console.log(modalData)

  useEffect(() => {
    head.setData({location: ["FrNet", "Biletter", "Scan"]});

    fetch(`${config.baseurl}/tickets/get_tickets`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data);
        setData(data.data.map((row) => ({...row, last_scan: row.last_scan !== -1 ? `${format_seconds(Math.round(+new Date()/1000 - row.last_scan))} siden` : "Ikke scannet"})));
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        alert("Fetch error:", error);
      });
  }, [update])

  const handle_save = () => {
    fetch(
      `${config.baseurl}/tickets/save_ticket`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: ticketId,
          code: code,
          attendeeName: attendeeName,
          attendeeEmail: attendeeEmail,
          data: ticketData
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
        
      setIsOpen(false);
      setUpdate(!update)
    })
  }

  const handle_get_ticket = () => {
    fetch(
      `${config.baseurl}/tickets/generate_ticket`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: ticketId,
          code: code
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Parse the response JSON
    })
    .then(data => {
      // Handle the response data here
      console.log(data);
    })
    .catch(error => {
      // Handle any errors that occurred during the fetch
      alert('Fetch error:', error);
    });
  }

  const handle_send_tickets = () => {
    fetch(
      `${config.baseurl}/tickets/bulk_send`,
      {
        method: "PUT",
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    })
  }

  function CustomFooter() {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box display="flex" gap="1em" ml="1em">
          <Button
            variant="contained" 
            size="large" 
            onClick={() => {
              setCode(""); 
              setTicketId(-1);
              setAttendeeName(""); 
              setAttendeeEmail(""); 
              setTicketData("");
              setIsOpen(true)
            }}
          >Opret billet</Button>
          <Button
            variant="contained" 
            size="large" 
            onClick={handle_send_tickets}
          >Send Biletter</Button>
        </Box>
        <div>
          <GridFooter />
        </div>
      </div>
    );
  }
  const cardSyle = {
    gridColumn: "span 3",
    borderColor: colors.grey[800],
    backgroundColor: colors.primary[400],
    display: "flex", 
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "667px"
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {setIsOpen(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card style={cardSyle} variant="outlined">
          <Box
            p={4}
            width="100%"
          >
            <Typography 
              variant="h4"
              color={colors.grey[300]}
              mb={1}
            >Billet {ticketId}</Typography>

            <Box
              display="flex"
              width="100%"
              gap="10px"
            >
              <Typography 
                fontWeight={500}
                width="68px"
                lineHeight="40px"
              >Billetkode</Typography>
              <TextField 
                variant="outlined" 
                placeholder="Billetkode (tom for autogeneret)"
                onChange={event => setCode(event.target.value)} 
                value={code}
                style={{
                  marginBottom: "15px",
                  padding: "2px",
                  width: "530px",
                  height: "30px",
                  transform: "translateY(5px)"
                }}
                sx={{
                  input: {padding: "0px", paddingLeft: "10px", height: "30px"}
                }}
              />
            </Box>
            <Box
              display="flex"
              width="100%"
              gap="10px"
            >
              <Typography 
                fontWeight={500}
                width="68px"
                lineHeight="40px"
              >Deltager</Typography>
              <TextField 
                variant="outlined" 
                placeholder="Navn"
                onChange={event => setAttendeeName(event.target.value)} 
                value={attendeeName}
                style={{
                  marginBottom: "15px",
                  padding: "2px",
                  width: "260px",
                  height: "30px",
                  transform: "translateY(5px)"
                }}
                sx={{
                  input: {padding: "0px", paddingLeft: "10px", height: "30px"}
                }}
              />
              <TextField 
                variant="outlined" 
                placeholder="Email"
                onChange={event => setAttendeeEmail(event.target.value)} 
                value={attendeeEmail}
                style={{
                  marginBottom: "15px",
                  padding: "2px",
                  width: "260px",
                  height: "30px",
                  transform: "translateY(5px)"
                }}
                sx={{
                  input: {padding: "0px", paddingLeft: "10px", height: "30px"}
                }}
              />
            </Box>
            <TextField
              multiline
              placeholder="Billetdata"
              onChange={(e) => setTicketData(e.target.value)}
              value={ticketData}
              rows={5}
              style={{
                marginBottom: "15px",
                marginTop: "6px",
                padding: "2px",
              }}
              sx={{
                width: "100%"
              }}
            />
            <Button 
              variant="contained" 
              size="large" 
              color="info"
              sx={{ mt: 2 }}
              onClick={handle_save}
            >Gem</Button>
            <Button 
              variant="contained" 
              size="large" 
              sx={{ mt: 2, ml: 1}}
              onClick={() => {setIsOpen(false)}}
            >Anuller</Button>
            <Button 
              variant="contained" 
              size="large" 
              color="error"
              sx={{ mt: 2, ml: 1}}
              onClick={() => {}}
            >Slet</Button>

            <Button 
              variant="contained" 
              size="large" 
              sx={{ mt: 2, ml: 1}}
              onClick={() => {handle_get_ticket()}}
            >Send billet</Button>
          </Box>
          <Box flex="1" height="100%" position="relative">
            <IconButton 
              style={{ position: "absolute", right: 0, color: colors.grey[300] }} 
              onClick={() => {setIsOpen(false)}}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
        </Card>
      </Modal>
      <Box
        sx={{
          height: 300,
          width: '100%',
          '& .unscanned': {
            backgroundColor: colors.greenAccent[800],
            color: colors.grey[100],
          },
          '& .scanned': {
            backgroundColor: colors.redAccent[800],
            color: colors.grey[100],
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          density="compact"
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
          disableSelectionOnClick
          style={{ height: "calc(100vh - 9em)" }}
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
             outline: "none !important",
            },
          }}
          onRowDoubleClick={(params, event, details) => {
            console.log(params, event, details)
            setModalData(params); 
            setCode(params.row.code); 
            setTicketId(params.row.id);
            setAttendeeName(params.row.name); 
            setAttendeeEmail(params.row.email); 
            setTicketData(params.row.data);
            setIsOpen(true)
          }}
          components={{
            footer: CustomFooter
          }}
          getCellClassName={(params) => {
            if (params.value == null) {
              return '';
            }
            if (params.field === 'last_scan') {
              return params.value !== "Ikke scannet" ? 'unscanned' : 'scanned';
            }
            if (params.field === 'emailed') {
              return params.value !== "Nej" ? 'unscanned' : 'scanned';
            }
          }}
        />
      </Box>
    </>
  )
}

export default TicketLookup;
