import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Box,
  Typography,
  useTheme
} from "@mui/material";

import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';

import { tokens, themeSettings } from "../../../theme";
import { ResponsiveBar } from '@nivo/bar'

import config from "../../../config";

const Bar = ({data}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const cardSettings = {
    backgroundColor: colors.primary[400], 
    gridColumn: "span 6", 
    gridRow: "span 3",
    display: "flex",
    flexDirection: "column",
    padding: "1em 1em" 
  }

  return (
    <Card variant="outlined" style={cardSettings} >
      <Typography 
        fontWeight={500}
        pl="1em"
        pb="-30px"
        variant="h5"
      >Arbejdsmæssig tidsfordeling</Typography>

      <Box style={{ "text": {fill: "#FF000"}, height: "440px" }}>
        {data?.timegraph !== undefined ? (
          <BarChart
            slotProps={{ legend: { hidden: true } }}
            xAxis={[
              {
                id: 'barCategories',
                data: data.timegraph.keys,
                scaleType: 'band',
                tickMinStep: 100,
                angle: 57,
                textAnchor: 'start',
                fontSize: 12,
              },
            ]}
            series={[
              {
                data: data.timegraph.series,
                label: "Frivillige",
                color: colors.redAccent[500]
              },
            ]}
          />
        ) : (<></>)}
      </Box>
    </Card>
  )
}

export default Bar;
