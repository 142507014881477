import React, { useState, useEffect, useContext, useCallback } from "react";
import { Typography, useTheme, Chip , Box, Popover, Card, Avatar, Modal, TextField, Button, IconButton, Autocomplete } from "@mui/material";
import { tokens } from "../../../theme.js";

import TimeSelect from "../../../components/timeSelect";
import Phone from "../../../components/phone";
import Person from "../../../components/person";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Person2 from '@mui/icons-material/Person2';

import { get_time_length } from "../../../utils/timeFunc";
import { better_round } from "../../../utils/numbers";
import config from "../../../config";
import { useNavigate } from "react-router-dom";

const EditShiftModal = ({ isOpen, setIsOpen, data, doReload }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [ activeId, setActiveId ] = useState(-1);
  const [ desc, setDesc ] = useState("");
  const [ startTimeModal, setStartTimeModal ] = useState("00:00 1");
  const [ endTimeModal, setEndTimeModal ] = useState("00:00 1");
  const [ doUpdate, setDoUpdate ] = useState(false);

  useEffect(() => {
    console.log("Opned", data);
    if (isOpen) {
      setActiveId(data.id);
      setDesc(data.desc);
      setStartTimeModal(data.start);
      setEndTimeModal(data.stop);
      setDoUpdate(!doUpdate);
    }
  }, [data])

  const handleClose = () => {
    setIsOpen(false);
  }

  const cardSyle = {
    gridColumn: "span 3",
    borderColor: colors.grey[800],
    backgroundColor: colors.primary[400],
    display: "flex", 
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "667px"
  }

  const handleSaveModal = () => {
    fetch(
      `${config.baseurl}/person/update_person_shift`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: activeId,
          start: startTimeModal, 
          stop: endTimeModal, 
          desc: desc,
          length: get_time_length(startTimeModal, endTimeModal)*15,
          person_id: data?.person_id,
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setIsOpen(false);
        
      return response.json(); // Parse the response JSON
    })
    .then(data => {
      // Handle the response data here
      console.log(data);
      if (data.status) {
        doReload();
      } else {
        alert("Invalid login.");
      }
    })
    .catch(error => {
      // Handle any errors that occurred during the fetch
      console.log(error);
      alert('Fetch error:', error);
    });
  }

  if (!isOpen) {
    return <></>
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card style={cardSyle} variant="outlined">
          <Box
            p={4}
            width="100%"
          >
            <Typography 
              variant="h4"
              color={colors.grey[300]}
              mb={1}
            >Frivillig optaget</Typography>

            <Box
              display="flex"
              width="100%"
              gap="10px"
            >
              <Box>
                <Box display="flex" gap="2em">
                  <Box>
                    <TimeSelect label="Start" time={startTimeModal} setTime={setStartTimeModal} update={doUpdate}/>
                    <TimeSelect label="Slut" time={endTimeModal} setTime={setEndTimeModal} update={doUpdate} />
                  </Box>
                  <Typography 
                    fontWeight={500}
                    width="260px"
                    color={get_time_length(startTimeModal, endTimeModal)/4 <= 0 ? colors.redAccent[500] : "unset"}
                  >Vagtlængde: {better_round(get_time_length(startTimeModal, endTimeModal)/4, 2)} timer</Typography>
                </Box>
                <Box 
                  alignItems="center"
                  pb={1}
                >
                  <Typography 
                    fontWeight={500}
                    width="68px"
                  >Beskrivelse</Typography>
                  <TextField 
                    variant="outlined" 
                    multiline
                    rows={2}
                    onChange={event => setDesc(event.target.value)} 
                    value={desc}
                    style={{
                      width: "529px",
                      transform: "translateY(5px)"
                    }}
                    sx={{
                      input: {padding: "0px", paddingLeft: "10px"}
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Button 
              variant="contained" 
              size="large" 
              color="info"
              disabled={get_time_length(startTimeModal, endTimeModal)/4 <= 0}
              sx={{ mt: 2 }}
              onClick={() => {handleSaveModal()}}
            >Gem</Button>
            <Button 
              variant="contained" 
              size="large" 
              sx={{ mt: 2, ml: 1}}
              onClick={() => {setIsOpen(false)}}
            >Anuller</Button>
            <Button 
              variant="contained" 
              size="large" 
              color="error"
              sx={{ mt: 2, ml: 1}}
              onClick={() => {}}
            >Slet</Button>
          </Box>
          <Box flex="1" height="100%" position="relative">
            <IconButton 
              style={{ position: "absolute", right: 0, color: colors.grey[300] }} 
              onClick={() => {setIsOpen(false)}}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
        </Card>
      </Modal>
    </>
  )
};

export default EditShiftModal;
