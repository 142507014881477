import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  TextField,
  Card,
  TableSortLabel,
} from "@mui/material";
import { tokens, themeSettings } from "../../../theme";
import Paper from "@mui/material/Paper";
import { DataGrid, GridFooter } from '@mui/x-data-grid';
import { useNavigate, Link } from "react-router-dom";
import { formatPhoneNumber } from "../../../utils/numbers";

import { AuthContext } from "../../../context/AuthContext";
import config from "../../../config";

const columns = [
  { 
    field: 'id', 
    headerName: 'ID', 
    type: 'number',
    flex: 0.5,
  },
  {
    field: 'name',
    headerName: 'Navn',
    editable: false,
    flex: 1,
  },
  {
    field: 'ansName',
    headerName: 'Ansvarlig',
    editable: false,
    flex: 1,
  },
  {
    field: 'ansNumber',
    headerName: 'Nummer',
    type: 'number',
    editable: false,
    flex: 1,
    renderCell: (params) => {
      if (params.aggregation && !params.aggregation.hasCellUnit) {
        return params.formattedValue;
      }
      return formatPhoneNumber(params.formattedValue);
    },
  },
  {
    field: 'people',
    headerName: 'Personer',
    type: 'number',
    editable: false,
    flex: 0.5,
  },
  {
    field: 'shifts',
    headerName: 'Vagter',
    type: 'number',
    editable: false,
    flex: 0.5,
  },
  {
    field: 'radio',
    headerName: 'Radio',
    editable: false,
    flex: 0.5,
  },
  {
    field: 'zone_finished',
    headerName: 'Generet alle vager',
    editable: false,
    flex: 0.5,
    renderCell: (params) => {
      if (params.aggregation && !params.aggregation.hasCellUnit) {
        return params.formattedValue;
      }
      return params.formattedValue != "1" ? "✅" : "❌";
    },
  },
  {
    field: 'shift_finished',
    headerName: 'Uddelt alle vager',
    editable: false,
    flex: 0.5,
    renderCell: (params) => {
      if (params.aggregation && !params.aggregation.hasCellUnit) {
        return params.formattedValue;
      }
      return params.formattedValue != "1" ? "✅" : "❌";
    },
  }
];

function CustomFooter() {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        <Typography 
          pl={2}
          fontWeight={500}
        >Gruppe Oversigt</Typography>
      </div>
      <div>
        <GridFooter />
      </div>
    </div>
  );
}

const GroupTable = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const auth = useContext(AuthContext);

  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`${config.baseurl}/group/get_groups`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data);
        setData(data.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        alert("Fetch error:", error);
      });
  }, []);

  return (
    <>
      <DataGrid
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        density="compact"
        pageSizeOptions={[20, 50, 100]}
        disableRowSelectionOnClick
        disableSelectionOnClick
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          "& .empty": {
            backgroundColor: colors.grey[900]+"55",
          }
        }}
        components={{
          footer: CustomFooter
        }}
        onRowDoubleClick={(params, event, details) => {navigate(`/groups/${params.row.id}`)}}
        getCellClassName={(params) => {
          if (params.field === 'radio' || params.field === 'ansName' || params.field === 'ansNumber') {
            return params.value === "" || params.value === null ? 'empty' : '';
          }
        }}
      />
    </>
  );
};

export default GroupTable;

