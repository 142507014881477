import React, { useState, useEffect, useContext, useCallback } from "react";
import { Typography, useTheme, Chip , Box, Popover, Card, Avatar, Modal, TextField, Button, IconButton, Autocomplete, Select, MenuItem } from "@mui/material";
import { tokens } from "../theme.js";

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import config from "../config";
import { useNavigate, useLocation } from "react-router-dom";

const FeedbackModal = ({ isOpen, setIsOpen, image }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();


  const [ comment, setComment ] = useState("");

  const handleSave = () => {
    fetch(
      `${config.baseurl}/feedback`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          image: image,
          comment: comment,
        })
      }
    )
    handleClose();
  }

  const handleClose = () => {
    setIsOpen(false);
  }

  const cardSyle = {
    gridColumn: "span 3",
    borderColor: colors.grey[800],
    backgroundColor: colors.primary[400],
    display: "flex", 
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "750px",
    height: "80%",
    overflow: "scroll"
  }

  if (!isOpen) {
    return <></>
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card style={cardSyle} variant="outlined">
          <Box
            p={4}
            width="100%"
          >
            <Typography 
              variant="h4"
              color={colors.grey[300]}
              mb={1}
            >Giv feedback</Typography>
            <Typography 
              variant="h5"
              color={colors.grey[300]}
              mb={1}
            >Kommentar</Typography>
            <TextField
              onBlur={(e) => {setComment(e.target.value)}}
              placeholder="Tilføj en kommentar der beskriver hvad din feedback går ud på"
              multiline
              rows={6}
              sx={{
                width: "100%"
              }}
            />

            <Typography 
              variant="h5"
              color={colors.grey[300]}
              mb={1}
              mt={5}
            >Screenshot</Typography>
            <Box 
              sx={{
                boxShadow: 1
              }}
            >
            <img 
              width="100%" 
              height="auto" 
              src={image} 
              alt={'Screenshot'} 
            />
            </Box>

    {/*}<Typography 
              variant="h5"
              color={colors.grey[300]}
              mb={1}
              mt={5}
            >Metadata</Typography>
            <Typography mb={1}>Her kan du vælge hvilke meta dataer der bliver inkluderet i din rapport, du kan vælge linjer fra og til. Det er kun internt personale der har mulighed for at læse din rapport.</Typography>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Navn</TableCell>
                    <TableCell>Værdi</TableCell>
                    <TableCell width="25px">
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Side</TableCell>
                    <TableCell>{location.pathname}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>{*/}
            <Box>
              <Button 
                variant="contained" 
                size="large" 
                color="info"
                sx={{ mt: 2 }}
                onClick={handleSave}
              >Indsend</Button>
              <Button 
                variant="contained" 
                size="large" 
                sx={{ mt: 2, ml: 1}}
                onClick={() => {setIsOpen(false)}}
              >Anuller</Button>
            </Box>
          </Box>
          <Box flex="1" height="100%" position="relative">
            <IconButton 
              style={{ position: "absolute", right: 0, color: colors.grey[300] }} 
              onClick={() => {setIsOpen(false)}}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
        </Card>
      </Modal>
    </>
  )
};

export default FeedbackModal;
