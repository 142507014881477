import { Box, IconButton, useTheme, Typography, Tooltip, Breadcrumbs } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../../theme.js";
import { useNavigate, Link } from "react-router-dom";

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';

import { HeadContext } from '../../context/HeadContext';
import { AuthContext } from '../../context/AuthContext';

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const Topbar = ({ open_feedback, setIsFeedbackOpen }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate();

  const headSettings = useContext(HeadContext);
  const auth = useContext(AuthContext);
  console.log(headSettings)

  return (
    <>
      <Box 
        display="flex" 
        justifyContent="space-between" 
        p={1} 
        pl={3}
        pr={2}
        alignItems="center"  
        width="-webkit-fill-available"
        top="0"
        position="sticky"
        backgroundColor={theme.palette.mode === 'dark' ? colors.primary[500] : "#fcfcfc"}
        zIndex="1200"
      >
        {/* TITLE TEXT */}
        <Breadcrumbs aria-label="breadcrumb">
          {headSettings.data.location.map((item, index) => (
            <Typography key={item} color={index === headSettings.data.location.length-1 ? colors.grey[200] : "unset"}>{item}</Typography>
          ))}
        </Breadcrumbs>

        {/* ICONS */}
        <Box display="flex">
          <Tooltip title="Hjælp os med at blive bedere">
            <IconButton onClick={() => {open_feedback(); setIsFeedbackOpen(true)}}>
              <FeedbackOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Gå til ${theme.palette.mode === 'dark' ? 'lyst' : 'mørkt (eksperimentelt)'} tema`}>
            <IconButton onClick={colorMode.toggleColorMode}>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Log ud">
            <IconButton onClick={() => {
              setCookie("authorization", "", 1); 
              setCookie("usrdata", "", 1); 
              auth.setToken(null); 
              navigate("/");
            }}>
              <LogoutOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>

      </Box>
    </>
  )
}

export default Topbar;

