import { Box, Modal, Button, IconButton, Typography, useTheme, Table, TextField, Card, MenuItem, Select } from "@mui/material";
import { tokens, themeSettings } from "../../../theme";
import { useState, useEffect, useContext } from "react";

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { AuthContext } from '../../../context/AuthContext';
import Badge from "../../../components/badge";
import Hider from "../../../components/passwordHider";

import config from "../../../config";


const Users = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const auth = useContext(AuthContext);
  const [ users, setUsers ] = useState([]);
  const [ count, setCount ] = useState(0);
  const [ isOpen, setIsOpen ] = useState(false);

  const [activeId, setActiveId] = useState(0);
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [mode, setMode] = useState("admin");

  const handleClose = () => {
    setIsOpen(false);
  }

  const cardSyle = {
    gridColumn: "span 3",
    borderColor: colors.grey[800],
    backgroundColor: colors.primary[400],
    display: "flex", 
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "630px",
    height: "340px"
  }

  const update = () => {
    fetch(`${config.baseurl}/admin/get_accounts`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data);
        setUsers(data.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        alert("Fetch error:", error);
      });
  }

  useEffect(() => {
    update();

    setTimeout(() => {
      setCount(count + 1);
    }, 30000)
  }, [count]);

  
  const handle_save = () => {
    fetch(
      `${config.baseurl}/admin/update_user`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: activeId,
          user: user,
          pass: pass,
          status: mode
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      update();
      setIsOpen(false);
    })
  }
  
  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card style={cardSyle} variant="outlined">
          <Box
            p={4}
          >
            <Typography 
              variant="h6"
              color={colors.grey[300]}
              mb={1}
            >Bruger opsætning</Typography>
            <TextField 
              id="standard-basic" 
              label="Brugernavn" 
              variant="standard" 
              value={user}
              onChange={event => setUser(event.target.value)} 
              style={{
                width: "550px",
                marginTop: "15px"
              }}
            />
            <br />
            <TextField 
              id="standard-basic" 
              label="Adgangskode" 
              variant="standard" 
              type="password" 
              value={pass}
              onChange={event => setPass(event.target.value)} 
              style={{
                width: "550px",
                marginTop: "15px",
                marginBottom: "23px"
              }}
            />
            <br />
            <Select
              sx={{
                width: "550px",
              }}
              value={mode}
              onChange={(e) => setMode(e.target.value)}
            >
              <MenuItem value="admin" key="1">Admin</MenuItem>
              <MenuItem value="manager" key="2">Manager</MenuItem>
            </Select>
            <Button 
              variant="contained" 
              size="large" 
              color="info"
              sx={{ mt: 2 }}
              onClick={() => {handle_save()}}
            >Gem</Button>
            <Button 
              variant="contained" 
              size="large" 
              sx={{ mt: 2, ml: 1}}
              onClick={() => {setIsOpen(false)}}
            >Anuller</Button>
            <Button 
              variant="contained" 
              size="large" 
              color="error"
              sx={{ mt: 2, ml: 1}}
            >Slet</Button>
          </Box>
          <Box flex="1" height="100%" position="relative">
            <IconButton 
              style={{ position: "absolute", right: 0, color: colors.grey[300] }} 
              onClick={() => {setIsOpen(false)}}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
        </Card>
      </Modal>

      <Box 
      >
        <h1>Oprettede brugere</h1>
        <TableContainer sx={{ height: 650 }} component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Navn</TableCell>
                <TableCell>Adgangskode</TableCell>
                <TableCell>Rolle</TableCell>
                <TableCell>Sidst set</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row) => (
                <TableRow
                  key={row[0]}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{row[0]}</TableCell>
                  <TableCell>{row[1]}</TableCell>
                  <TableCell><Hider text={row[4]} /></TableCell>
                  <TableCell><Badge text={row[2]} type={
                    row[2] === "admin" ? "purple" : 
                    row[2] === "manager" ? "green" : "red"
                  } /></TableCell>
                  <TableCell>{
                    row[3] === "-1" ? "-- sekunder siden" : 
                      Math.round((+new Date() / 1000) - row[3]) > 86400 ? `${Math.round(((+new Date() / 1000) - row[3])/86400)} dage siden` :
                      Math.round((+new Date() / 1000) - row[3]) > 3600 ? `${Math.round(((+new Date() / 1000) - row[3])/3600)} timer siden` :
                      Math.round((+new Date() / 1000) - row[3]) > 60 ? `${Math.round(((+new Date() / 1000) - row[3])/60)} minutter siden` :
                      `${Math.round((+new Date() / 1000) - row[3])} sekunder siden`
                  } </TableCell>
                  <TableCell>{Math.round((+new Date() / 1000) - row[3]) < 600 ? <Badge text="Online" type="green" /> : <Badge text="Offline" type="red" />}</TableCell>
                  <TableCell width="20px">
                    <IconButton onClick={() => {setIsOpen(true); setActiveId(row[0]); setMode(row[2]); setPass(row[4]); setUser(row[1]); console.log(row)}}>
                      <SettingsOutlinedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        
      </Box>
    </>
  )
}

export default Users;
