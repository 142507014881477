import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card, FormControlLabel, RadioGroup, Radio, Checkbox, Dialog, DialogTitle, DialogActions, DialogContent, Select, MenuItem } from "@mui/material";
import { tokens, themeSettings } from "../../../theme";
import { useState, useEffect, useContext } from "react";

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import LoadingButton from '@mui/lab/LoadingButton';

import { AuthContext } from '../../../context/AuthContext';
import { HeadContext } from '../../../context/HeadContext';
import { useNavigate, useParams } from 'react-router-dom';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

import Papa from "papaparse";

import config from "../../../config";

const FileButton = styled(Button)(({ theme }) => {
  const colors = tokens(theme.palette.mode);

  return {
    color: colors.grey[400],
    backgroundColor: colors.primary[400],
    '&:hover': {
      backgroundColor: colors.primary[400],
    },
    width: "100%",
    height: "calc(100vh - 80px - 2em)"
  }}
);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const FileUpload = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const params = useParams();

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [error, setError] = useState("");
  const [file, setFile] = useState("");
  const [options, setOptions] = useState(["--"]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    head.setData({location: ["FrNet", "Data", "Upload"]});
  }, [])

  const handle_file_read = () => {
      // If user clicks the parse button without
    // a file we show a error
    if (!file) return alert("Enter a valid file");

    // Initialize a reader which allows user
    // to read any file or blob.
    const reader = new FileReader();

    // Event listener on reader when the file
    // loads, we parse it and set the data.
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, {
        header: true,
      });
      const parsedData = csv?.data;

      const data = parsedData.map((row) => (Object.values(row)))
      setColumns(Object.keys(parsedData[0]));
      setData(data);
    };
    reader.readAsText(file);
  }

  useEffect(() => {
    if (file) {
      handle_file_read();
    }
  }, [file])

  const handle_chosen_file = (e) => {
    setError("");
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension = inputFile?.type.split("/")[1];
      console.log(fileExtension)
      if (!fileExtension === "csv") {
        setError("Please input a csv file");
        return;
      }

      setFile(inputFile);
    }
    console.log(e)
  }

  const handle_submit = (e) => {
    // Get the type to submit
    const target = e.target;
    const type = target[0].checked ? target[0].value : 
                 target[1].checked ? target[1].value : 
                 target[2].checked ? target[2].value : 
                 null

    // If there is something selected
    if (type === null) {
      console.log("Nahh m7")
      return
    }

    setIsLoading(true);

    // Extract the mentioend rows
    const selectedDataColumns = []
    const selcetedDataColumnTypes = []
    let idx = 0
    for (let i = 3; i < target.length-1; i+=2) {
      if (target[i].value !== "--") {
        idx = target[i].name.split("-")[0];
        selectedDataColumns.push(data.map((row) => (row[idx])));
        selcetedDataColumnTypes.push(target[i].value);
      }
    }

    fetch(
      `${config.baseurl}/data/upload`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          type: type,
          dataColumns: selectedDataColumns,
          dataColumnTypes: selcetedDataColumnTypes
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      navigate("/");
    })
  }

  if (!data.length) {
    return (
      <FileButton
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        Klik for at uploade en fil
        <VisuallyHiddenInput type="file" accept="csv" onChange={(e) => {handle_chosen_file(e)}} />
      </FileButton>
    )
  }

  return (
    <>
      <form onSubmit={(e) => {e.preventDefault(); handle_submit(e)}}>
        <Box mb="2em" gap="1em" display="flex" alignItems="center">
          <Typography variant="h5">Opdater</Typography>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="upload-type"
          >
            <FormControlLabel value="volunteers" control={<Radio onChange={() => {setOptions(["--", "Navn", "Nummer"])}}/>} label="Frivillige" />
            <FormControlLabel value="attendees" control={<Radio onChange={() => {setOptions(["--", "Navn", "Email"])}} />} label="Deltagere" />
            {auth.token.settings.modules.ticket_commands === "1" ? (
              <FormControlLabel value="tickets" control={<Radio onChange={() => {setOptions(["--", "Navn", "Email", "Billet Kode", "Billet Data"])}} />} label="Biletter" />
            ) : (<></>)}
          </RadioGroup>
        </Box>

        <TableContainer component={Paper} sx={{ height: "calc(100vh - 80px - 12em)" }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {columns.map((name) => (
                  <TableCell>{name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {columns.map((name, idx) => (
                <TableCell>
                  <Select
                    sx={{
                      width: "100%",
                      height: "30px",
                      mb: 2
                    }}
                    defaultValue="--"
                    name={`${idx}-${name}`}
                  >
                  {options.map((name) => (
                    <MenuItem value={name} key={name}>
                      <Typography>{name}</Typography>
                    </MenuItem>
                  ))}
                  </Select>
                </TableCell>
              ))}
              {data.slice(0, 50).map((row, idx) => (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={idx}
                >
                  {row.map((value) => (
                    <TableCell>{value}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box 
          display="flex" 
          gap="2em" 
          pt="2em"
          pb="2em"
          position="fixed"
          justifyContent="space-between"
          bottom="0em"
          width="-webkit-fill-available"
          backgroundColor={theme.palette.mode === 'dark' ? colors.primary[500] : "#fcfcfc"}
          zIndex="200"
        >
          <LoadingButton
            variant="contained" 
            size="large" 
            type="submit"
            loading={isLoading}
          >Upload</LoadingButton>
          <Box pr="2em" display="flex" gap="1em" alignItems="center">
            <Typography>+ {data.length - 50} rækker</Typography>
          </Box>
        </Box>
      </form>
    </>
  )
}

export default FileUpload;
