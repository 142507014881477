import React, { useState, useEffect, useContext, useCallback } from "react";
import { Typography, useTheme, Chip , Box, Popover, Card, Avatar, Modal, TextField, Button, IconButton, Autocomplete, Select, MenuItem } from "@mui/material";
import { tokens } from "../../theme.js";

import TimeSelect from "../../components/timeSelect";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import Phone from "../../components/phone.jsx";
import { get_time_length } from "../../utils/timeFunc";
import { better_round } from "../../utils/numbers";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import { range } from "../../utils/numbers";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const EditStageItemModal = ({ isOpen, setIsOpen, data, doReload, group }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [ name, setName ] = useState("");
  const [ startTime, setStartTime ] = useState("00:00 01");
  const [ endTime, setEndTime ] = useState("00:00 01");
  const [ doUpdate, setDoUpdate ] = useState(false);
  const [ testLength, setTestLength] = useState(0);
  const [ volunteers, setVolunteers] = useState([]);
  
  // popover 
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperPersonId, setPopperPersonId] = useState(null);
  const [popperPerson, setPopperPerson] = useState(null);
  const [people, setPeople] = useState(null);

  const handleClickPopper = (event) => {
    if (name) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClosePopper = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    fetch(`${config.baseurl}/person/get_people`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data);
        setPeople(data.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        alert("Fetch error:", error);
      });
  }, []);

  const save_popper = () => {
    if (popperPerson !== null) {
      console.log([popperPerson])
      setVolunteers([...volunteers, popperPerson]);
      setPopperPerson(null);
    }
    handleClosePopper ();
  }

  const remove_volunteer = (row) => {
    setVolunteers(v => v.filter(item => item.id !== row.id));
  }

  // data load
  useEffect(() => {
    if (isOpen) {
      setName(data.name);
      setStartTime(data.start);
      setEndTime(data.stop);
      setTestLength(data.test_time);
      setDoUpdate(!doUpdate);
      setVolunteers(data.volunteers);
    }
  }, [data])

  const handleSave = () => {
    fetch(
      `${config.baseurl}/stages/save_item`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: data.id,
          name: name,
          startTime: startTime,
          endTime: endTime,
          testLength: testLength,
          volunteers: volunteers,
          stage_id: data.stage_id,
        })
      }
    )
    handleClose();
  }

  const handleDelete = () => {
    fetch(
      `${config.baseurl}/stages/delete_timeslot`,
      {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: data.id,
        })
      }
    )
    handleClose();
  }

  const handleClose = () => {
    setIsOpen(false);
  }

  const cardSyle = {
    gridColumn: "span 3",
    borderColor: colors.grey[800],
    backgroundColor: colors.primary[400],
    display: "flex", 
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "867px"
  }

  if (!isOpen) {
    return <></>
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card style={cardSyle} variant="outlined">
          <Box
            p={4}
            width="100%"
          >
            <Typography 
              variant="h4"
              color={colors.grey[300]}
              mb={1}
            >Tidslot opsætning</Typography>

            <Box 
              display="flex"
              alignItems="center"
              gap="4px"
              pb={1}
              height="40px"
            >
              <Typography 
                fontWeight={500}
                width="68px"
                lineHeight="40px"
              >Navn</Typography>
              <TextField 
                id="standard-basic" 
                variant="outlined" 
                onChange={event => setName(event.target.value)} 
                value={name}
                style={{
                  marginBottom: "15px",
                  padding: "2px",
                  width: "529px",
                  height: "30px",
                  transform: "translateY(5px)"
                }}
                sx={{
                  input: {padding: "0px", paddingLeft: "10px", height: "30px"}
                }}
              />
            </Box>
            <Box display="flex" gap="2em" width="100%">
              <Box>
                <TimeSelect label="Start" time={startTime} setTime={setStartTime} update={doUpdate}/>
                <TimeSelect label="Slut" time={endTime} setTime={setEndTime} update={doUpdate} />
                <Box 
                  display="flex"
                  alignItems="center"
                  gap="4px"
                  mb={1}
                >
                  <Typography 
                    fontWeight={500}
                    width="118px"
                  >Lydprøve</Typography>
                  <Select
                    sx={{
                      width: "166px",
                      height: "30px"
                    }}
                    value={testLength}
                    onChange={(e) => setTestLength(e.target.value)}
                  >
                  {range(0,121, 15).map((i) => (
                    <MenuItem value={`${i}`} key={`${i}`}>{`${i} minutter`}</MenuItem>
                  ))}
                  </Select>
                </Box>
                <hr />
                <Typography 
                  fontWeight={500}
                  width="260px"
                >Spilletid: {better_round(get_time_length(startTime, endTime)/4, 2)} timer</Typography>
              </Box>
              <Box flex="1">
                <TableContainer sx={{ height: 200 }} component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell width="40px">ID</TableCell>
                        <TableCell>Navn</TableCell>
                        <TableCell>Nummer</TableCell>
                        <TableCell width="25px">
                          <IconButton sx={{ margin: "-5px" }} onClick={handleClickPopper}>
                            <AddIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {volunteers.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.id}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.number}</TableCell>
                          <TableCell width="25px">
                            <IconButton sx={{ margin: "-5px" }} onClick={() => {remove_volunteer(row)}}>
                              <RemoveIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClosePopper}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  style={{
                    padding: "2em",
                  }}
                >
                  <Box m="2em" display="flex" gap="2em">
                    <Autocomplete 
                      sx={{ 
                        width: 300, 
                      }}
                      options={people.filter((item) => (!volunteers.some((volun) => (volun.id == item.id))))}
                      autoHighlight
                      getOptionLabel={(option) => option.name}
                      onChange={(e, a) => {
                        console.log(a?.id)
                        setPopperPersonId(a.id);
                        setPopperPerson(a);
                      }}
                      value={popperPerson}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option.name} ({option.id}) 
                            <Phone number={option.number} props={{ fontWeight: 200 }}/>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Vælg person"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                      isOptionEqualToValue={(option, value) => (option.id == value.id)}
                    />
                    <Button 
                      variant="contained" 
                      size="large" 
                      color="info"
                      onClick={save_popper}
                    >Tilføj</Button>
                  </Box>
                </Popover>
              </Box>
            </Box>
            <Button 
              variant="contained" 
              size="large" 
              color="info"
              sx={{ mt: 2 }}
              onClick={handleSave}
            >Gem</Button>
            <Button 
              variant="contained" 
              size="large" 
              sx={{ mt: 2, ml: 1}}
              onClick={() => {setIsOpen(false)}}
            >Anuller</Button>
            {data.id !== -1 ? <Button 
              variant="contained" 
              size="large" 
              color="error"
              sx={{ mt: 2, ml: 1}}
              onClick={handleDelete}
            >Slet</Button> : <></>}
          </Box>
          <Box flex="1" height="100%" position="relative">
            <IconButton 
              style={{ position: "absolute", right: 0, color: colors.grey[300] }} 
              onClick={() => {setIsOpen(false)}}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
        </Card>
      </Modal>
    </>
  )
};

export default EditStageItemModal;
