import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem } from "@mui/material";
import { tokens, themeSettings } from "../../../theme";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { AuthContext } from '../../../context/AuthContext';
import { HeadContext } from '../../../context/HeadContext';
import { better_round, formatPhoneNumber } from "../../../utils/numbers";
import { RowSpace, Column, ColumnItem } from "../../../components/calenderGraph";

import config from "../../../config";
import { SnackBarContext } from "../../../context/SnackBarContext";

const VolunShift = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();
  const snackBar = useContext(SnackBarContext);

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);

  const [ data, setData ] = useState(null);
  const [ openCreate, setOpenCreate ] = useState(false);
  const [ openCreateOffer, setOpenCreateOffer ] = useState(false);
  const [ note, setNote ] = useState("");
  const [ dropdownShift, setDropdownShift ] = useState(-1);
  const [ update, setUpdate ] = useState(false);
  const [ showenShift, setShowenShift ] = useState({})

  useEffect(() => {
    head.setData({location: ["FrNet", "Vagt", id]});

    fetch(`${config.baseurl}/volunteer/get_shift?id=${id}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data);
        setData(data.data);
        setShowenShift({
          start: data.data.start,
          stop: data.data.stop,
          area: data.data.area,
          length: data.data.length,
          volunteer_id: data.data.volunteer_id,
        })
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        alert("Fetch error:", error);
      });
  }, [update]);

  const open_shift_swap = () => {
    console.log(note)

    fetch(
      `${config.baseurl}/volunteer/open_shift_swap`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: id,
          note: note
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      snackBar.setText("Vagten er udgivet til bytning")
      setOpenCreate(false);
      setUpdate(!update);
    })
  }

  const take_shift = () => {
    fetch(
      `${config.baseurl}/volunteer/take_shift`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: id,
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      snackBar.setText("Du har nu overtaget vagten")
      setUpdate(!update);
    })
  }

  const offer_shift_swap = () => {
    fetch(
      `${config.baseurl}/volunteer/offer_shift_swap`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: id,
          shift: dropdownShift,
          note: note
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      snackBar.setText("Vagten er udgivet til bytning")
      setOpenCreateOffer(false);
      setUpdate(!update);
    })
  }

  const accept_offer = (offer_id) => {
    console.log("Accpeting")
    fetch(
      `${config.baseurl}/volunteer/accept_offer`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          shift_id: id,
          offer_id: offer_id,
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      snackBar.setText("Vagten er udgivet til bytning")
      setOpenCreateOffer(false);
      setUpdate(!update);
    })
  }

  const delete_shift_swap = (swapid) => {
    fetch(
      `${config.baseurl}/volunteer/offer_shift_swap`,
      {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: id,
          swap_id: swapid,
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      snackBar.setText("Vagttilbudet er tilbagetrukket")
      setUpdate(!update);
    })
  }

  console.log(data)
  

  if (data === null) {
    return <></>
  }

  return (
    <>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '600px', maxHeight: 435 } }}
        maxWidth="xs"
        open={openCreate}
      >
        <DialogTitle>Åben vagt til bytte</DialogTitle>
        <DialogContent dividers>
          
          <TextField
            onBlur={(e) => {setNote(e.target.value)}}
            defaultValue={note}
            label="Tilføj en note"
            multiline
            rows={4}
            sx={{
              width:"100%"
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={open_shift_swap}>Udgiv</Button>
          <Button onClick={() => {setOpenCreate(false)}}>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '600px', maxHeight: 435 } }}
        maxWidth="xs"
        open={openCreateOffer}
      >
        <DialogTitle>Tilbyd vagt til bytte</DialogTitle>
        <DialogContent dividers>
          <Select
            sx={{
              width: "100%",
              mb: 2
            }}
            value={dropdownShift}
            onChange={(e) => {setDropdownShift(e.target.value); }}
          >
            <MenuItem value={-1} key={-1}>
               <Typography>Ingen vagt</Typography>
            </MenuItem>

            {data.volunteer_shifts.map((row) => (
              <MenuItem value={row.id} key={row.id}>
                 <Typography>{row.start} - {row.stop} {row.area}</Typography>
              </MenuItem>
            ))}
          </Select>
          
          <TextField
            onBlur={(e) => {setNote(e.target.value)}}
            defaultValue={note}
            label="Tilføj en note"
            multiline
            rows={4}
            sx={{
              width:"100%"
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={offer_shift_swap}>Udgiv</Button>
          <Button onClick={() => {setOpenCreateOffer(false)}}>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        display="flex"
        height="calc(100vh - 4em - 50px)"
        gap="2em"
      >
        <Box
          display="flex"
          flexDirection="column"
          height="calc(100vh - 4em - 50px)"
          gap="2em"
          flex="2"
        >
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h3" mb="0.5em">Mødested: {data.area}</Typography>
              <Typography variant="h6">{data.start.replace(" ", " dag ")} - {data.stop.replace(" ", " dag ")} ({better_round(data.length/60, 2)} timer)</Typography>
              <Typography variant="h6">Ejet af: {data.volunteer_name}</Typography>
            </Box>
            <Card 
              variant="outlined"
              style={{
                padding: "1em",
                width: "350px",
                height: "min-content",
                borderRadius: "5px",
                backgroundColor: colors.primary[500],
              }}
              sx={{ boxShadow: 1 }}
            >
              <Box display="flex" justifyContent="space-between">
                <Typography>{data.group_name}</Typography>
                <Typography>{data.ansName}</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>{data.radio !== null ? `Radio kanal: ${data.radio}` : ""}</Typography>
                <Typography>{formatPhoneNumber(data.ansNumber)}</Typography>
              </Box>
            </Card>
          </Box>

          <Card 
            variant="outlined"
            style={{
              padding: "1em",
              flex: "1",
              height: "min-content",
              borderRadius: "5px",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              backgroundColor: colors.primary[500],
            }}
            sx={{ boxShadow: 1 }}
          >
            {data.swap_state === 1 ? (
              <>
                <Typography variant="h5">{data.volunteer_name} har udgivet vagten til bytte</Typography>
                <Box 
                  width="100%" 
                  backgroundColor={colors.primary[400]} 
                  p="1em"
                  mt="1em"
                  borderRadius="5px"
                  flex="1"
                >
                  <Typography variant="p">{data.swap_note}</Typography>
                </Box>
              </>
            ) : (
              <Typography
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: colors.grey[600]
                }}
              >{data.swapable === 1 ? "Vagten er ikke åben for bytning" : "Det er desværre ikke muligt at bytte denne vagt"}</Typography>
            )}
          </Card>

          <Card 
            variant="outlined"
            style={{
              padding: "1em",
              flex: "3",
              height: "min-content",
              borderRadius: "5px",
              overflow: "scroll",
              position: "relative",
              display: "flex",
              gap: "1em",
              flexDirection: "column",
              backgroundColor: colors.primary[500],
            }}
            sx={{ boxShadow: 1 }}
          >
            {data.swap_state === 1 ? (
              data.swap_shifts.length === 0 ? (
                <Typography
                  style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: colors.grey[600]
                  }}
                >
                  {data.volunteer_id == auth.token.volunteer_id ? 
                    "Der er ingen bytte tilbud endnu, kom tilbage senere" 
                    : 
                    "Du har ikke lavet et vagt bytte forslag"
                  }
                </Typography>
              ) : (
                data.swap_shifts.map((item) => (
                  <Box width="100%" borderBottom={`1px ${colors.grey[600]} solid`} pb="1em">
                    <Box display="flex" justifyContent="space-between">
                      <Box>
                        <Typography variant="h5" mb="0.5em">{item.area}</Typography>
                        <Typography variant="h6">{item.start.replace(" ", " dag ")} - {item.stop.replace(" ", " dag ")} ({better_round(item.length/60, 2)} timer)</Typography>
                        <Typography variant="h6">Tilbudt af: {item.volunteer_name}</Typography>
                      </Box>
                      <Box gap="2em" display="flex" height="min-content">
                        {data.volunteer_id == auth.token.volunteer_id ? (
                          <>
                            <Button
                              variant="contained" 
                              size="large" 
                              onClick={() => (accept_offer(item.id))}
                            >Vælg</Button>
                            <Button
                              variant="contained" 
                              size="large" 
                              onClick={() => (setShowenShift(item))}
                            >Vis</Button>
                          </>
                        ) : (
                          <Button
                            variant="contained" 
                            size="large" 
                            onClick={() => {delete_shift_swap(item.id)}}
                          >Tilbagetræk</Button>
                        )}
                      </Box>
                    </Box>
                    <Box 
                      width="100%" 
                      backgroundColor={colors.primary[400]} 
                      p="1em"
                      mt="1em"
                      borderRadius="5px"
                    >
                      <Typography variant="p">{item.note}</Typography>
                    </Box>
                  </Box>
                ))
              )
            ) : (
              <Typography
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: colors.grey[600]
                }}
              >{data.swapable === 1 ? "Vagten er ikke åben for bytning" : "Det er desværre ikke muligt at bytte denne vagt"}</Typography>
            )}
          </Card>

          <Box display="flex" gap="2em">
            {data.volunteer_id == auth.token.volunteer_id && data.swapable ? (
              data.swap_state === 1 ? (
                <Button
                  variant="contained" 
                  size="large" 
                  onClick={() => {}}
                >Annuler vagt bytte</Button>
              ) : (
                <Button
                  variant="contained" 
                  size="large" 
                  onClick={() => {setOpenCreate(true)}}
                >Åben vagt til bytte</Button>
              )
            ) : (
              data.swap_state === 1 && data.swapable ? (
                <Button
                  variant="contained" 
                  size="large" 
                  onClick={() => {setOpenCreateOffer(true)}}
                >Tilbyd en vagt til bytte</Button>
              ) : (
                <></>
              )
            )}
            {data.volunteer_id === null ? (
              <Button
                variant="contained" 
                size="large" 
                onClick={take_shift}
              >Tag vagt</Button>
            ) : (
              <></>
            )}
            <Button
              variant="contained" 
              size="large" 
              onClick={() => {navigate(-1)}}
            >Tilbage</Button>
          </Box>
        </Box>
        <Box flex="1">
          <Box 
            display="flex"
            flexDirection="row"
            gap="20px"
            width="100%"
            height="calc(100vh - 4em - 52.56px)"
          >
            <RowSpace columns={[`Dag ${parseInt(showenShift.start.split(" ")[1])}`]} scrollTo={showenShift.start}>
              <Column>
                {data.volunteer_shifts.map((item) => (
                  parseInt(item.start.split(" ")[1]) === parseInt(showenShift.start.split(" ")[1]) ? (
                    <ColumnItem 
                      key={item.id} 
                      name={item.area} 
                      start={item.start} 
                      stop={item.stop} 
                      length={item.length} 
                    />
                  ) : (<></>)
                ))}
                <ColumnItem 
                  key={-1} 
                  name={showenShift.area} 
                  start={showenShift.start} 
                  stop={showenShift.stop} 
                  length={showenShift.length} 
                  sx={showenShift.volunteer_id != auth.token.volunteer_id ? {
                    backgroundColor: colors.redAccent[800],
                    border: `1px ${colors.redAccent[700]} solid`
                  } : {}}
                />
              </Column>
            </RowSpace>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default VolunShift;
