import React, { useState, useEffect, useContext, useCallback } from "react";
import { Typography, useTheme, Chip , Box, Popover, Card, Avatar } from "@mui/material";
import { tokens } from "../theme.js";
import Barcode from "react-barcode";
import { format_seconds } from "../utils/numbers.js";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Ticket = ({ id, code, data, last_scan, name, email }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (!code) {
    return (<></>)
  }

  return (
    <>
      <Card 
        variant="outlined" 
        style={{
          width: "100%", 
          height: "600px", 
          backgroundColor: theme.palette.mode === 'dark' ? colors.primary[500] : "#fcfcfc",
          padding: "2em",
          position: "relative"
        }} 
      >
        <Box
          sx={{
            position: "absolute",
            left: "2em",
          }}
        >
          <Typography 
            variant="h3"
            color={data === "Invalid billet" ? colors.redAccent[300] : "unset"}
            backgroundColor={data === "Invalid billet" ? colors.redAccent[700] : "unset"}
          >{name}</Typography>
          <Typography 
            variant="p"
            color={colors.grey[600]}
            lineHeight="25px"
          >{email}</Typography>

          <Box mt="1em">
            <Typography>Data</Typography>
            <TableContainer component={Paper} sx={{ minHeight: "200px", minWidth: "400px", marginTop: "1em" }}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Navn</TableCell>
                    <TableCell>Værdi</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        
        <Box
          sx={{
            transform: "rotate(-90deg)",
            position: "absolute",
            width: "600px",
            right: "calc(2em - 239px)",
            top: "50%",
            translate: "0 -50%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Barcode value={code} type="CODE39" flat={true}/>
        </Box>

        <Card 
          variant="outlined" 
          style={{
            width: "400px", 
            height: "50px", 
            paddingLeft: "2em",
            backgroundColor: data === "Invalid billet" ? colors.redAccent[800] : last_scan == -1 ? colors.greenAccent[800] : colors.redAccent[800],
            position: "absolute",
            bottom: "2em"
          }} 
        >
            <Typography 
              variant="pre"
              lineHeight="50px"
          >{last_scan == -1 ? "Første scanning" : `Sidst scannet ${format_seconds(Math.round(+new Date()/1000 - last_scan))} siden`}</Typography>
        </Card>
      </Card>
    </>
  )
};

export default Ticket;
