import { memo, useState, useEffect, useContext, useCallback } from "react";
import React from 'react';
import {
  Card,
  Box,
  Typography,
  useTheme,
  Tooltip,
} from "@mui/material";
import { tokens, themeSettings } from "../theme";
import { debounce, isEqual } from 'lodash';

import { convert_timestamp, get_time_length, timestamp_convert } from "../utils/timeFunc";
import { AuthContext } from "../context/AuthContext";
import Person from "./person";

import config from "../config";

const widthscale = 1.4;
const heightscale = 1;

function range(start, end, step = 1) {
  const result = [];
  for (let i = start; i < end; i += step) {
    result.push(i);
  }
  return result;
}

export const RowSpace = ({children, callback, setActiveRow, sx}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const auth = useContext(AuthContext);

  const [ oldPos, setOldPos ] = useState([0,0]);

  const handleMouseMove = debounce((e) => {
    console.log(Math.abs(oldPos[1] - e.clientY), e.clientY, oldPos[1])
    if (Math.abs(oldPos[1] - e.clientY) > 0 || Math.abs(oldPos[0] - e.clientX) > 0) {
      setActiveRow(Math.ceil((e.clientY - e.target.getBoundingClientRect().y) / (40 * heightscale)) - 1);
      setOldPos([e.clientX, e.clientY]);
    }
  }, 0); // Adjust the delay as needed

  return (
    <Box 
      position="relative"
      onMouseMove={(e) => {handleMouseMove(e)}}
      onMouseLeave={() => {setActiveRow(-1)}}
      sx={sx}
    >
      {range(0,auth.token.settings.festival_settings.festival_length).map((d) => (
        range(0,24).map((h) => (
          range(0,4).map((m) => {
            return (
              <Box
                height="100%"
                position="absolute"
                className="vertical"
                left={`${10*(d*96+h*4+m)*widthscale-1}px`}
                width={`${10*widthscale}px`} 
                borderRight={`1px ${[23,0,1,2,3,4,5,6].includes(h) ? colors.redAccent[700] : colors.grey[900]} solid`} 
                backgroundColor={[23,0,1,2,3,4,5,6].includes(h) ? colors.redAccent[800] : ""}
                zIndex="10"
                onClick={(e) => {callback(Math.ceil((e.clientY - e.target.getBoundingClientRect().y) / (40*heightscale))-1, `${h}:${m*15} ${d+1}`)}}
                sx={{ ":hover": {backgroundColor: [23,0,1,2,3,4,5,6].includes(h) ? colors.redAccent[700] : colors.grey[400]+"22"} }}
              />
            )
          })
        ))
      ))}
      {children}
    </Box>
  )
}

export const LHeader = ({ data, setData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const auth = useContext(AuthContext);

  return (
    <Box display="flex" mb="25px">
      {range(0,auth.token.settings.festival_settings.festival_length).map((day) => {
        return (
          <Box 
            width={`${960*widthscale}px`} 
            height="25px" 
            boxSizing="border-box" 
            borderRight={`1px ${colors.grey[900]} solid`} 
            borderBottom={`1px ${colors.grey[900]} solid`}
          >
            <Typography 
              fontWeight={500}
              lineHeight="25px"
              style={{
                paddingLeft: "5px",
                width: "50px",
                left: 0,
                position: "sticky"
              }}
            >Dag {day+1}</Typography>
            <Box display="flex">
              {range(0, 24).map((hour) => {
                return (
                  <Box 
                    width={`${40*widthscale}px`} 
                    height="25px" 
                    boxSizing="border-box" 
                    borderRight={`1px ${colors.grey[900]} solid`}  
                    borderBottom={`1px ${colors.grey[900]} solid`}
                  >
                    <Typography 
                      fontWeight={500}
                      lineHeight="25px"
                      style={{
                        paddingLeft: "5px",
                      }}
                    >{hour}</Typography>
                  </Box>
                )
              })}
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

export const Header = memo(LHeader);

export const LIndexColumn = ({ data, setData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box borderRight={`1px ${colors.grey[900]} solid`}>
      <Box pl={2} width="200px" height="50px" boxSizing="border-box">
        <Typography 
          fontWeight={500}
          lineHeight="50px"
        >Områder</Typography>
      </Box>
      {data.map((item) => {
        return (
          <Tooltip title={item.mode === "person" ? "" : item.name}>
            <Box 
              pl={2} 
              width="200px" 
              height={`${40*heightscale}px`} 
              boxSizing="border-box" 
              borderTop={`1px ${colors.grey[900]} solid`}
              overflow="hidden"
              alignItems="center"
              display="flex"
            >
              {item.mode === "person" ? (
                <Person name={item.name} id={item.id} sx={{ width: "160px" }} />
              ) : (
                <Typography 
                  fontWeight={500}
                  lineHeight={`${40*heightscale}px`}
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >{item.name}</Typography>
              )}
            </Box>
          </Tooltip>
        )
      })}
    </Box>
  )
}

export const IndexColumn = memo(LIndexColumn);

export const LRow = function ({children, active}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const auth = useContext(AuthContext);

  return (
    <Box 
      height={`${40*heightscale}px`} 
      width={`${960*widthscale*auth.token.settings.festival_settings.festival_length}px`} 
      display="flex" 
      position="relative"
      boxSizing="border-box"
      border={`1px ${colors.grey[900]} solid`}
      backgroundColor={active ? colors.grey[400]+"22" : ""}
      sx={{pointerEvents: "none"}}
      zIndex="15"
    >
      {children}
    </Box>
  )
}

function compareProps(prevProps, nextProps) {
  return isEqual(prevProps, nextProps)
}

export const Row = memo(LRow, compareProps);

export const LRowItem = ({name, tstart, tend, parts, id, marked, color}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const length = get_time_length(tstart, tend);
  const offset = get_time_length("0:0 1", tstart);
  var partsLength = (get_time_length(tstart, tend) / (parts));

  var length2 = get_time_length(tstart, tend) * 900000 /parts;
  var times = [];
  for (let i = 0; i < parts; i++) {
    times.push(timestamp_convert(convert_timestamp(tstart) + length2*i));
  }

  return (
    <Box 
      display="flex"
      key={id}
      left={`${10*offset*widthscale-3}px`}
      top="1px"
      position="absolute"
      borderRadius="2px"
      overflow="hidden"
      zIndex="10"
      onClick={(e) => {console.log(e)}}
    >
      <Box 
        height={`${40*heightscale-2}px`}
        backgroundColor={
          color === "grey" ? colors.grey[600] : 
          color === "red" ? colors.redAccent[600] : 
          color === "blue" ? colors.blueAccent[600] : 
          colors.greenAccent[600]
        }
        width="5px"
      />
      <Box 
        height={`${40*heightscale-2}px`}
        backgroundColor={
          color === "grey" ? colors.grey[700] : 
          color === "red" ? colors.redAccent[700] : 
          color === "blue" ? colors.blueAccent[700] : 
          colors.greenAccent[700]
        }
        width={`${10*length*widthscale - 5}px`} 
        p={1}
        pr={1}
        position="relative"
      >
        {range(0, parts - 1).map((i) => (
          <>
            <Box 
              height={`${11*heightscale-2}px`}
              backgroundColor={colors.grey[400]}
              left={(i+1)*partsLength*10*widthscale-4}
              bottom="0"
              position="absolute"
              width="2px"
            />
            <Box
              left={(i+1)*partsLength*10*widthscale-4}
              top={0}
              position="absolute"
              p={1}
            >
            {<Typography 
              fontWeight={500}
              lineHeight="22px"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
            >{times[i+1].split(" ")[0]}</Typography>}
            </Box>
          </>
        ))}
        {<Typography 
          fontWeight={500}
          lineHeight="22px"
          whiteSpace="nowrap"
          overflow="hidden"
        >{name}</Typography>}
        {marked ? (
          <Typography
            sx={{ position: "absolute", top: "3px", right: "5px" }}
            color={colors.redAccent[500]}
            fontWeight={700}
          >*</Typography>
        ): (<></>)}
      </Box>
    </Box>
  )
}

export const RowItem = memo(LRowItem);

