
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Card,
  Box,
  Typography,
  useTheme,
  Modal,
  Button,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Table
} from "@mui/material";
import { tokens, themeSettings } from "../../../theme";

import TimeSelect from "../../../components/timeSelect";
import EditShiftModal from "../../../components/editShiftModal";
import Person from "../../../components/person";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import Person2 from '@mui/icons-material/Person2';

import config from "../../../config";
import { check_time_in, get_time_length, timestamp_convert, convert_timestamp } from "../../../utils/timeFunc";
import { better_round } from "../../../utils/numbers";

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function range(start, end, step = 1) {
  const result = [];
  for (let i = start; i < end; i += step) {
    result.push(i);
  }
  return result;
}

const Spreadsheet = ({ group, people }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [ joined, setJoined ] = useState([]);
  const [ joinedShowed, setJoinedShowed ] = useState([]);
  const [ extra, setExtra ] = useState([]);
  const [ extraShowed, setExtraShowed ] = useState([]);
  const [ update, setUpdate ] = useState(false);
  const [ searchTerm, setSearchTerm ] = useState("");

  useEffect(() => {
    fetch(`${config.baseurl}/group/get_people?id=${group}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        setJoined(data.data.joined);
        setExtra(data.data.extraPeople);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.log(error);
        alert("Fetch error:", error);
      });
  }, [update])

  useEffect(() => {
    setJoinedShowed(joined.filter((item) => ((item.id+item.name+item.number).toLowerCase().includes(searchTerm.toLowerCase()))));
  }, [searchTerm, joined])

  useEffect(() => {
    setExtraShowed(extra.filter((item) => ((item.id+item.name+item.number).toLowerCase().includes(searchTerm.toLowerCase()))));
  }, [searchTerm, extra])

  const remove_group = (pid, idx) => {
    const tmpJoined = joinedShowed.slice(0, joinedShowed.length);
    const row = tmpJoined[idx]
    tmpJoined.splice(idx, 1);
    setJoinedShowed(tmpJoined);

    const tmpExtra = extra.slice(0, extra.length);
    tmpExtra.push(row)
    setExtraShowed(tmpExtra);

    fetch(
      `${config.baseurl}/person/remove_group`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: pid,
          group: group 
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setUpdate(!update);
    })
  }

  const add_group = (pid, idx) => {
    const tmpExtra = extraShowed.slice(0, extraShowed.length);
    const row = tmpExtra[idx]
    tmpExtra.splice(idx, 1);
    setExtraShowed(tmpExtra);

    const tmpJoined = joinedShowed.slice(0, joinedShowed.length);
    tmpJoined.push(row)
    setJoinedShowed(tmpJoined);

    fetch(
      `${config.baseurl}/person/add_group`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: pid,
          group: group 
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setUpdate(!update);
    })
  }

  return (
    <>
      <TextField 
        id="standard-basic" 
        variant="outlined" 
        label="Søg efter person"
        onChange={event => (setSearchTerm(event.target.value))} 
        value={searchTerm}
        style={{
          marginBottom: "15px",
          padding: "2px",
          width: "100%",
          height: "30px",
          transform: "translateY(5px)",
        }}
        sx={{
          input: {padding: "0px", paddingLeft: "10px", height: "30px"},
          label: { top: "-8px" }
        }}
      />
      <Box 
        display="flex"
        gap="2em"
        justifyContent="space-between"
        mb="1em"
      >
        <Typography>Personer tilsluttet gruppen</Typography>
        <Typography>Personer ikke tilsluttet gruppen</Typography>
      </Box>
      <Box 
        display="flex"
        gap="2em"
        pb="6em"
      >
        <TableContainer component={Paper} key="t1">
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Navn</TableCell>
                <TableCell>Nummer</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {joinedShowed.map((row, idx) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{row.id}</TableCell>
                  <TableCell><Person name={row.name} id={row.id} tlf={row.number} notes={row.notes} /></TableCell>
                  <TableCell>{row.number}</TableCell>
                  <TableCell width="10px">
                    <IconButton sx={{ margin: "-5px" }} onClick={() => {remove_group(row.id, idx)}}>
                      <KeyboardArrowRightOutlinedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer component={Paper} key="t2">
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Navn</TableCell>
                <TableCell>Nummer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {extraShowed.map((row, idx) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell width="10px">
                    <IconButton sx={{ margin: "-5px" }} onClick={() => {add_group(row.id, idx)}}>
                      <KeyboardArrowLeftOutlinedIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>{row.id}</TableCell>
                  <TableCell><Person name={row.name} id={row.id} tlf={row.number} notes={row.notes} /></TableCell>
                  <TableCell>{row.number}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        
      </Box>
    </>
  )
}

export default Spreadsheet;
