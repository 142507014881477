import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Box,
  Typography,
  useTheme
} from "@mui/material";

import { DataGrid, GridFooter } from '@mui/x-data-grid';

import { tokens, themeSettings } from "../../../theme";
import { ResponsiveBar } from '@nivo/bar'
import StatBox from "../../../components/statsBox";
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import { useNavigate, Link } from "react-router-dom";

import config from "../../../config";

const columns = [
  { 
    field: 'id', 
    headerName: 'ID', 
    type: 'number',
    flex: 0.5,
  },
  {
    field: 'group_name',
    headerName: 'Gruppe',
    editable: false,
    flex: 0.7,
  },
  {
    field: 'person_name',
    headerName: 'Person navn',
    editable: false,
    flex: 1,
  },
  {
    field: 'start',
    headerName: 'Start',
    editable: false,
    flex: 0.5,
  },
  {
    field: 'stop',
    headerName: 'Stop',
    editable: false,
    flex: 0.5,
  },
  {
    field: 'area',
    headerName: 'Område',
    editable: false,
    flex: 1,
  },
  {
    field: 'notified',
    headerName: 'Sms sendt',
    editable: false,
    flex: 0.5,
    renderCell: (params) => {
      if (params.aggregation && !params.aggregation.hasCellUnit) {
        return params.formattedValue;
      }
      return params.formattedValue == "1" ? "✅" : "❌";
    },
  }
];

const ShiftStatus = ({data}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const cardSettings = {
    backgroundColor: colors.primary[400], 
    gridColumn: "span 6", 
    gridRow: "span 3",
    display: "flex",
    flexDirection: "column",
    padding: "1em 1em" 
  }

  return (
    <>
      <Card variant="outlined" style={cardSettings} >
        <DataGrid
          rows={
            data?.shiftStatus === undefined ? [] : data.shiftStatus
          }
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          density="compact"
          pageSizeOptions={[20, 50, 100]}
          disableRowSelectionOnClick
          disableSelectionOnClick
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
             outline: "none !important",
            },
            "& .empty": {
              backgroundColor: colors.grey[900]+"55",
            }
          }}
          onRowDoubleClick={(params, event, details) => {navigate(`/people/${params.row.person_id}`)}}
          getCellClassName={(params) => {
            if (params.field === 'person_id' || params.field === 'person_name') {
              return params.value === "" || params.value === null ? 'empty' : '';
            }
          }}
        />
      </Card>
    </>
  )
}

export default ShiftStatus;
