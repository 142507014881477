import { useState, useContext, useEffect } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
// import "react-pro-sidebar/dist/css/styles.css";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme.js";

import "react-pro-sidebar/dist/css/styles.css";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SubjectIcon from '@mui/icons-material/Subject';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ForumIcon from '@mui/icons-material/Forum';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import PersonOutlineOutlined from "@mui/icons-material/PersonOutlineOutlined";
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';

import { AuthContext } from '../../context/AuthContext';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({ setExpanded }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const auth = useContext(AuthContext);
  console.log("auth", auth)

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  useEffect(() => {
    setExpanded(isCollapsed);
  }, [isCollapsed])

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        height: "100vh",
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 10px !important",
        },
        "& .pro-sub-menu > .pro-inner-item": {
          color: colors.grey[100] + " !important",
          fill: colors.grey[100] + " !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
          fill: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
          fill: "#6870fa !important",
        },
        "& .pro-inner-item:focus": {
          color: colors.grey[100],
          fill: colors.grey[100],
        },
        "& .pro-menu": {
          height: "100%",
        },
        "& ul": {
          height: "100%",
        },
        "& .pro-inner-list-item": {
          paddingLeft: !isCollapsed ? "40px !important" : "25px !important" 
        },
        "& .pro-inner-list-item::before !important": {
          backgroundColor: "#f00",
          height: "40px",
          widht: "2px"
        }
      }}
    >
    
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <Box display="flex" flexDirection="column" height="100%">
            <MenuItem
              onClick={() => setIsCollapsed(!isCollapsed)}
              icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
              style={{
                margin: "10px 0 20px 0",
                color: colors.grey[100],
              }}
              sx={{
                "&*": {fill: colors.grey[100]},
              }}
            >
              {!isCollapsed && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml="15px"
                >
                  <Typography variant="h3" color={colors.grey[100]}>
                    FrNet
                  </Typography>
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <MenuOutlinedIcon />
                  </IconButton>
                </Box>
              )}
            </MenuItem>

            {!isCollapsed && (
              <Box 
                width="calc(270px - 35px)"
                boxSizing="border-box"
                margin="8px 5px 8px 20px"
                p="10px 20px"
                backgroundColor={colors.primary[500]}
                borderRadius="5px"
              >
                <Box>
                  <Typography 
                    variant="h4" 
                    fontSize="16px"
                    color={colors.grey[100]} 
                    fontWeight="bold" 
                  >
                    {auth.token.settings.festival_settings.festival_name}
                  </Typography>
                  <Typography
                    varient="span" 
                    fontSize="12px"
                    color={colors.grey[600]}
                  >
                    Starter {auth.token.settings.festival_settings.start_date}
                  </Typography>
                </Box>
              </Box>
            )}

            {/* MENU ITEMS */}
            <Box paddingLeft={isCollapsed ? undefined : "3%"} overflow="overlay" flex="1">
              {auth.token.level === "volunteer" ? (
                <>
                  <Item 
                    title="Dashboard"
                    to="/volunteer"
                    icon={<HomeOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item 
                    title="Mine vagter"
                    to="/volunteer/shifts"
                    icon={<AccessTimeOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                {/*}<Item 
                    title="Kommonikation"
                    to="/messages"
                    icon={<ForumIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />{*/}
                </>
              ) : (
                <>
                  <Item 
                    title="Dashboard"
                    to="/"
                    icon={<HomeOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  
                  <SubMenu 
                    title={<Typography variant="h6">Grupper</Typography>} 
                    icon={<GroupsOutlinedIcon />
                  }>
                    <Item 
                      title="Gruppe oversigt"
                      to="/groups"
                      icon={<GroupOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item 
                      title="Opret gruppe"
                      to="/groupscreate"
                      icon={<GroupAddOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </SubMenu>
                  {auth.token.settings.modules.planning === "1" ? (
                    <Item 
                      title="Planlægning (beta)"
                      to="/planning"
                      icon={<CalendarMonthOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  ) : <></>}
                  <SubMenu 
                    title={<Typography variant="h6">Frivillige</Typography>} 
                    icon={<PersonOutlineOutlined />}
                  >
                    <Item 
                      title="Frivillig oversigt"
                      to="/volunteers"
                      icon={<PersonOutlineOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item 
                      title="Opret frivillig"
                      to="/volunteerscreate"
                      icon={<PersonAddAlt1OutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item 
                      title="Vagtbytter"
                      to="/volunteers/swaps"
                      icon={<AccessTimeOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </SubMenu>
                  <SubMenu 
                    title={<Typography variant="h6">Vagter</Typography>} 
                    icon={<CalendarTodayOutlinedIcon />}
                  >
                    <Item 
                      title="Vagter"
                      to="/shifts"
                      icon={<AccessTimeOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item 
                      title="Udskriv vagtlister"
                      to="/pdf/vagt"
                      icon={<ListAltOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item 
                      title="Udskriv tidsplaner"
                      to="/pdf/group"
                      icon={<ViewTimelineOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </SubMenu>
              <Item
                title="Scener"
                to="/stages"
                      icon={<svg xmlns="http://www.w3.org/2000/svg" fill={selected !== "Scener" ? colors.grey[100] : "unset"} height="24" viewBox="0 -960 960 960" width="24"><path d="M120-160q-33 0-56.5-23.5T40-240v-480q0-33 23.5-56.5T120-800h80q33 0 56.5 23.5T280-720v480q0 33-23.5 56.5T200-160h-80Zm0-79h80v-482h-80v482Zm320 79q-33 0-56.5-23.5T360-240v-480q0-33 23.5-56.5T440-800h400q33 0 56.5 23.5T920-720v480q0 33-23.5 56.5T840-160H440Zm0-79h400v-482H440v482Zm-240 0v-482 482Zm240 0v-482 482Z"/></svg>}
                selected={selected}
                setSelected={setSelected}
              />
                  {auth.token.settings.modules.tickets === "1" ? (
                    <SubMenu 
                      title={<Typography variant="h6">Biletter</Typography>} 
                      icon={<PaymentOutlinedIcon />
                    }>
                      <Item 
                        title="Browse Biletter"
                        to="/tickets/browse"
                        icon={<ConfirmationNumberOutlinedIcon />}
                        selected={selected}
                        setSelected={setSelected}
                      />
                      <Item 
                        title="Tjek Biletter"
                        to="/tickets/lookup"
                        icon={<svg xmlns="http://www.w3.org/2000/svg" fill={selected !== "Tjek Biletter" ? colors.grey[100] : "unset"} height="24" viewBox="0 -960 960 960" width="24"><path d="M240-120q-60 0-95.5-46.5T124-270l72-272q-33-21-54.5-57T120-680q0-66 47-113t113-47h320q45 0 68 38t3 78l-80 160q-11 20-29.5 32T520-520h-81l-11 40h12q17 0 28.5 11.5T480-440v80q0 17-11.5 28.5T440-320h-54l-30 112q-11 39-43 63.5T240-120Zm0-80q14 0 24-8t14-21l78-291h-83l-72 270q-5 19 7 34.5t32 15.5Zm40-400h240l80-160H280q-33 0-56.5 23.5T200-680q0 33 23.5 56.5T280-600Zm480-160-25-54 145-66 24 55-144 65Zm120 280-145-65 25-55 144 66-24 54ZM760-650v-60h160v60H760Zm-360-30Zm-85 160Z"/></svg>}
                        selected={selected}
                        setSelected={setSelected}
                      />
                      {auth.token.settings.modules.ticket_commands === "1" ? (
                        <>
                          <Item 
                            title="Programmer"
                            to="/tickets/programmer"
                            icon={<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={selected !== "Programmer" ? colors.grey[100] : "unset"}><path d="M220-80q-58 0-99-41t-41-99q0-58 41-99t99-41q18 0 35 4.5t32 12.5l153-153v-110q-44-13-72-49.5T340-740q0-58 41-99t99-41q58 0 99 41t41 99q0 48-28 84.5T520-606v110l154 153q15-8 31.5-12.5T740-360q58 0 99 41t41 99q0 58-41 99t-99 41q-58 0-99-41t-41-99q0-18 4.5-35t12.5-32L480-424 343-287q8 15 12.5 32t4.5 35q0 58-41 99t-99 41Zm520-80q25 0 42.5-17.5T800-220q0-25-17.5-42.5T740-280q-25 0-42.5 17.5T680-220q0 25 17.5 42.5T740-160ZM480-680q25 0 42.5-17.5T540-740q0-25-17.5-42.5T480-800q-25 0-42.5 17.5T420-740q0 25 17.5 42.5T480-680ZM220-160q25 0 42.5-17.5T280-220q0-25-17.5-42.5T220-280q-25 0-42.5 17.5T160-220q0 25 17.5 42.5T220-160Z"/></svg>}
                            selected={selected}
                            setSelected={setSelected}
                          />
                          <Item 
                            title="Kommandprint"
                            to="/pdf/scan"
                            icon={<TerminalOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                          />
                        </>
                      ) : <></>}
                    </SubMenu>
                  ) : <></>}

                  {auth.token.level === "admin" ? (
                    <SubMenu 
                      title={<Typography variant="h6">Admin</Typography>} 
                      icon={<AdminPanelSettingsOutlinedIcon />
                    }>
                      <Item
                        title="Brugere"
                        to="/admin/users"
                        icon={<GroupOutlinedIcon />}
                        selected={selected}
                        setSelected={setSelected}
                      />
                      <Item 
                        title="Upload"
                        to="/data/upload"
                        icon={<UploadFileIcon />}
                        selected={selected}
                        setSelected={setSelected}
                      />
                      <Item
                        title="Logs"
                        to="/admin/logs"
                        icon={<SubjectIcon />}
                        selected={selected}
                        setSelected={setSelected}
                      />
                      <Item
                        title="Indstillinger"
                        to="/admin/settings"
                        icon={<SettingsOutlinedIcon />}
                        selected={selected}
                        setSelected={setSelected}
                      />
                    </SubMenu>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Box>



            {/* USER */}
            {!isCollapsed && (
              <Box 
                width="calc(270px - 35px)"
                boxSizing="border-box"
                margin="8px 5px 8px 20px"
                p="10px 20px"
                backgroundColor={colors.primary[500]}
                borderRadius="5px"
              >
                <Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography 
                      variant="h4" 
                      fontSize="16px"
                      color={colors.grey[100]} 
                      fontWeight="bold" 
                    >
                      {auth.token.level !== "volunteer" ? (
                        auth.token.user[0].toUpperCase() + auth.token.user.substring(1)
                      ) : (
                        auth.token.name
                      )}
                    </Typography>
                    <Typography
                      varient="span" 
                      fontSize="12px"
                      color={colors.greenAccent[500]}
                    >
                      {auth.token.level[0].toUpperCase() + auth.token.level.substring(1)}
                    </Typography>
                  </Box>
                  <Typography
                    varient="span" 
                    fontSize="12px"
                    color={colors.grey[600]}
                  >
                    {auth.token.email}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  )
}

export default Sidebar;
