import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card, Select, MenuItem } from "@mui/material";
import { tokens, themeSettings } from "../../../theme";
import { useState, useEffect, useContext } from "react";

import { AuthContext } from '../../../context/AuthContext';
import { HeadContext } from '../../../context/HeadContext';

import Row from '../components/settingsRow';

import config from "../../../config";

const Shifts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);

  const [ data, setData ] = useState(null);

  useEffect(() => {
    fetch(
      `${config.baseurl}/admin/get_shift_settings`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Parse the response JSON
    })
    .then((data) => {
      // Handle the response data here
      console.log(data);
      setData(data.data);
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      alert("Fetch error:", error);
    });
  }, [])

  if (data === null) {
    return (
      <></>
    )
  }

  return (
    <>
      <Row name="Standard vagtbegrænsning">
        <Select
          name="shiftlimit_default"
          sx={{
            width: "300px",
            height: "30px"
          }}
          defaultValue={""+data.shiftlimit_default}
        >
          <MenuItem value="1" key="1">1 Vagt</MenuItem>
          <MenuItem value="2" key="2">2 Vagter</MenuItem>
          <MenuItem value="3" key="3">3 Vagter</MenuItem>
          <MenuItem value="4" key="4">4 Vagter</MenuItem>
          <MenuItem value="5" key="5">5 Vagter</MenuItem>
          <MenuItem value="6" key="6">6 Vagter</MenuItem>
          <MenuItem value="7" key="7">7 Vagter</MenuItem>
          <MenuItem value="8" key="8">8 Vagter</MenuItem>
        </Select>
      </Row>
    </>
  )
}

export default Shifts;
