import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card } from "@mui/material"; import { tokens, themeSettings } from "../../../theme";
import { useState, useEffect, useContext } from "react";

import config from "../../../config";

const Row = ({name, children}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box display="flex" mb="1em">
      <Box width="300px">
        {name}
      </Box>
      <Box flexGrow="1">
        {children}
      </Box>
    </Box>
  )
}

export default Row;
