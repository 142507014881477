import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Card,
  Box,
  Typography,
  useTheme,
  Modal,
  Button,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Table
} from "@mui/material";
import { tokens, themeSettings } from "../../../theme";

import config from "../../../config";
import { SnackBarContext } from '../../../context/SnackBarContext';

const Spreadsheet = ({ id, update, setUpdate }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const snackBar = useContext(SnackBarContext);

  const generate = () => {
    fetch(
      `${config.baseurl}/group/generate_shifts`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          group: id
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setUpdate(!update);
        
      return response.json(); // Parse the response JSON
    }).then(data => {
      console.log(data);
      snackBar.setText(`Oprettede ${data.data.shifts} nye vagter`)
    })
  }

  const pobulate = () => {
    fetch(
      `${config.baseurl}/group/pobulate_shifts`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          group: id
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setUpdate(!update);

      return response.json(); // Parse the response JSON
    }).then(data => {
      console.log(data);
      snackBar.setText(`Uddelte ${data.data.totalSuccess} vagter, kunne ikke uddele ${data.data.totalFail}`)
    })
  }

  const resetShifts = () => {
    fetch(
      `${config.baseurl}/group/reset_shifts`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          group: id
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setUpdate(!update);
      snackBar.setText("Nulstillede alle vagter.")
    })
  }

  return (
    <>
      <Box 
        display="flex" 
        gap="2em" 
        pt="2em"
        pb="2em"
        position="fixed"
        bottom="0em"
        width="100%"
        backgroundColor={theme.palette.mode === 'dark' ? colors.primary[500] : "#fcfcfc"}
      >
        <Button
          variant="contained" 
          size="large" 
          color="info"
          onClick={generate}
        >Generer vagter</Button>
        <Button
          variant="contained" 
          size="large" 
          color="info"
          onClick={pobulate}
        >Uddel vagter</Button>
        <Button
          variant="contained" 
          size="large" 
          color="error"
          onClick={resetShifts}
        >Nulstil vagter</Button>
      </Box>
    </>
  )
}

export default Spreadsheet;

