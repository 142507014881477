import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card } from "@mui/material";
import { tokens, themeSettings } from "../../theme";
import { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { AuthContext } from '../../context/AuthContext';
import { HeadContext } from '../../context/HeadContext';
import Gantt from './components/gantt3';
import Spreadsheet from './components/spreadsheet';
import PersonSwitcher from './components/personSwitcher';
import Toolbar from './components/toolbar';

import config from "../../config";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Index = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const params = useParams();
  const id = params.id;

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);

            // "people_in_group": people,
            // "available_people": available,
  const [ info, setInfo ] = useState({});
  const [ times, setTimes ] = useState([]);
  const [ peopleIn, setPeopleIn ] = useState([]);
  const [ peopleOut, setPeopleOut ] = useState([]);
  const [ allPeople, setAllPeople ] = useState([]);

  const [ update, setUpdate ] = useState(false);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    head.setData({location: ["FrNet", "Gruppe", id]});
    fetch(`${config.baseurl}/group/get_group?id=${id}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data)
        setInfo(data.data.group);
        setTimes(data.data.zones_out);
        setPeopleIn(data.data.people_in_group);
        setPeopleOut(data.data.available_people);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        alert("Fetch error:", error);
      });
  }, [update]);

  useEffect(() => {
    if (peopleIn.length && peopleOut.length){
      setAllPeople(peopleIn.concat(peopleOut))
    }
  }, [peopleIn, peopleOut])

  const save_info = () => {
    fetch(
      `${config.baseurl}/group/save_info`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: id,
          info: info
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    })
  }
  
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
      >
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <TextField 
              label="Gruppe Navn" 
              variant="standard" 
              onBlur={save_info}
              onChange={event => setInfo({...info, navn: event.target.value})} 
              value={info.navn === undefined ? "" : info.navn}
              style={{
                width: "300px",
                marginRight: "2em"
              }}
            /> 
            <TextField 
              label="Radio kanal" 
              variant="standard" 
              onBlur={save_info}
              onChange={event => setInfo({...info, radio: event.target.value})} 
              value={info.radio === undefined ? "" : info.radio}
              style={{
                width: "300px",
                marginTop: "15px",
                marginRight: "2em"
              }}
            /> 
          </Box>
          <Box display="flex" flexDirection="column">
            <TextField 
              label="Ansvarlig Navn" 
              variant="standard" 
              onBlur={save_info}
              onChange={event => setInfo({...info, ansNavn: event.target.value})} 
              value={info.ansNavn === undefined ? "" : info.ansNavn}
              style={{
                width: "300px",
                marginRight: "2em"
              }}
            /> 
            <TextField 
              label="Ansvarlig Nummer" 
              variant="standard" 
              onBlur={save_info}
              onChange={event => setInfo({...info, ansNummer: event.target.value})} 
              value={info.ansNummer === undefined ? "" : info.ansNummer}
              style={{
                width: "300px",
                marginTop: "15px",
                marginBottom: "15px"
              }}
            />
          </Box>
        </Box>
        <hr />
        <Box style={{ padding: "0" }}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Vagter" />
            <Tab label="Personer" />
            <Tab label="Vagt Skema" />
          </Tabs>
          <TabPanel value={value} index={0} sx={{ padding: "0", "&.MuiBox-root": {padding: 0} }}>
            <Gantt data={times} setData={setTimes} group={id} update={update}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PersonSwitcher group={id} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Spreadsheet group={id} people={allPeople} update={update} />
          </TabPanel>
        </Box>
        <Toolbar id={id} update={update} setUpdate={setUpdate}/>
      </Box>
    </>
  )
}

export default Index;
