import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card } from "@mui/material";
import { tokens, themeSettings } from "../../theme";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { AuthContext } from '../../context/AuthContext';
import { HeadContext } from '../../context/HeadContext';

import config from "../../config";

const CreateVolunteer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);

  const [ name, setName ] = useState("");
  const [ number, setNumber ] = useState("");
  const [ email, setEmail ] = useState("");

  const save = () => {
    if (name === "" || number === "") {
      return;
    }

    fetch(
      `${config.baseurl}/person/create_volunteer`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          name: name,
          number: number,
          email: email,
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Parse the response JSON
    })
    .then((data) => {
      // Handle the response data here
      console.log(data);
      navigate(`/people/${data.data.id}`);
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      alert("Fetch error:", error);
    });
  }

  return (
    <>
      <Box width="600px" display="flex" flexDirection="column">
        <Typography variant="h4" fontWeight="600" p="0em 0 0.5em 0">
          Frivillig information
        </Typography>
        <TextField 
          label="Navn"
          variant="outlined" 
          required
          value={name}
          onChange={(e) => {setName(e.target.value)}}
          sx={{ pb: "10px" }}
        />
        <br />
        <TextField 
          label="Telefonnummer"
          variant="outlined" 
          required
          value={number}
          onChange={(e) => {setNumber(e.target.value)}}
          sx={{ pb: "10px" }}
        />
        <br />
        <TextField 
          label="Email"
          variant="outlined" 
          value={email}
          onChange={(e) => {setEmail(e.target.value)}}
        />
      </Box>
      <Box width="600px" display="flex" flexDirection="column">
        <Button
          variant="contained" 
          size="large" 
          sx={{
            width: "100%",
            height: "30px",
            marginTop: "2em"
          }}
          onClick={save}
        >Opret</Button>
      </Box>
    </>
  )
}

export default CreateVolunteer;
