import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card } from "@mui/material";
import { tokens, themeSettings } from "../../theme";
import { useState, useEffect, useContext } from "react";

import { AuthContext } from '../../context/AuthContext';
import { HeadContext } from "../../context/HeadContext";
import { formatPhoneNumber, format_seconds, range } from "../../utils/numbers";
import { DataGrid, GridFooter } from '@mui/x-data-grid';

import { RowSpace, Column, ColumnStageItem } from "../../components/calenderGraph";
import EditStageItemModal from "./stageItemModal";

import config from "../../config";
import { useNavigate } from "react-router-dom";

const Stages = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);

  const [ data, setData ] = useState([]);
  const [ day, setDay ] = useState(1);

  // Modal
  const [ isOpen, setIsOpen ] = useState(false);
  const [ doUpdate, setDoUpdate ] = useState(false);
  const [ modalData, setModalData ] = useState({});

  useEffect(() => {
    head.setData({location: ["FrNet", "Shifts"]});
  }, [])

  useEffect(() => {
    if (!isOpen) {
      fetch(`${config.baseurl}/stages/get_stage_data`, {
        credentials: "include",
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json(); // Parse the response JSON
        })
        .then((data) => {
          // Handle the response data here
          console.log(data);
          setData(data.data);
        })
        .catch((error) => {
          // Handle any errors that occurred during the fetch
          alert("Fetch error:", error);
        });
    }
  }, [isOpen, doUpdate]);

  const save_drag = (id, start, stop, row) => {
    fetch(
      `${config.baseurl}/stages/save_item`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: row.id,
          name: row.name,
          startTime: `${start} ${day}`,
          endTime: `${stop} ${day}`,
          testLength: row.test_time,
          volunteers: row.volunteers,
          stage_id: row.stage_id,
        })
      }
    ).then(() => {
        setDoUpdate(!doUpdate);
      })
  }

  return (
    <>
      <EditStageItemModal isOpen={isOpen} setIsOpen={setIsOpen} data={modalData} />
      <Box 
        display="flex"
        flexDirection="row"
        gap="20px"
        width="100%"
        height="calc(100vh - 10em - 52.56px)"
      >
        <RowSpace 
          columns={Object.keys(data).map((key) => ({name: `${data[key].name}`, id: data[key]?.id}))} 
          scrollTo="09:00"
          onClick={(time, column) => {
            setModalData({
              id: -1,
              name: "",
              start: `${time} ${day}`,
              stop: `${time} ${day}`,
              test_time: 15,
              stage_id: column.id,
              volunteers: []
            }); setIsOpen(true); 
            console.log(time, column)
          }}
        >
          {Object.keys(data).map((key) => (
            <Column sx={{ cursor: "pointer" }}>
              {data[key].stage_items.map((item) => (
                parseInt(item.start.split(" ")[1]) === day ? (
                  <ColumnStageItem 
                    key={item.id} 
                    name={item.name} 
                    start={item.start} 
                    stop={item.stop} 
                    test_length={item.test_time/15}
                    onClick={() => {console.log(item); setModalData(item); setIsOpen(true)}}
                    sx={{ cursor: "pointer" }}
                    dragable={true}
                    save_drag={(start, stop) => {save_drag(item.id, start, stop, item)}}
                  />
                ) : (<></>)
              ))}
            </Column>
          ))}
        </RowSpace>
      </Box>
      <Box p="2em 2em 0 2em" display="flex" gap="1em" alignItems="center">
        <Button
          size="large" 
          onClick={() => {setDay(Math.max(1, day-1))}}
        >{"<"}</Button>
        <Typography>Dag {day}</Typography>
        <Button
          size="large" 
          onClick={() => {setDay(Math.min(auth.token.settings.festival_settings.festival_length, day+1))}}
        >{">"}</Button>
      </Box>
    </>
  )
}

export default Stages;
