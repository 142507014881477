import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  TextField,
  Card,
  TableSortLabel,
} from "@mui/material";
import { tokens, themeSettings } from "../../theme";
import Paper from "@mui/material/Paper";
import { DataGrid, GridFooter } from '@mui/x-data-grid';
import { useNavigate, Link } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";
import config from "../../config";

import { format_seconds, formatPhoneNumber } from "../../utils/numbers";

const columns = [
  { 
    field: 'id', 
    headerName: 'ID', 
    type: 'number',
    flex: 0.6,
  },
  {
    field: 'name',
    headerName: 'Navn',
    editable: false,
    flex: 1,
  },
  {
    field: 'number',
    headerName: 'Nummer',
    editable: false,
    flex: 0.6,
    renderCell: (params) => {
      if (params.aggregation && !params.aggregation.hasCellUnit) {
        return params.formattedValue;
      }
      return formatPhoneNumber(params.formattedValue);
    },
  },
  {
    field: 'working_seconds',
    headerName: 'Arbejdsmængde',
    type: 'number',
    editable: false,
    flex: 0.5,
    renderCell: (params) => {
      if (params.aggregation && !params.aggregation.hasCellUnit) {
        return params.formattedValue;
      }
      return format_seconds(parseInt(params.formattedValue.replace(",", "").replace(".", "")), "hours");
    },
  },
  {
    field: 'shifts',
    headerName: 'Antal Vagter',
    type: 'number',
    editable: false,
    flex: 0.5,
  },
  {
    field: 'shift_error',
    headerName: 'Overlap tjek',
    editable: false,
    flex: 0.5,
    renderCell: (params) => {
      if (params.aggregation && !params.aggregation.hasCellUnit) {
        return params.formattedValue;
      }
      return params.formattedValue != "1" ? "✅" : "❌";
    },
  },
  {
    field: 'groups',
    headerName: 'Grupper',
    editable: false,
    flex: 1,
  }
];

function CustomFooter() {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        <Typography 
          pl={2}
          fontWeight={500}
        >Person Oversigt</Typography>
      </div>
      <div>
        <GridFooter />
      </div>
    </div>
  );
}

const UserTable = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const [ data, setData ] = useState([]);

  const [ filterId, setFilterId ] = useState("");
  const [ filterName, setFilterName ] = useState("");
  const [ filterGroup, setFilterGroup ] = useState("");

  useEffect(() => {
    fetch(`${config.baseurl}/person/get_people`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data);
        setData(data.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        alert("Fetch error:", error);
      });
  }, []);

  return (
    <>
      <Box display="flex" gap="2em">
        <Box>
          <Typography>
            Frivillig ID
          </Typography>
          <TextField 
            style={{
              marginBottom: "15px",
              padding: "2px",
              width: "300px",
              height: "30px",
              transform: "translateY(5px)"
            }}
            sx={{
              input: {padding: "0px", paddingLeft: "10px", height: "30px"}
            }}
            variant="outlined" 
            value={filterId}
            onChange={(e) => {setFilterId(e.target.value)}}
          />
        </Box>
        <Box>
          <Typography>
            Frivillig Navn
          </Typography>
          <TextField 
            style={{
              marginBottom: "15px",
              padding: "2px",
              width: "300px",
              height: "30px",
              transform: "translateY(5px)"
            }}
            sx={{
              input: {padding: "0px", paddingLeft: "10px", height: "30px"}
            }}
            variant="outlined" 
            value={filterName}
            onChange={(e) => {setFilterName(e.target.value)}}
          />
        </Box>
        <Box>
          <Typography>
            Gruppe Navn
          </Typography>
          <TextField 
            style={{
              marginBottom: "15px",
              padding: "2px",
              width: "300px",
              height: "30px",
              transform: "translateY(5px)"
            }}
            sx={{
              input: {padding: "0px", paddingLeft: "10px", height: "30px"}
            }}
            variant="outlined" 
            value={filterGroup}
            onChange={(e) => {setFilterGroup(e.target.value)}}
          />
        </Box>
      </Box>
      <Box
        style={{ height: "calc(100vh - 170px)" }}
      >
        <DataGrid
          rows={
            data.filter((row) => (
              (row.id+"").includes(filterId) 
                && row.name.toLowerCase().includes(filterName.toLowerCase())
                && row.groups.toLowerCase().includes(filterGroup.toLowerCase())
            ))
          }
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          density="compact"
          pageSizeOptions={[20, 50, 100]}
          disableRowSelectionOnClick
          disableSelectionOnClick
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
             outline: "none !important",
            },
            "& .marked": {
              backgroundColor: colors.redAccent[800]
            }
          }}
          components={{
            footer: CustomFooter
          }}
          onRowDoubleClick={(params, event, details) => {navigate(`/people/${params.row.id}`)}}
          getCellClassName={(params) => {
            if (params.field === 'working_seconds') {
              return params.row.working_seconds < auth.token.settings.festival_settings.min_time * 60 * 60 || params.row.working_seconds > auth.token.settings.festival_settings.max_time * 60 * 60 ? 'marked' : '';
            }
          }}
        />
      </Box>
    </>
  );
};

export default UserTable;

