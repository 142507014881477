const config = {
  baseurl: `https://${window.location.hostname}/api`,
  days: 3
};

export default config;

/*
 * baseurl: The url the API is located at
 * gantt: The gant driagram licence key, get one at https://gstc.neuronet.io/free-key/
 *
 *
 *
 *
 */

