import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card, Select, MenuItem, Tooltip, Checkbox, Switch, FormControlLabel } from "@mui/material";
import { tokens, themeSettings } from "../../../theme";
import { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import LaunchIcon from '@mui/icons-material/Launch';

import { AuthContext } from '../../../context/AuthContext';
import { HeadContext } from '../../../context/HeadContext';
import { useNavigate, Link } from "react-router-dom";

import config from "../../../config";

const Row = ({ name }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box height="30px" borderBottom={`1px ${colors.grey[700]} solid`}>
      <Typography lineHeight="30px">{name}</Typography>
    </Box>
  )
}

const Column = ({ name, idx, rowcount, columncount }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box 
      height={`${rowcount * 30 - idx * 30 + columncount * 30}px`} 
      borderLeft={`1px ${colors.grey[700]} solid`} 
      mt={`${idx * 30}px`}
      pl="10px"
      width="40px"
      backgroundColor={idx % 2 !== 0 ? colors.grey[800] + "55" : "unset"}
    >
      <Typography whiteSpace="nowrap" >{name}</Typography>
    </Box>
  )
}

const Plan = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const params = useParams();
  const navigate = useNavigate();

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);

  const [ rows, setRows ] = useState([]);
  const options = ["Indskreven personer", "Inskrevet optaget tider", "Forspurgt ift vagter", "Indskreven vagter", "Generet vagter", "Uddelt vagter", "Eftertjekket personer"]

  const getData = () => {
    fetch(`${config.baseurl}/group/get_groups`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data);
        setRows(data.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.log(error);
        alert("Fetch error:", error);
      });
  }

  useEffect(() => {
    head.setData({location: ["FrNet", "Planlægning"]});
    getData();
  }, [])

  return (
    <>
      <Box position="relative">
        <Box position="absolute" top={`${options.length * 30}px`} width={`${options.length * 40 + 300}px`}>
          {rows.map((item) => (
            <Row name={item[1]} />
          ))}
        </Box>
        <Box 
          position="absolute" 
          top="0" 
          left="300px"
          width="700px" 
          display="flex" 
          height="800px"
        >
          {options.map((item, idx) => (
            <Column name={item} idx={idx} rowcount={rows.length} columncount={options.length} />
          ))}
        </Box>
      </Box>
    </>
  )
}

export default Plan;
