import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card, Select, MenuItem, Tooltip } from "@mui/material";
import { tokens, themeSettings } from "../../../theme";
import { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import EditShiftModal from "../../../components/editShiftModal";
import UserTimeModal from "./userTimeModal";
import { check_time_in, get_time_length, timestamp_convert, convert_timestamp } from "../../../utils/timeFunc";

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

import { AuthContext } from '../../../context/AuthContext';
import { HeadContext } from '../../../context/HeadContext';
import { Header, IndexColumn, Row, RowItem, RowSpace } from "../../../components/gantt2";
import { useNavigate, Link } from "react-router-dom";

import config from "../../../config";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Person = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);

  const [ activeRow, setActiveRow ] = useState(-1);
  const [ shifts, setShifts ] = useState([]);
  const [ taken, setTaken ] = useState([]);

  const [ isOpen, setIsOpen ] = useState(false);
  const [ isOpen2, setIsOpen2 ] = useState(false);
  const [ modalData, setModalData ] = useState({});
  const [ group, setGroup ] = useState("");
  const [ people, setPeople ] = useState([]);

  const [ name, setName ] = useState("");
  const [ number, setNumber ] = useState("");
  const [ note, setNote ] = useState("");
  const [ allGroups, setAllGroups ] = useState([]);
  const [ groups, setGroups ] = useState([]);
  const [ dropdownGroup, setDropdownGroup ] = useState("");

  const [ stageItems, setStageItems ] = useState([]);

  const names_template = ["Vagter", "Optaget", ...Array.from(new Set(stageItems.map(obj => obj.stage_name)))];
  const names = names_template.map(i => ({name: i}));

  console.log(names)
  console.log(Array.from(new Set(stageItems.map(obj => obj.stage_name))))

  const getData = () => {
    fetch(`${config.baseurl}/person/get_person?id=${id}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        setName(data.data.name);
        setNumber(data.data.number);
        setGroups(data.data.groups);
        setAllGroups(data.data.allGroups);
        setShifts(data.data.shifts);
        setTaken(data.data.taken_zones);
        setNote(data.data.notes);
        setStageItems(data.data.stage_slots);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.log(error);
        alert("Fetch error:", error);
      });
  }

  useEffect(() => {
    fetch(`${config.baseurl}/person/get_people`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        setPeople(data.data)
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        alert("Fetch error:", error);
      });
  }, []);

  useEffect(() => {
    getData()
    head.setData({location: ["FrNet", "Rediger Person", id]});
  }, [])

  const handle_callback = (row, time) => {
    console.log(row, time);

    var selected = null

    if (row === 0) {
      for (let i = 0; i < shifts.length; i++) {
        if (check_time_in(shifts[i].start, shifts[i].stop, time)) {
          selected = shifts[i];
        }
      }

      if (selected !== null) {
        selected.idperson = id
        setGroup(selected.group);
        setModalData(selected);
      } else {
        setModalData({
          area: "",
          group: null,
          id: -1,
          start: time,
          stop: time,
          personId: id
        })
        setGroup("none");
      }

      setIsOpen(true);

    } else if (row === 1) {
      for (let i = 0; i < taken.length; i++) {
        if (check_time_in(taken[i].start, taken[i].stop, time)) {
          selected = taken[i];
        }
      }

      if (selected !== null) {
        setModalData(selected);
        setIsOpen2(true);
      } else {
        setModalData({
          id: -1,
          desc: "",
          start: time,
          stop: time,
          person_id: id
        });
        setIsOpen2(true);
      }
    }
  } 

  const remove_group = (name) => {
    fetch(
      `${config.baseurl}/person/remove_group`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: id,
          group: name
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      getData();
    })
  }

  const add_group = (name) => {
    fetch(
      `${config.baseurl}/person/add_group`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: id,
          group: name
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      getData();
    })
  }

  const save_name = () => {
    fetch(
      `${config.baseurl}/person/save_name`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: id,
          text: name
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    })
  }

  const save_number = (text) => {
    fetch(
      `${config.baseurl}/person/save_number`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: id,
          text: number
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    })
  }

  const save_note = (text) => {
    fetch(
      `${config.baseurl}/person/save_note`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: id,
          note: text
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    })
  }


  return (
    <Box display="flex" flexDirection="column" flex="1">
      <EditShiftModal isOpen={isOpen} setIsOpen={setIsOpen} data={modalData} doReload={getData} people={people} group={group} />
      <UserTimeModal isOpen={isOpen2} setIsOpen={setIsOpen2} data={modalData} doReload={getData} />
      <Box display="flex" justifyContent="space-between" width="100%" pb="1em" gap="2em">
        <Box backgroundColor={colors.primary[400]} p="1em 2em" borderRadius="5px">
          <TextField 
            onBlur={() => {save_name()}}
            label="Navn" 
            variant="standard" 
            onChange={event => setName(event.target.value)} 
            value={name}
            style={{
              width: "300px",
              marginTop: "15px"
            }}
          /> <br />
          <TextField 
            onBlur={() => {save_number()}}
            label="Nummer" 
            variant="standard" 
            onChange={event => setNumber(event.target.value)} 
            value={number}
            style={{
              width: "300px",
              marginTop: "15px",
              marginBottom: "15px"
            }}
          />
        </Box>
        <Box backgroundColor={colors.primary[400]} display="flex" width="700px" gap="2em" p="1em 1em" borderRadius="5px" flex="1">
          <TableContainer style={{ flex: "1", height: "134px" }}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Tilsluttet grupper</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groups.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ 
                      '&:last-child td, &:last-child th': { border: 0 }, 
                    }}
                  >
                    <TableCell>{row.name}</TableCell>
                    <TableCell style={{ width: "10px" }}>
                      <Box display="flex" gap="5px">
                        <IconButton sx={{ margin: "-5px" }} onClick={() => {navigate(`/groups/${row.id}`)}}>
                          <LaunchIcon />
                        </IconButton>
                        <Tooltip title="Fjærn fra gruppen">
                          <IconButton sx={{ margin: "-5px" }} onClick={() => (remove_group(row.id))}>
                            <RemoveOutlinedIcon /> 
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box flex="1">
            <Typography sx={{ mb: 2 }}>Vælg gruppe</Typography>
            <Select
              sx={{
                width: "100%",
                height: "30px",
                mb: 2
              }}
              value={dropdownGroup}
              onChange={(e) => {setDropdownGroup(e.target.value); }}
            >
            {allGroups.map((row) => (
              <MenuItem value={row.id} key={row.id}>
                 <Typography color={groups.map((i) => (i.id)).includes(row.id) ? colors.grey[100] : colors.grey[500]}>{row.name}</Typography>
              </MenuItem>
            ))}
            </Select>
            <br />
            <Button
              sx={{
                width: "100%",
                height: "30px",
                backgroundColor: !groups.map((i) => (i.id)).includes(dropdownGroup) ? colors.greenAccent[700] : colors.redAccent[700],
                ":hover" : {backgroundColor: !groups.map((i) => (i.id)).includes(dropdownGroup) ? colors.greenAccent[600] : colors.redAccent[600]}
              }}
              disabled={!dropdownGroup}
              onClick={() => (!groups.map((i) => (i.id)).includes(dropdownGroup) ? add_group(dropdownGroup) : remove_group(dropdownGroup))}
            >{!groups.map((i) => (i.id)).includes(dropdownGroup) ? "Tilføj til gruppe" : "Fjærn fra gruppe"}</Button>
          </Box>
        </Box>
      </Box>

      <Box display="flex" gap="2em" pt="1em" pb="1em">
        <Box backgroundColor={colors.primary[400]} display="flex" p="1em 1em" borderRadius="5px" flex="1">
          <TextField
            onBlur={(e) => {save_note(e.target.value)}}
            defaultValue={note}
            placeholder="Tilføj en intern note"
            multiline
            rows={7}
            sx={{
              flexGrow: "2",
              backgroundColor: colors.primary[400]
            }}
          />
        </Box>
        <Box backgroundColor={colors.primary[400]} display="flex" width="350px" gap="2em" p="1em 1em" borderRadius="5px">
          Infoboks (WIP)
        </Box>
      </Box>

      <Box className="gantt" backgroundColor={colors.primary[400]} borderRadius="5px" m="1em 0">
        <Box display="flex" flexWrap="unset">
          <IndexColumn data={names} />
          <Box overflow="overlay">
            <Box>
              <Header />
              <RowSpace callback={handle_callback} setActiveRow={setActiveRow} sx={{ cursor: "pointer" }}>

                <Row active={"Vagter" === names[activeRow]?.name}>
                  {shifts.map((item) => (
                    <RowItem name={item.area} tstart={item.start} tend={item.stop} parts={1} id={item.id} key={item.id} />
                  ))}
                </Row>

                <Row active={"Optaget" === names[activeRow]?.name}>
                  {taken.map((item) => (
                    <RowItem name={item.desc ? item.desc : ""} tstart={item.start} tend={item.stop} parts={1} id={item.id} key={item.id} />
                  ))}
                </Row>

                {Array.from(new Set(stageItems.map(obj => obj.stage_name))).map((stageName) => (
                  <Row active={stageName === names[activeRow]?.name} sx={{ cursor: "default" }}>
                    {stageItems.filter(i => i.stage_name === stageName).map((item) => {
                      return <RowItem 
                        name={item.item_name} 
                        tstart={timestamp_convert(convert_timestamp(item.start) - item.test_time*60*1000)} 
                        tend={item.stop} 
                        parts={1} 
                        id={item.id} 
                        key={item.id} 
                      />
                    })}
                  </Row>
                ))}

              </RowSpace>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box 
        flex="1"
        display="flex"
        mt="1em"
        backgroundColor={colors.primary[400]} 
        borderRadius="5px"
      >
        <TableContainer style={{ flex: "1" }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Område</TableCell>
                <TableCell>Gruppe</TableCell>
                <TableCell>Start</TableCell>
                <TableCell>Slut</TableCell>
                <TableCell>Vægt</TableCell>
                <TableCell>Vagtgrænse</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shifts.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.area}</TableCell>
                  <TableCell>{row.group}</TableCell>
                  <TableCell>{row.start}</TableCell>
                  <TableCell>{row.stop}</TableCell>
                  <TableCell>{row.length}</TableCell>
                  <TableCell>{row.shiftNum}</TableCell>
                  <TableCell width="25px">
                    <Box display="flex" gap="10px">
                      <IconButton sx={{ margin: "-5px" }} onClick={() => {navigate(`/swap/${row.id}`)}}>
                        <SwapHorizIcon />
                      </IconButton>
                      <IconButton sx={{ margin: "-5px" }} onClick={() => {setModalData({...row, personId: id}); setIsOpen(true)}}>
                        <ModeEditOutlineOutlinedIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        
      </Box>
    </Box>
  )
}

export default Person;
