import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card } from "@mui/material";
import { tokens, themeSettings } from "../../theme";
import { useState, useEffect, useContext } from "react";

import { AuthContext } from '../../context/AuthContext';
import { HeadContext } from "../../context/HeadContext";

import BarChart from './components/worktime';
import DateTime from "./components/currentTime";
import TicketCount from "./components/ticketCount";
import ShiftStatus from "./components/shiftStatus"

import config from "../../config";

const Index = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    head.setData({location: ["FrNet", "Dashboard"]});
  }, [])

  useEffect(() => {
    fetch(`${config.baseurl}/group/get_stats`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data);
        setData(data.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        alert("Fetch error:", error);
      });

    setTimeout(() => {
      setCount(i => i+1)
    }, 60000);
  }, [count]);

  return (
    <>
      <Box 
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"

      >
    {/*}<TicketCount data={data} />{*/}
        <DateTime data={data} />
        <DateTime data={data} />
        <DateTime data={data} />
        <DateTime data={data} />
        <BarChart data={data} />
        <ShiftStatus data={data} />
      </Box>
    </>
  )
}

export default Index;
