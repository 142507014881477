import React, { useState, useRef }  from 'react';
import { Route, Routes } from "react-router-dom";
import { CssBaseline, ThemeProvider, Box } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import './App.css';

import { AuthProvider } from './context/AuthContext';
import { HeadProvider } from './context/HeadContext';
import { SnackBarProvider, NotifSnackBar } from './context/SnackBarContext';
import { useScreenshot } from 'use-react-screenshot'

import FeedbackModal from './components/feedback';

import Login from "./scenes/login/login";
import Index from "./scenes/index/index";
import Groups from "./scenes/groups/groups";
import Users from "./scenes/admin/accounts/accounts";
import Swap from "./scenes/swap/swap";
import PdfZone from "./scenes/pdf/pdfgroup/pdfgroup";
import PdfGroup from "./scenes/pdf/pdftime/pdftime";
import Plan from "./scenes/planning/planning/plan";
import FileUpload from "./scenes/data/upload/upload";
import TicketLookup from "./scenes/tickets/lookup/lookup"
import TicketBrowser from "./scenes/tickets/browse/browse"
import TicketProgrammer from "./scenes/tickets/programmer/programmer";
import PdfScan from './scenes/pdf/pdfscan/pdfscan';

import ShiftTable from './scenes/index/tables/shiftTableX';
import GroupTable from './scenes/index/tables/groupTableX';
import CreateGroup from './scenes/groups/createGroup';

// volunteers
import UserTable from './scenes/volunteers/userTable';
import Person from "./scenes/volunteers/people/person";
import UserSwaps from './scenes/volunteers/userSwaps/userSwaps';
import CreateVolunteer from './scenes/volunteers/createVolunteer';

import VolunIndex from './scenes/volunteer/dashboard/dashboard';
import VolunShifts from './scenes/volunteer/shifts/shifts';
import VolunShift from './scenes/volunteer/shift/shift';

import IndexSettings from "./scenes/settings/index";

// Stages
import Stages from './scenes/stages/stages';


import Sidebar from "./scenes/global/sidebar.jsx";
import Topbar from "./scenes/global/topbar.jsx";

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

const App = () => { 
  const [theme, colorMode] = useMode();
  const [token, setToken] = useState(null);
  const [headerData, setHeaderData] = useState({location: ["FrNet", "Dashboard"]});
  const [expanded, setExpanded] = useState(false);
  const [snackText, setSnackText] = useState(false);

  // Feedback
  const appRef = useRef(null)
  const [image, takeScreenshot] = useScreenshot()
  const getImage = () => takeScreenshot(appRef.current)
  const [ isOpen, setIsOpen ] = useState(false);

  const open_feedback = () => {
    getImage();
  }

  console.log("token", token)

  console.log(getCookie("usrdata"))
  if (token === null && getCookie("authorization") !== "" && getCookie("authorization") !== null && getCookie("usrdata") !== "" && getCookie("usrdata") !== null) {
    setToken(JSON.parse(getCookie("usrdata")));
  }

  return (
    <AuthProvider value={{token: token, setToken: setToken}}>
      <HeadProvider value={{data: headerData, setData: setHeaderData, expanded: expanded}}>
        <SnackBarProvider value={{text: snackText, setText: setSnackText}}>
          <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <NotifSnackBar />
              <FeedbackModal isOpen={isOpen} setIsOpen={setIsOpen} image={image}/>
              {token === null ? (
                <Login />
              ) : (
                <Box className="App" display="flex" height="100%" ref={appRef}>
                  <Sidebar setExpanded={setExpanded} />
                  <Box>
                    <Topbar open_feedback={open_feedback} setIsFeedbackOpen={setIsOpen} />
                    <Box 
                      className="content" 
                      style={{ 
                        padding: "25px",
                        width: `calc(100vw - ${expanded ? '85px' : '275px'})`, 
                        minHeight: "calc(100vh - 54px)" 
                      }}
                      display="flex"
                      flexDirection="column"
                    >
                      <Routes>
                        <Route path="/volunteer" element={<VolunIndex />} />
                        <Route path="/volunteer/shifts" element={<VolunShifts />} />
                        <Route path="/volunteer/shift/:id" element={<VolunShift />} />

                        <Route path="/" element={<Index />} />
                        <Route path="/planning" element={<Plan />} />

                        <Route path="/groups" element={<GroupTable />} />
                        <Route path="/shifts" element={<ShiftTable />} />

                        <Route path="/volunteers" element={<UserTable />} />
                        <Route path="/volunteers/swaps" element={<UserSwaps />} />

                        <Route path="/groups/:id" element={<Groups />} />
                        <Route path="/groupscreate" element={<CreateGroup />} />

                        <Route path="/people/:id" element={<Person />} />
                        <Route path="/swap/:id" element={<Swap />} />
                        <Route path="/volunteerscreate" element={<CreateVolunteer />} />

                        <Route path="/pdf/vagt" element={<PdfZone />} />
                        <Route path="/pdf/group" element={<PdfGroup />} />
                        <Route path="/pdf/scan" element={<PdfScan />} />

                        <Route path="/tickets/lookup" element={<TicketLookup />} />
                        <Route path="/tickets/browse" element={<TicketBrowser />} />
                        <Route path="/tickets/programmer" element={<TicketProgrammer />} />

                        <Route path="/stages" element={<Stages />} />

                        <Route path="/data/stats" element={<h1>Hi</h1>} />
                        <Route path="/data/upload" element={<FileUpload />} />

                        <Route path="/admin/users" element={<Users />} />
                        <Route path="/admin/settings" element={<IndexSettings />} />
                      </Routes>
                    </Box>
                  </Box>
                </Box>
              )}
            </ThemeProvider>
          </ColorModeContext.Provider>
        </SnackBarProvider>
      </HeadProvider>
    </AuthProvider>
  )
}

export default App;
