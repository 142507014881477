import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card, FormControlLabel, RadioGroup, Radio, Checkbox, Dialog, DialogTitle, DialogActions, DialogContent, Select, MenuItem, Modal } from "@mui/material";
import { tokens, themeSettings } from "../../../theme";
import React, { useState, useEffect, useContext, useRef } from "react";

import { NodeEditor } from "flume";
import { FlumeConfig, Colors, Controls } from 'flume';
import { RootEngine } from 'flume';
import { useRootEngine } from 'flume';

import { AuthContext } from '../../../context/AuthContext';
import { HeadContext } from '../../../context/HeadContext';
import { useNavigate, useParams } from 'react-router-dom';

import CreateIcon from '@mui/icons-material/Create';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import config from "../../../config";

import { flumeConfig, resolvePorts } from "../flumeConfig";

const resolveNodes = (node, inputValues, nodeType, context) => {
  switch (node.type) {
    case 'string':
      return { string: inputValues.string }
    case 'boolean':
      return { boolean: inputValues.boolean }
    case 'number':
      return { number: inputValues.number }
    case 'add':
      return { number: inputValues.num1 + inputValues.num2 }
    case 'fetch_int':
      let xhr = new XMLHttpRequest();
      xhr.open("GET", `${config.baseurl}/tickets/get_data?name=${inputValues.title}`, false);
      xhr.withCredentials = true;

      xhr.send();

      let data = JSON.parse(xhr.responseText);
      console.log(data)

      return {number: parseInt(data.data)}
    case "input":
      return { string: prompt(inputValues.title)}
    default:
      return inputValues
  }
}


const engine = new RootEngine(flumeConfig, resolvePorts, resolveNodes)

const TicketProgrammer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [commands, setCommands] = useState([])
  const [command, setCommand] = useState(null)
  const [nodekey, setNodekey] = useState("key");

  const [update, setUpdate] = useState(false);

  // Modal stuff
  const [isOpen, setIsOpen] = useState(false);
  const [id, setID] = useState(0);
  const [name, setName] = useState("");
  const [trigger, setTrigger] = useState("");

  useEffect(() => {
    fetch(`${config.baseurl}/tickets/get_commands`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data);
        setCommands(data.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.log(error);
        alert("Fetch error:", error);
      });
  }, [update])
  
  useEffect(() => {
    if (command !== null) {
      fetch(`${config.baseurl}/tickets/get_nodes?command=${command}`, {
        method: "GET",
        credentials: "include",
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json(); // Parse the response JSON
        })
        .then((data) => {
          // Handle the response data here
          console.log(data);
          setNodes(data.data === null ? [] : JSON.parse(data.data));
          setNodekey((new Date()).toISOString());
        })
        .catch((error) => {
          // Handle any errors that occurred during the fetch
          console.log(error);
          alert("Fetch error:", error);
        });
    }
  }, [command])
  

  const [nodes, setNodes] = React.useState({})

  React.useCallback((nodes) => {
    setNodes(nodes)
  }, [])

  console.log(nodes)

  const handleClose = () => {
    setIsOpen(false);
  }

  const handleSave = () => {
    fetch(
      `${config.baseurl}/tickets/save_command`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: id,
          name: name,
          trigger: trigger
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    });

    setUpdate(!update);
    setCommand(name);
    handleClose();
  }

  const cardSyle = {
    gridColumn: "span 3",
    borderColor: colors.grey[800],
    backgroundColor: colors.primary[400],
    display: "flex", 
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "667px"
  }

  return (
    <Box display="flex" flex="1" gap="2em">
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card style={cardSyle} variant="outlined">
          <Box
            p={4}
            width="100%"
          >
            <Typography 
              variant="h4"
              color={colors.grey[300]}
              mb={1}
            >Scan kommando</Typography>

            <br />
            <TextField 
              variant="outlined" 
              label="Navn"
              onChange={event => (setName(event.target.value))} 
              value={name}
              sx={{ width: "100%" }}
            />
            <br />
            <br />
            <TextField 
              variant="outlined" 
              label="Kode"
              onChange={event => (setTrigger(event.target.value))} 
              value={trigger}
              sx={{ width: "100%" }}
            />

            <br />
            <br />
            <Button 
              variant="contained" 
              size="large" 
              color="info"
              sx={{ mt: 2 }}
              onClick={handleSave}
            >Gem</Button>
            <Button 
              variant="contained" 
              size="large" 
              sx={{ mt: 2, ml: 1}}
              onClick={() => {setIsOpen(false)}}
            >Anuller</Button>
            <Button 
              variant="contained" 
              size="large" 
              color="error"
              sx={{ mt: 2, ml: 1}}
              onClick={() => {}}
            >Slet</Button>
          </Box>
          <Box flex="1" height="100%" position="relative">
            <IconButton 
              style={{ position: "absolute", right: 0, color: colors.grey[300] }} 
              onClick={() => {setIsOpen(false)}}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
        </Card>
      </Modal>
      <Box display="flex" flexDirection="column" flex={1} gap="2em">
        {command !== null ? (
          <>
            <Box flex={1}>
              <NodeEditor
                portTypes={flumeConfig.portTypes}
                nodeTypes={flumeConfig.nodeTypes}
                nodes={nodes}
                onChange={setNodes}
                defaultNodes={[
                  {
                    type: "save",
                    x: 190,
                    y: -150
                  }
                ]}
                key={nodekey}
              />
            </Box>

            <Box display="flex" gap="2em">
              <button onClick={() => {
                fetch(
                  `${config.baseurl}/tickets/save_nodes`,
                  {
                    method: "POST",
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({
                      command: command,
                      nodeData: JSON.stringify(nodes),
                    })
                  }
                )
              }}>Gem</button>
              <button onClick={() => {
                const res = useRootEngine(nodes, engine, {});
                let toString = obj => Object.entries(obj).map(([k, v]) => `${k}: ${v}`).join('\n');
                alert("Gemmer værdi \n\n" + toString(res))
              }}>test</button>
            </Box>
          </>
        ) : (
          <></>
        )}
      </Box>

      <Box width="270px" backgroundColor={colors.primary[400]} p={1}>
        {commands.map(item => (
          <Box 
            key={item.id} 
            backgroundColor={command === item.name ? colors.greenAccent[700] : ""}
            display="flex"
            justifyContent="space-between"
            sx={{ 
              cursor: "pointer", 
              borderRadius: "5px",
              ":hover": { 
                backgroundColor: command === item.name ? colors.greenAccent[800] : colors.primary[500] 
              } 
            }}
            onClick={() => {setCommand(item.name)}}
          >
            <Typography padding={1}>{item.name}</Typography>
            <IconButton onClick={() => {setID(item.id); setName(item.name); setTrigger(item.trigger); setIsOpen(true)}} >
              <CreateIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default TicketProgrammer;
