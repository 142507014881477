import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card, Select, MenuItem, Radio } from "@mui/material";
import { tokens, themeSettings } from "../../../theme";
import { useState, useEffect, useContext } from "react";

import { AuthContext } from '../../../context/AuthContext';
import { HeadContext } from '../../../context/HeadContext';

import Row from '../components/settingsRow';

import config from "../../../config";

const Setup = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);

  const [ data, setData ] = useState(null);
  const [ align, setAlign ] = useState(0);

  useEffect(() => {
    fetch(
      `${config.baseurl}/admin/get_ticket_settings`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Parse the response JSON
    })
    .then((data) => {
      // Handle the response data here
      console.log(data);
      setData(data.data);
      setAlign(data.data.barcode_align);
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      alert("Fetch error:", error);
    });
  }, [])

  if (data === null) {
    return (
      <></>
    )
  }

  return (
    <>
      <Row name="Stregkode afgrænsningstegn">
        <TextField 
          name="code_determiner" 
          variant="outlined" 
          placeholder="$"
          style={{
            width: "300px",
            marginRight: "2em"
          }}
          defaultValue={data.code_determiner}
        /> 
      </Row>
      <Row name="Kommando starttegn">
        <TextField 
          name="command_determiner" 
          variant="outlined" 
          placeholder="%"
          style={{
            width: "300px",
            marginRight: "2em"
          }}
          defaultValue={data.command_determiner}
        /> 
      </Row>
      <Row name="Stregkode alignment">
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" width="130px">
          <Radio name="align1" value={align === "1" ? "1" : "0"} onClick={() => {setAlign("1")}} checked={align === "1"} />
          <Radio name="align2" value={align === "2" ? "1" : "0"} onClick={() => {setAlign("2")}} checked={align === "2"} />
          <Radio name="align3" value={align === "3" ? "1" : "0"} onClick={() => {setAlign("3")}} checked={align === "3"} />
          <Radio name="align4" value={align === "4" ? "1" : "0"} onClick={() => {setAlign("4")}} checked={align === "4"} />
          <Radio name="align5" value={align === "5" ? "1" : "0"} onClick={() => {setAlign("5")}} checked={align === "5"} />
          <Radio name="align6" value={align === "6" ? "1" : "0"} onClick={() => {setAlign("6")}} checked={align === "6"} />
          <Radio name="align7" value={align === "7" ? "1" : "0"} onClick={() => {setAlign("7")}} checked={align === "7"} />
          <Radio name="align8" value={align === "8" ? "1" : "0"} onClick={() => {setAlign("8")}} checked={align === "8"} />
          <Radio name="align9" value={align === "9" ? "1" : "0"} onClick={() => {setAlign("9")}} checked={align === "9"} />
        </Box>
      </Row>
      <Row name="Stregkode rotation">
        <Select
          name="barcode_rotation"
          sx={{
            width: "300px",
            height: "30px"
          }}
          defaultValue={""+data.barcode_rotation}
        >
          <MenuItem value="0" key="1">0 grader</MenuItem>
          <MenuItem value="90" key="2">90 grader</MenuItem>
          <MenuItem value="180" key="3">180 grader</MenuItem>
          <MenuItem value="270" key="4">270 grader</MenuItem>
        </Select>
      </Row>
    </>
  )
}

export default Setup;
