import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card, FormControlLabel, RadioGroup, Radio, Checkbox, Dialog, DialogTitle, DialogActions, DialogContent, Select, MenuItem } from "@mui/material";
import { tokens, themeSettings } from "../../../theme";
import { useState, useEffect, useContext, useRef } from "react";

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import LoadingButton from '@mui/lab/LoadingButton';

import { AuthContext } from '../../../context/AuthContext';
import { HeadContext } from '../../../context/HeadContext';
import { useNavigate, useParams } from 'react-router-dom';

import { RootEngine } from 'flume';
import { useRootEngine } from 'flume';

import Ticket from "../../../components/ticket";

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

import config from "../../../config";

import { flumeConfig, resolvePorts } from "../flumeConfig";

const resolveNodes = (node, inputValues, nodeType, context) => {
  switch (node.type) {
    case 'string':
      return { string: inputValues.string }
    case 'boolean':
      return { boolean: inputValues.boolean }
    case 'number':
      return { number: inputValues.number }
    case 'add':
      return { number: inputValues.num1 + inputValues.num2 }
    case 'fetch_int':
      let xhr = new XMLHttpRequest();
      xhr.open("GET", `${config.baseurl}/tickets/get_data?name=${inputValues.title}`, false);
      xhr.withCredentials = true;

      xhr.send();

      let data = JSON.parse(xhr.responseText);
      console.log(data)

      return {number: parseInt(data.data)}
    case "input":
      return { string: prompt(inputValues.title)}
    default:
      return inputValues
  }
}

const engine = new RootEngine(flumeConfig, resolvePorts, resolveNodes)

const TicketLookup = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const params = useParams();
  const inputRef = useRef(null);

  const [ code, setCode ] = useState("");
  const [ data, setData ] = useState({});
  const [ update, setUpdate ] = useState(false);
  const [ ticketCode, setTicketCode ] = useState("");
  const [ commandMode, setCommandMode ] = useState(false);
  const [ commands, setCommands ] = useState([]);

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);
  const code_start_token = auth.token.settings.tickets.code_determiner;

  useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.children[1].firstChild.focus();
    }
  })

  useEffect(() => {
    fetch(
      `${config.baseurl}/tickets/get_ticket`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: ticketCode,
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
        
      return response.json(); // Parse the response JSON
    })
    .then(data => {
      // Handle the response data here
      console.log(data);
      if (data.status) {
        setData(data.data)
      } else {
        alert("Invalid login.")
      }
    })
    .catch(error => {
      // Handle any errors that occurred during the fetch
      alert('Fetch error:', error);
    });
  }, [ticketCode, update])

  useEffect(() => {
    head.setData({location: ["FrNet", "Biletter", "Scan"]});
    getData()
  }, [])

  const getData = () => {
    fetch(`${config.baseurl}/tickets/get_commands`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data);
        setCommands(data.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.log(error);
        alert("Fetch error:", error);
      });
  }

  const run_command = (node_str) => {
    console.log("RUNNING COMMAND")
    let nodes = JSON.parse(node_str);
    const res = useRootEngine(nodes, engine, {});
    console.log(res, res?.str !== undefined ? res.str : res.num)

    fetch(
      `${config.baseurl}/tickets/save_ticket_data`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: data.id,
          name: res.name,
          value: res?.str !== undefined ? res.str : res.num,
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setUpdate(!update)
    })
  }

  useEffect(() => {
    if (!commandMode && code.split(code_start_token).length === 2) {
      setCode(code_start_token+code.split(code_start_token)[1]);
    }
    if (code.split(code_start_token).length === 3) {
      // Enable commandmode if command is ran
      if (data.id !== -1 && commands.map(i => auth.token.settings.tickets.command_determiner + i.trigger).includes(code.split(code_start_token)[1])) {
        let trigger = code.split(code_start_token)[1].substr(1)
        let hits = commands.filter(i => i.trigger === trigger)
        // If we got one and only one hit
        if (hits.length === 1) {
          run_command(hits[0].nodes)
        }
        setCode("");

      // Remove the start, end and command tags from scanned commands
      } else if (commandMode) {
        setCode(code.split(code_start_token).map((item) => (item.substr(1, item.length))).join(""));

      // If a normal ticket has been scanned
      } else if (!commandMode) {
        setTicketCode(code.split(code_start_token)[1]);
        setCode(code.split(code_start_token)[1]);
      }
    }
  }, [code])

  return (
    <>
      <form onSubmit={(e) => {e.preventDefault(); setTicketCode(e.target[0].value)}}>
        <Box display="flex" gap="2en" mb="2em">
          <TextField 
            variant="outlined" 
            label={commandMode ? "Indtast kommando" : "Indtast billetkode"}
            onChange={event => (setCode(event.target.value))} 
            value={code}
            ref={inputRef}
            style={{
              flex: 1,
              backgroundColor: commandMode ? colors.greenAccent[900] : "unset",
              paddingRight: "2em"
            }}
          />
          <Button
            variant="contained" 
            size="large" 
            type="submit"
          >Søg</Button>
        </Box>
      </form>
      <Ticket id={data.id} code={ticketCode} data={data.data} last_scan={data.last_scan} name={data.name} email={data.email} />
    </>
  )
}

export default TicketLookup;
