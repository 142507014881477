import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card } from "@mui/material";
import { tokens, themeSettings } from "../../../theme";
import { useState, useEffect, useContext } from "react";

import { AuthContext } from '../../../context/AuthContext';
import { HeadContext } from "../../../context/HeadContext";
import { formatPhoneNumber, format_seconds, range } from "../../../utils/numbers";
import { DataGrid, GridFooter } from '@mui/x-data-grid';

import { RowSpace, Column, ColumnItem } from "../../../components/calenderGraph";

import config from "../../../config";
import { useNavigate } from "react-router-dom";

const VolunShifts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);

  const [data, setData] = useState([]);

  useEffect(() => {
    head.setData({location: ["FrNet", "Shifts"]});
  }, [])

  useEffect(() => {
    fetch(`${config.baseurl}/volunteer/get_shifts`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data);
        setData(data.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        alert("Fetch error:", error);
      });
  }, []);

  if (data.length === 0) {
    return <></>
  }

  return (
    <>
      <Box 
        display="flex"
        flexDirection="row"
        gap="20px"
        width="100%"
        height="calc(100vh - 4em - 52.56px)"
      >
        <RowSpace columns={range(0, auth.token.settings.festival_settings.festival_length).map((i) => (`Dag ${i+1}`))} scrollTo="09:00">
          {range(0, auth.token.settings.festival_settings.festival_length).map((i) => (
            <Column>
              {data.map((item) => (
                parseInt(item.start.split(" ")[1]) === i+1 ? (
                  <ColumnItem 
                    key={item.id} 
                    name={item.area} 
                    start={item.start} 
                    stop={item.stop} 
                    length={item.length} 
                    onClick={() => navigate(`/volunteer/shift/${item.id}`)} 
                    sx={{ cursor: "pointer" }}
                  />
                ) : (<></>)
              ))}
            </Column>
          ))}
        </RowSpace>
      </Box>
    </>
  )
}

export default VolunShifts;
