import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Card,
  Box,
  Typography,
  useTheme,
  Modal,
  Button,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Table
} from "@mui/material";
import { tokens, themeSettings } from "../../../theme";

import TimeSelect from "../../../components/timeSelect";
import EditShiftModal from "../../../components/editShiftModal";
import Person from "../../../components/person";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Person2 from '@mui/icons-material/Person2';

import config from "../../../config";
import { check_time_in, get_time_length, timestamp_convert, convert_timestamp } from "../../../utils/timeFunc";
import { better_round } from "../../../utils/numbers";

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useNavigate } from "react-router-dom";


function range(start, end, step = 1) {
  const result = [];
  for (let i = start; i < end; i += step) {
    result.push(i);
  }
  return result;
}

const Spreadsheet = ({ group, people, update }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [ shifts, setShifts ] = useState([]);
  const [ isOpen, setIsOpen ] = useState(false);
  const [ modalData, setModalData ] = useState({});
  const [ count, setCount ] = useState(0);

  useEffect(() => {
    fetch(`${config.baseurl}/group/get_timezones?id=${group}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        setShifts(data.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.log(error);
        alert("Fetch error:", error);
      });
  }, [count, update])

  const doUpdate = () => {
    setCount(count+1);
  }

  // timeZonesid, start, stop, area, personId, length, name

  return (
    <>
      <EditShiftModal isOpen={isOpen} setIsOpen={setIsOpen} data={modalData} doReload={doUpdate} people={people} group={group} />
      <Box 
        pb="6em"
      >
        <TableContainer sx={{ flex: 1 }} component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Område</TableCell>
                <TableCell>Frivillig</TableCell>
                <TableCell>Start</TableCell>
                <TableCell>Slut</TableCell>
                <TableCell>Vægt</TableCell>
                <TableCell>Vagtgrænse</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shifts.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ 
                    '&:last-child td, &:last-child th': { border: 0 }, 
                    backgroundColor: row.personId === null ? colors.redAccent[900] : "unset" 
                  }}
                >
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.area}</TableCell>
                  <TableCell><Person name={row.name} id={row.personId} tlf={row.number} notes={row.notes} /></TableCell>
                  <TableCell>{row.start}</TableCell>
                  <TableCell>{row.stop}</TableCell>
                  <TableCell>{row.length}</TableCell>
                  <TableCell>{row.shiftNum}</TableCell>
                  <TableCell width="10px">
                    <Box display="flex" gap="10px">
                      <IconButton sx={{ margin: "-5px" }} onClick={() => {navigate(`/swap/${row.id}`)}}>
                        <SwapHorizIcon />
                      </IconButton>
                      <IconButton sx={{ margin: "-5px" }} onClick={() => {setModalData(row); setIsOpen(true)}}>
                        <ModeEditOutlineOutlinedIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        
      </Box>
    </>
  )
}

export default Spreadsheet;
