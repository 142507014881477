import { Select, MenuItem, Box, Button, IconButton, Typography, useTheme, Table, TextField, Card, FormControlLabel, Checkbox, Dialog, DialogTitle, DialogActions, DialogContent, SnackbarContent } from "@mui/material";
import { tokens, themeSettings } from "../../theme";
import { useState, useEffect, useContext, memo } from "react";

import { AuthContext } from '../../context/AuthContext';
import { HeadContext } from '../../context/HeadContext';
import { useNavigate, useParams } from 'react-router-dom';

import { Header, IndexColumn, Row, RowItem, RowSpace } from "../../components/gantt2";
import { check_time_in, get_time_length, timestamp_convert, convert_timestamp } from "../../utils/timeFunc";
import { debounce, isEqual } from 'lodash';

import config from "../../config";
import { SnackBarContext } from '../../context/SnackBarContext';

const LSelector = ({
  send_data, 
  setOpen, 
  open, 
  data, 
  checkedOverlap, 
  setCheckedOverlap, 
  setDoFetch, 
  setCheckedGroups, 
  checkedGroups, 
  swapId, 
  swapName,
  handle_group_check,
  doFetch,
  shiftCount,
  setShiftCount,
  shiftTime,
  setShiftTime
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={open}
      >
        <DialogTitle>Godkend overførlsen</DialogTitle>
        <DialogContent dividers>
          {swapId === -1 ? (
            <Typography>Er du sikker på at du gerne vil ligge vagten fra <strong>{data?.shift?.person_name}</strong> til <strong>{swapName} uden at bytte med en anden vagt?</strong></Typography>
          ) : (
            <Typography>Er du sikker på at du gerne vil bytte vagten fra <strong>{data?.shift?.person_name}</strong> til <strong>{swapName}</strong>?</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {send_data()}}>Ja</Button>
          <Button autoFocus onClick={() => {setOpen(false)}}>
            Nej
          </Button>
        </DialogActions>
      </Dialog>
      <Box 
        display="flex" 
        gap="2em" 
        width="-webkit-fill-available"
      >
        <Box display="flex" flexWrap="wrap" flex="3" alignItems="start">
          {data.groups.map((row) => (
            <FormControlLabel 
              control={<Checkbox checked={checkedGroups.includes(row.id)} onChange={() => handle_group_check(row.id)} />} 
              label={row.name} 
              sx={{ width: "180px" }}
            />
          ))}
        </Box>
        <Box borderRight={`1px ${colors.grey[800]} solid`} />
        <Box display="flex" flexDirection="column" flex="1" alignItems="start">
          <FormControlLabel 
            control={<Checkbox checked={checkedOverlap} onChange={() => {setCheckedOverlap(!checkedOverlap)}} />} 
            label="Skjul overlap" 
            sx={{ width: "180px" }}
          />
          <Box display="flex" gap="1em" alignItems="center">
            <Typography>Begræns til mindre end</Typography>
            <Select
              sx={{
                width: "110px",
                height: "30px"
              }}
              value={shiftCount}
              onChange={(e) => {setShiftCount(e.target.value)}}
            >
              <MenuItem value="-1" key="-1">-- vagter</MenuItem>
              <MenuItem value="0" key="0"> 0 vagter</MenuItem>
              <MenuItem value="1" key="1">1 vagt</MenuItem>
              <MenuItem value="2" key="2">2 vagter</MenuItem>
              <MenuItem value="3" key="3">3 vagter</MenuItem>
              <MenuItem value="4" key="4">4 vagter</MenuItem>
              <MenuItem value="5" key="5">5 vagter</MenuItem>
              <MenuItem value="6" key="6">6 vagter</MenuItem>
              <MenuItem value="7" key="7">7 vagter</MenuItem>
            </Select>
          </Box>
          <Box display="flex" gap="1em" alignItems="center" mt="1em">
            <Typography>Begræns til mindre end</Typography>
            <Select
              sx={{
                width: "110px",
                height: "30px"
              }}
              value={shiftTime}
              onChange={(e) => {setShiftTime(e.target.value)}}
            >
              <MenuItem value="-1" key="-1">-- timer</MenuItem>
              <MenuItem value="0" key="0"> 0 timer</MenuItem>
              <MenuItem value="0.5" key="0.5"> 0.5 timer</MenuItem>
              <MenuItem value="1" key="1">1 timer</MenuItem>
              <MenuItem value="1.5" key="1.5">1.5 timer</MenuItem>
              <MenuItem value="2" key="2">2 timer</MenuItem>
              <MenuItem value="2.5" key="2.5">2.5 timer</MenuItem>
              <MenuItem value="3" key="3">3 timer</MenuItem>
              <MenuItem value="3.5" key="3.5">3.5 timer</MenuItem>
              <MenuItem value="4" key="4">4 timer</MenuItem>
              <MenuItem value="4.5" key="4.5">4.5 timer</MenuItem>
              <MenuItem value="5" key="5">5 timer</MenuItem>
              <MenuItem value="5.5" key="5.5">5.5 timer</MenuItem>
              <MenuItem value="6" key="6">6 timer</MenuItem>
              <MenuItem value="6.5" key="6.5">6.5 timer</MenuItem>
              <MenuItem value="7" key="7">7 timer</MenuItem>
              <MenuItem value="7.5" key="7.5">7.5 timer</MenuItem>
              <MenuItem value="8" key="8">8 timer</MenuItem>
              <MenuItem value="8.5" key="8.5">8.5 timer</MenuItem>
              <MenuItem value="9" key="9">9 timer</MenuItem>
              <MenuItem value="9.5" key="9.5">9.5 timer</MenuItem>
              <MenuItem value="10" key="10">10 timer</MenuItem>
              {/*<MenuItem value="10.5" key="10.5">10.5 timer</MenuItem>
              <MenuItem value="11" key="11">11 timer</MenuItem>
              <MenuItem value="11.5" key="11.5">11.5 timer</MenuItem>
              <MenuItem value="12" key="12">12 timer</MenuItem>
              <MenuItem value="12.5" key="12.5">12.5 timer</MenuItem>
              <MenuItem value="13" key="13">13 timer</MenuItem>
              <MenuItem value="13.5" key="13.5">13.5 timer</MenuItem>
              <MenuItem value="14" key="14">14 timer</MenuItem>
              <MenuItem value="14.5" key="14.5">14.5 timer</MenuItem>
              <MenuItem value="15" key="15">15 timer</MenuItem>
              <MenuItem value="15.5" key="15.5">15.5 timer</MenuItem>
              <MenuItem value="16" key="16">16 timer</MenuItem>
              <MenuItem value="16.5" key="16.5">16.5 timer</MenuItem>
              <MenuItem value="17" key="17">17 timer</MenuItem>
              <MenuItem value="17.5" key="17.5">17.5 timer</MenuItem>
              <MenuItem value="18" key="18">18 timer</MenuItem>
              <MenuItem value="18.5" key="18.5">18.5 timer</MenuItem>
              <MenuItem value="19" key="19">19 timer</MenuItem>
              <MenuItem value="19.5" key="19.5">19.5 timer</MenuItem>
              <MenuItem value="20" key="20">20 timer</MenuItem>*/}
            </Select>
          </Box>
        </Box>
      </Box>
      <Box pb="2em" pt="1em" display="flex" gap="2em" justifyContent="space-between">
        <Box display="flex" gap="2em">
          <Button
            variant="contained" 
            size="large" 
            onClick={() => {setCheckedGroups(data.groups.map((i) => (i.id)));}}
          >Tjek alle</Button>
          <Button
            variant="contained" 
            size="large" 
            onClick={() => {setCheckedGroups([])}}
          >Tjek ingen</Button>
        </Box>
        <Button
          variant="contained" 
          size="large" 
          color="info"
          onClick={() => {setDoFetch(!doFetch)}}
        >opdater</Button>
      </Box>
    </>
  )
}

function compareProps(prevProps, nextProps) {
  return isEqual(prevProps, nextProps)
}

const Selector = memo(LSelector, compareProps);

const IndexSettings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const snackBar = useContext(SnackBarContext);

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);

  const [ activeRow, setActiveRow ] = useState(-1);
  const [ data, setData ] = useState({rows: [], people: [], groups: []});
  const [ doFetch, setDoFetch ] = useState(false);
  const [ page, setPage ] = useState(0);

  const [ checkedOverlap, setCheckedOverlap ] = useState(true);
  const [ checkedGroups, setCheckedGroups ] = useState([-1]);
  const [ shiftCount, setShiftCount ] = useState(-1);
  const [ shiftTime, setShiftTime ] = useState(-1);

  const [ open, setOpen ] = useState(false);
  const [ swapId, setSwapId ] = useState(-1);
  const [ swapPersonId, setSwapPersonId ] = useState("");
  const [ swapName, setSwapName ] = useState("");

  useEffect(() => {
    fetch(
      `${config.baseurl}/group/get_shifts_swap`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          shift_id: id, 
          page: page,
          checks: [
            checkedOverlap ? "overlap" : "",
          ],
          shiftCount: shiftCount,
          shiftTime: shiftTime,
          groups: checkedGroups
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Parse the response JSON
    })
    .then((data) => {
      // Handle the response data here
      console.log(data);
      setData(data.data);
      if (checkedGroups.includes(-1)) {
        setCheckedGroups(data.data.groups.map((i) => (i.id)));
      }
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      alert("Fetch error:", error);
    });
  }, [doFetch, page])

  useEffect(() => {
    head.setData({location: ["FrNet", "Byt vagt", id]});
  }, [])

  const handle_callback = (row, time) => {
    console.log(row, time)
    const person = data.people[row];
    const person_shifts = person.shifts;
    var selectedItem = null;

    for (let i = 0; i < person_shifts.length; i++) {
      if (check_time_in(person_shifts[i].start, person_shifts[i].stop, time)) {
        selectedItem = person_shifts[i];
      }
    }

    if (selectedItem !== null) {
      setSwapId(selectedItem.id);
    } else {
      setSwapId(-1);
    }
    setSwapName(person.name);
    setSwapPersonId(person.id);
    setOpen(true);
  }

  const send_data = () => {
    fetch(
      `${config.baseurl}/group/swap_shifts`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          from: id,
          to: swapId,
          to_person: swapPersonId
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      snackBar.setText(swapId !== -1 ? "Byttede 2 vagter" : "Overførte vagten")
      navigate(-1);
    })
  }

  const handle_group_check = (id) => {
    console.log(checkedGroups);
    const tmp = checkedGroups.slice(0, checkedGroups.length);

    if (tmp.includes(id)) {
      tmp.splice(tmp.indexOf(id), 1);
      setCheckedGroups(tmp);
    } else {
      tmp.push(id);
      setCheckedGroups(tmp);
    }
  }

  return (
    <>
      <Selector 
        send_data={send_data}
        setOpen={setOpen}
        open={open}
        data={data}
        checkedOverlap={checkedOverlap}
        setCheckedOverlap={setCheckedOverlap}
        setDoFetch={setDoFetch}
        setCheckedGroups={setCheckedGroups}
        checkedGroups={checkedGroups}
        swapId={swapId}
        swapName={swapName}
        handle_group_check={handle_group_check}
        doFetch={doFetch}
        shiftCount={shiftCount}
        setShiftCount={setShiftCount}
        shiftTime={shiftTime}
        setShiftTime={setShiftTime}
      /> 

      <Card variant="outlined" className="gantt" sx={{ marginBottom: "6em" }}>
        <Box display="flex">
          <IndexColumn data={data.rows} />
          <Box overflow="scroll">
            <Box>
              <Header />
              <RowSpace callback={handle_callback} setActiveRow={setActiveRow} sx={{ cursor: "pointer" }}>
                {data.people.map(row => (
                  <Row active={row.name === data.rows[activeRow]?.name}>
                    {
                      data.shift?.person_id === -1 ? (
                        <RowItem 
                          name={data.shift?.area}
                          tstart={data.shift?.start} 
                          tend={data.shift?.stop} 
                          parts={1} 
                          id={data.shift?.id} 
                          color="red"
                        />
                      ) : (
                        data.person_shifts.map(item => (
                          <RowItem 
                            name={item?.area !== undefined ? item.area : item.desc}
                            tstart={item.start} 
                            tend={item.stop} 
                            parts={1} 
                            id={item.id} 
                            color={item.id === data.shift.id ? "red" : "grey"}
                          />
                        ))
                      )
                    }
                    {row.shifts.map(item => (
                      <RowItem 
                        name={item.area} 
                        tstart={item.start} 
                        tend={item.stop} 
                        parts={1} 
                        id={item.id} 
                      />
                    ))}
                    {row.taken.map(item => (
                      <RowItem 
                        name={item.desc} 
                        tstart={item.start} 
                        tend={item.stop} 
                        parts={1} 
                        id={item.id} 
                        color="blue"
                      />
                    ))}
                    {row.stage_items.map(item => (
                      <RowItem 
                        name={item.item_name} 
                        tstart={item.start} 
                        tend={item.stop} 
                        parts={1} 
                        id={item.id} 
                        color="blue"
                      />
                    ))}
                  </Row>
                ))}
              </RowSpace>
            </Box>
          </Box>
        </Box>
      </Card>
      <Box 
        display="flex" 
        gap="2em" 
        pt="2em"
        pb="2em"
        position="fixed"
        justifyContent="space-between"
        bottom="0em"
        width="-webkit-fill-available"
        backgroundColor={theme.palette.mode === 'dark' ? colors.primary[500] : "#fcfcfc"}
        zIndex="200"
      >
        <Button
          variant="contained" 
          size="large" 
          onClick={() => {navigate(-1)}}
        >Annuler</Button>
        <Box pr="2em" display="flex" gap="1em" alignItems="center">
          <Button
            size="large" 
            onClick={() => {setPage(Math.max(0, page-1))}}
          >{"<"}</Button>
          <Typography>{page+1}/{data.pages+1}</Typography>
          <Button
            size="large" 
            onClick={() => {setPage(Math.min(data.pages, page+1))}}
          >{">"}</Button>
        </Box>
      </Box>
    </>
  )
}

export default IndexSettings;
