import React, { useState, useEffect, useContext, useCallback } from "react";
import { Typography, useTheme, Chip , Box, Popover, Card, Avatar, Modal, TextField, Button, IconButton, Autocomplete } from "@mui/material";
import { tokens } from "../theme.js";

import TimeSelect from "./timeSelect";
import Phone from "./phone";
import Person from "./person";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Person2 from '@mui/icons-material/Person2';

import { get_time_length } from "../utils/timeFunc";
import { better_round } from "../utils/numbers";
import config from "../config";
import { useNavigate } from "react-router-dom";

/* {
    "area": "ved indgangen i infoteltet",
    "id": 4107,
    "length": 90,
    "name": "Agnes",
    "notes": "OKAY SO HEAR ME OUT HERE one minue, this is the pilots speaking, this should wrapt the text field nicely so that it all formats out gud",
    "number": "51956098",
    "personId": 449453,
    "start": "16:00 01",
    "stop": "17:30 01"
}
*/

const EditShiftModal = ({ isOpen, setIsOpen, data, doReload, people, group }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  console.log(people)

  const [ activeId, setActiveId ] = useState(-1);
  const [ area, setArea ] = useState("");
  const [ startTimeModal, setStartTimeModal ] = useState("00:00 1");
  const [ endTimeModal, setEndTimeModal ] = useState("00:00 1");
  const [ doUpdate, setDoUpdate ] = useState(false);

  const [ activePersonId, setActivePersonId ] = useState("");
  const [ activePerson, setActivePerson ] = useState({
    idperson: 0,
    name: "",
    number: ""
});


  useEffect(() => {
    console.log("Opned", data);
    if (isOpen) {
      setActiveId(data.id);
      setArea(data.area);
      setStartTimeModal(data.start);
      setEndTimeModal(data.stop);
      setDoUpdate(!doUpdate);
      setActivePersonId(data.personId);
      for (let i = 0; i < people.length; i++) {
        if (people[i].idperson == data.personId) {
          setActivePerson(people[i]);
        }
      }
    }
  }, [data])

  const handleClose = () => {
    setIsOpen(false);
  }

  const cardSyle = {
    gridColumn: "span 3",
    borderColor: colors.grey[800],
    backgroundColor: colors.primary[400],
    display: "flex", 
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "667px"
  }

  const handleSaveModal = () => {
    fetch(
      `${config.baseurl}/group/update_shift`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: activeId,
          start: startTimeModal, 
          stop: endTimeModal, 
          area: area,
          personId: activePersonId,
          group: group,
          length: get_time_length(startTimeModal, endTimeModal)*15
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setIsOpen(false);
        
      return response.json(); // Parse the response JSON
    })
    .then(data => {
      // Handle the response data here
      console.log(data);
      if (data.status) {
        doReload();
      } else {
        alert("Invalid login.");
      }
    })
    .catch(error => {
      // Handle any errors that occurred during the fetch
      console.log(error);
      alert('Fetch error:', error);
    });
  }

  if (!isOpen) {
    return <></>
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card style={cardSyle} variant="outlined">
          <Box
            p={4}
            width="100%"
          >
            <Typography 
              variant="h4"
              color={colors.grey[300]}
              mb={1}
            >Timezone opsætning</Typography>

            <Box 
              display="flex"
              alignItems="center"
              gap="4px"
              pb={1}
              height="40px"
            >
              <Typography 
                fontWeight={500}
                width="68px"
                lineHeight="40px"
              >Mødested</Typography>
              <TextField 
                id="standard-basic" 
                variant="outlined" 
                onChange={event => setArea(event.target.value)} 
                value={area}
                style={{
                  marginBottom: "15px",
                  padding: "2px",
                  width: "529px",
                  height: "30px",
                  transform: "translateY(5px)"
                }}
                sx={{
                  input: {padding: "0px", paddingLeft: "10px", height: "30px"}
                }}
              />
            </Box>

            <Box
              display="flex"
              width="100%"
              gap="10px"
            >
              <Box>
                <TimeSelect label="Start" time={startTimeModal} setTime={setStartTimeModal} update={doUpdate}/>
                <TimeSelect label="Slut" time={endTimeModal} setTime={setEndTimeModal} update={doUpdate} />
                <Typography 
                  fontWeight={500}
                  width="260px"
                  color={get_time_length(startTimeModal, endTimeModal)/4 <= 0 ? colors.redAccent[500] : "unset"}
                >Vagtlængde: {better_round(get_time_length(startTimeModal, endTimeModal)/4, 2)} timer</Typography>
              </Box>
              <Box borderRight={`1px ${colors.grey[800]} solid`} />
              <Box>
                <Box mb={1}>
                  <Autocomplete 
                    sx={{ 
                      width: 300, 
                    }}
                    options={people}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    onChange={(e, a) => {
                      setActivePersonId(a.id);
                      setActivePerson(a);
                    }}
                    value={activePerson}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.name} ({option.idperson}) 
                          <Phone number={option.number} props={{ fontWeight: 200 }}/>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tilsluttet person"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                    isOptionEqualToValue={(option, value) => (option.idperson == value.idperson)}
                  />
                </Box>
                <Person 
                  id={activePerson.idperson} 
                  name={activePerson.name} 
                  tlf={activePerson.number} 
                  notes="e" 
                  sx={{
                    backgroundColor: colors.grey[900],
                    height: "30px",
                    ".MuiTypography-body1": {
                      fontSize: "13.5px !important"
                    }
                  }} 
                />
              </Box>
            </Box>
            <Button 
              variant="contained" 
              size="large" 
              color="info"
              disabled={get_time_length(startTimeModal, endTimeModal)/4 <= 0}
              sx={{ mt: 2 }}
              onClick={() => {handleSaveModal()}}
            >Gem</Button>
            <Button 
              variant="contained" 
              size="large" 
              sx={{ mt: 2, ml: 1}}
              onClick={() => {setIsOpen(false)}}
            >Anuller</Button>
            <Button 
              variant="contained" 
              size="large" 
              color="error"
              sx={{ mt: 2, ml: 1}}
              onClick={() => {}}
            >Slet</Button>
            <Button 
              variant="contained" 
              size="large" 
              color="error"
              sx={{ mt: 2, ml: 1}}
              onClick={() => {navigate(`/swap/${activeId}`)}}
            >Byt</Button>
          </Box>
          <Box flex="1" height="100%" position="relative">
            <IconButton 
              style={{ position: "absolute", right: 0, color: colors.grey[300] }} 
              onClick={() => {setIsOpen(false)}}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
        </Card>
      </Modal>
    </>
  )
};

export default EditShiftModal;
