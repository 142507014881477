import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card, Select, MenuItem, Tooltip } from "@mui/material";
import { tokens, themeSettings } from "../../../theme";
import { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';
import { DataGrid, GridFooter } from '@mui/x-data-grid';

import { check_time_in, get_time_length, timestamp_convert, convert_timestamp } from "../../../utils/timeFunc";
import { formatPhoneNumber } from "../../../utils/numbers";

import { AuthContext } from '../../../context/AuthContext';
import { HeadContext } from '../../../context/HeadContext';
import { Header, IndexColumn, Row, RowItem, RowSpace } from "../../../components/gantt2";
import { useNavigate, Link } from "react-router-dom";

import config from "../../../config";

const columns = [
  { 
    field: 'id', 
    headerName: 'ID', 
    type: 'number',
    flex: 0.6,
  },
  {
    field: 'group_name',
    headerName: 'Gruppe',
    editable: false,
    flex: 1,
  },
  {
    field: 'area',
    headerName: 'Mødested',
    editable: false,
    flex: 1,
  },
  {
    field: 'start',
    headerName: 'Start',
    editable: false,
    flex: 0.5,
  },
  {
    field: 'stop',
    headerName: 'Stop',
    editable: false,
    flex: 0.5,
  },
  {
    field: 'swap_note',
    headerName: 'Årsag',
    editable: false,
    flex: 2,
  },
  {
    field: 'volunteer_name',
    headerName: 'Navn',
    editable: false,
    flex: 1,
  },
  {
    field: 'volunteer_number',
    headerName: 'Nummer',
    editable: false,
    flex: 0.6,
    renderCell: (params) => {
      if (params.aggregation && !params.aggregation.hasCellUnit) {
        return params.formattedValue;
      }
      return formatPhoneNumber(params.formattedValue);
    },
  },
  {
    field: 'swap_count',
    headerName: 'Status',
    editable: false,
    flex: 0.8,
    renderCell: (params) => {
      if (params.aggregation && !params.aggregation.hasCellUnit) {
        return params.formattedValue;
      }
      return `✅ (${params.row.swap_count} tilbud)`;
    },
  }
];

const UserSwaps = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const params = useParams();
  const navigate = useNavigate();

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);

  const [ data, setData ] = useState([]);

  const getData = () => {
    fetch(`${config.baseurl}/person/get_user_swaps`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data);
        setData(data.data)
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.log(error);
        alert("Fetch error:", error);
      });
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Box display="flex" flexDirection="column" flex="1">
        <DataGrid
          rows={data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          density="compact"
          pageSizeOptions={[20, 50, 100]}
          disableRowSelectionOnClick
          disableSelectionOnClick
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
             outline: "none !important",
            },
            "& .marked": {
              backgroundColor: colors.grey[900]+"88",
            }
          }}
          getCellClassName={(params) => {
            if (params.field === 'swap_count') {
              return params.row.swap_count == 0 ? 'marked' : '';
            }
          }}
        />
    </Box>
  )
}

export default UserSwaps;
