import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card } from "@mui/material";
import { tokens, themeSettings } from "../../../theme";
import { useState, useEffect, useContext } from "react";

import { AuthContext } from '../../../context/AuthContext';
import { HeadContext } from "../../../context/HeadContext";
import { formatPhoneNumber, format_seconds } from "../../../utils/numbers";
import { DataGrid, GridFooter } from '@mui/x-data-grid';
import Badge from "../../../components/badge";

import config from "../../../config";
import { useNavigate } from "react-router-dom";

const columns2 = [
  { 
    field: 'id', 
    headerName: 'ID', 
    type: 'number',
    flex: 0.5,
  },
  {
    field: 'group_name',
    headerName: 'Gruppe',
    editable: false,
    flex: 0.7,
  },
  {
    field: 'start',
    headerName: 'Start',
    editable: false,
    flex: 0.5,
  },
  {
    field: 'stop',
    headerName: 'Stop',
    editable: false,
    flex: 0.5,
  },
  {
    field: 'area',
    headerName: 'Område',
    editable: false,
    flex: 1,
  },
  {
    field: 'length',
    headerName: 'Længde',
    type: 'number',
    editable: false,
    flex: 0.5,
    renderCell: (params) => {
      if (params.aggregation && !params.aggregation.hasCellUnit) {
        return params.formattedValue;
      }
      return format_seconds(parseInt(params.formattedValue.replace(",", "")*60), "hours");
    },
  },
  {
    field: 'type',
    headerName: 'type',
    editable: false,
    flex: 0.7,
    renderCell: (params) => {
      if (params.aggregation && !params.aggregation.hasCellUnit) {
        return params.formattedValue;
      }
      return <Badge text={params.formattedValue} type={params.formattedValue === "Bytte" ? "purple" : "green"} />;
    },
  },
];
const columns = [
  { 
    field: 'id', 
    headerName: 'ID', 
    type: 'number',
    flex: 0.5,
  },
  {
    field: 'group_name',
    headerName: 'Gruppe',
    editable: false,
    flex: 0.7,
  },
  {
    field: 'start',
    headerName: 'Start',
    editable: false,
    flex: 0.5,
  },
  {
    field: 'stop',
    headerName: 'Stop',
    editable: false,
    flex: 0.5,
  },
  {
    field: 'area',
    headerName: 'Område',
    editable: false,
    flex: 1,
  },
  {
    field: 'length',
    headerName: 'Længde',
    type: 'number',
    editable: false,
    flex: 0.5,
    renderCell: (params) => {
      if (params.aggregation && !params.aggregation.hasCellUnit) {
        return params.formattedValue;
      }
      return format_seconds(parseInt(params.formattedValue.replace(",", "")*60), "hours");
    },
  },
  {
    field: 'swap_state',
    headerName: 'Bytte udgivet',
    editable: false,
    flex: 0.5,
    renderCell: (params) => {
      if (params.aggregation && !params.aggregation.hasCellUnit) {
        return params.formattedValue;
      }
      return (params.formattedValue == "1" ? "✅" : "❌") + (params.row.swap_count != 0 ? ` (${params.row.swap_count} tilbud)` : "");
    },
  }
];

const VolunIndex = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);

  const [data, setData] = useState({
    takeable: [],
    shifts: [],
    groups: []
  });

  useEffect(() => {
    head.setData({location: ["FrNet", "Dashboard"]});
  }, [])

  useEffect(() => {
    fetch(`${config.baseurl}/volunteer/get_dashboard`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data);
        setData(data.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        alert("Fetch error:", error);
      });
  }, []);

  return (
    <>
      <Box 
        position="absolute"
        top="52.56px"
        left="0"
        width="100vw"
        height="calc(100vh - 52.56px)"
        className="cool-gradient"
        zIndex="-2"
      />
      <Box 
        display="flex"
        flexDirection="row"
        gap="20px"
        width="100%"
        height="calc(100vh - 4em - 52.56px)"
      >
        <Box 
          display="flex"
          flexDirection="column"
          gap="20px"
          flex="2"
        >
          <Card variant="outlined"  
            style={{
              backgroundColor: colors.primary[400], 
              gridColumn: "span 8", 
              gridRow: "span 3", 
              padding: "2em 2em",
              flex: "2",
              display: "flex",
              flexDirection: "column"
            }}
            sx={{ boxShadow: 1 }}
          >
            <Typography 
              fontWeight={500}
              variant="h5"
              mb="1em"
            >Dine vagter</Typography>
            <Box flex="1" height="calc(50vh - 130px - 2em)">
              <DataGrid
                rows={data.shifts}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 20,
                    },
                  },
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                    },
                  },
                }}
                density="compact"
                pageSizeOptions={[20, 50, 100]}
                onRowClick={(params, event, details) => {navigate(`/volunteer/shift/${params.row.id}`)}}
                disableRowSelectionOnClick
                disableSelectionOnClick
                sx={{
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                   outline: "none !important",
                  },
                  "& .marked": {
                    backgroundColor: colors.redAccent[800]
                  }
                }}
              />
            </Box>
          </Card>
          <Card variant="outlined"  
            style={{
              backgroundColor: colors.primary[400], 
              gridColumn: "span 8", 
              gridRow: "span 2", 
              padding: "2em 2em",
              flex: "2",
              display: "flex",
              flexDirection: "column"
            }}
            sx={{ boxShadow: 1 }}
          >
            <Typography 
              fontWeight={500}
              variant="h5"
              mb="1em"
            >Åbne vagter</Typography>
            <Box flex="1" height="calc(50vh - 130px - 2em)">
              <DataGrid
                rows={data.takeable}
                columns={columns2}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 20,
                    },
                  },
                  columns: {
                    columnVisibilityModel: {
                      id: false,
                    },
                  },
                }}
                density="compact"
                pageSizeOptions={[20, 50, 100]}
                onRowClick={(params, event, details) => {navigate(`/volunteer/shift/${params.row.id}`)}}
                disableRowSelectionOnClick
                disableSelectionOnClick
                sx={{
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                   outline: "none !important",
                  },
                  "& .marked": {
                    backgroundColor: colors.redAccent[800]
                  }
                }}
              />
            </Box>
          </Card>
        </Box>



        <Box 
          display="flex"
          flexDirection="column"
          gap="20px"
          flex="1"
        >
          <Card variant="outlined"  
            style={{
              backgroundColor: colors.primary[400], 
              gridColumn: "span 4", 
              gridRow: "span 2", 
              padding: "2em 2em",
              flex: "2" 
            }}
            sx={{ boxShadow: 1 }}
          >
            <Typography 
              fontWeight={500}
              variant="h5"
              mb="1em"
            >Dine kontaktpersoner</Typography>
            {data.groups.map((item) => (
              <Box 
                p="1em"
                mt="1em"
                borderRadius="5px"
                backgroundColor={theme.palette.mode === 'dark' ? colors.primary[500] : "#fcfcfc"}
                key={item.id}
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography>{item.name}</Typography>
                  <Typography>{item.ansName}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>{item.radio !== null ? `Radio kanal: ${item.radio}` : ""}</Typography>
                  <Typography>{formatPhoneNumber(item.ansNumber)}</Typography>
                </Box>
              </Box>
            ))}
          </Card>
          <Card variant="outlined"  
            style={{
              backgroundColor: colors.primary[400], 
              gridColumn: "span 4", 
              gridRow: "span 3", 
              padding: "2em 2em",
              flex: "4" 
            }}
            sx={{ boxShadow: 1 }}
          >
            <Typography 
              fontWeight={500}
              variant="h5"
              mb="1em"
            >Beskeder</Typography>
            {"..."}
          </Card>
        </Box>
      </Box>
    </>
  )
}

export default VolunIndex;
