
import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Box,
  Typography,
  useTheme
} from "@mui/material";

import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';

import { tokens, themeSettings } from "../../../theme";
import { ResponsiveBar } from '@nivo/bar'
import StatBox from "../../../components/statsBox";
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';

import config from "../../../config";

const DateTime = ({data}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const cardSettins = {
    backgroundColor: colors.primary[400], 
    gridColumn: "span 3", 
    gridRow: "span 1", 
    padding: "1em 2em" 
  }

  return (
    <>
      <Card variant="outlined" style={cardSettins} >
        <Box style={{ "text": {fill: "#FF000"}, height: "-webkit-fill-available" }}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">{`${data?.day_start}`}</Typography>
            <Typography variant="h5">{Math.abs(data?.current_time === undefined ? 0 : data.current_time.split(" ")[1])} dage</Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" height="-webkit-fill-available" pb="2em">
            <Typography variant="h1">{data?.current_time === undefined ? "0:0" : data?.current_time.split(" ")[0]}</Typography>
          </Box>
        </Box>
      </Card>
    </>
  )
}

export default DateTime;
