import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Card,
  Box,
  Typography,
  useTheme,
  Modal,
  Button,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { tokens, themeSettings } from "../../../theme";

import { Header, IndexColumn, Row, RowItem, RowSpace } from "../../../components/gantt2";
import TimeSelect from "../../../components/timeSelect";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Person2 from '@mui/icons-material/Person2';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';

import config from "../../../config";
import { check_time_in, get_time_length, timestamp_convert, convert_timestamp } from "../../../utils/timeFunc";
import { better_round } from "../../../utils/numbers";
import { AuthContext } from "../../../context/AuthContext";

function range(start, end, step = 1) {
  const result = [];
  for (let i = start; i < end; i += step) {
    result.push(i);
  }
  return result;
}

const Gantt = ({ data, setData, group, update }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const auth = useContext(AuthContext);

  const [ names, setNames ] = useState([]);
  const [ indexNames, setIndexNames ] = useState([]);
  const [ times, setTimes ] = useState({});
  const [ activeRow, setActiveRow ] = useState(-1);
  const [ isOpen, setIsOpen ] = useState(false);
  const [ doUpdate, setDoUpdate ] = useState(false);

  const [ activeId, setActiveId ] = useState(-1);
  const [ area, setArea ] = useState("");
  const [ startTimeModal, setStartTimeModal ] = useState("00:00 1");
  const [ endTimeModal, setEndTimeModal ] = useState("00:00 1");
  const [ vagter, setVagter ] = useState(1);
  const [ voulenteers, setVoulenteers ] = useState(1);
  const [ shiftNum, setShiftNum ] = useState(auth.token.settings.shift_mgr.shiftlimt_default);
  const [ volunteerClaimable, setVolunteerClaimable] = useState(false);
  const [ volunteerSwapable, setVolunteerSwapable] = useState(false);

  const [ recommendEnd, setrecommendEnd ] = useState("00:00 01");
  const [ recommendEnd1, setrecommendEnd1 ] = useState("00:00 01");
  const [ recommendEnd2, setrecommendEnd2 ] = useState("00:00 01");
  const [ recommendEnd3, setrecommendEnd3 ] = useState("00:00 01");

  const [ rowName, setRowName ] = useState("");

  const [ timeMenuIsOpen, setTimeMenuIsOpen ] = useState(false);

  const [ shiftTimes, setShiftTimes ] = useState([]);

  useEffect(() => {
    if (![startTimeModal, endTimeModal, vagter, voulenteers].includes(undefined)) {
      var length = get_time_length(startTimeModal, endTimeModal) * 900000 /vagter;
      var tmp = [];
      for (let i = 0; i < vagter; i++) {
        tmp.push(timestamp_convert(convert_timestamp(startTimeModal) + length*i));
      }
      setShiftTimes(tmp);
      
      setrecommendEnd(timestamp_convert(convert_timestamp(startTimeModal) + vagter * 5400000));  // 1000*60*60*1.5
      setrecommendEnd1(timestamp_convert(convert_timestamp(startTimeModal) + vagter * 7200000)); // 1000*60*60*2
      setrecommendEnd2(timestamp_convert(convert_timestamp(startTimeModal) + vagter * 9000000)); // 1000*60*60*2.5
      setrecommendEnd3(timestamp_convert(convert_timestamp(startTimeModal) + vagter * 10800000)); // 1000*60*60*3
    }
  }, [startTimeModal, endTimeModal, vagter, voulenteers])

  useEffect(() => {
    console.log("Dataing", data)
    if (data){
      setNames([]);
      var collector = [];

      for (let i = 0; i < data.length; i++) {
        if (!collector.includes(data[i].area)) {
          collector.push(data[i].area);
        }
      }

      setNames(collector);
    }
  }, [data]);

  useEffect(() => {
    console.log("Naming", names)
    if (names && names.length) {
      setIndexNames(names.map(item => ({name: item})));

      var tmpTimes = times;
      for (let i = 0; i < names.length; i++) {
        tmpTimes[names[i]] = []
      }
      for (let i = 0; i < data.length; i++) {
        tmpTimes[data[i].area].push(data[i])
      }

      console.log(times)
      setTimes(tmpTimes);
    }
  }, [names])

  const handleClose = () => {
    setIsOpen(false);
  }

  const handleDeleteModal = () => {
    fetch(
      `${config.baseurl}/group/delete_zone`,
      {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: activeId,
          group: group
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setIsOpen(false);
        
      return response.json(); // Parse the response JSON
    })
    .then(data => {
      // Handle the response data here
      console.log(data);
      if (data.status) {
        setData(data.data)
      } else {
        alert("Invalid login.")
      }
    })
    .catch(error => {
      // Handle any errors that occurred during the fetch
      alert('Fetch error:', error);
    });
  }

  const handleSaveModal = () => {
    fetch(
      `${config.baseurl}/group/update_zone`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          id: activeId,
          start: startTimeModal, 
          stop: endTimeModal, 
          vagter: vagter, 
          voulenteers: voulenteers,
          area: area,
          group: group,
          shiftNum: shiftNum
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setIsOpen(false);
        
      return response.json(); // Parse the response JSON
    })
    .then(data => {
      // Handle the response data here
      console.log(data);
      if (data.status) {
        setData(data.data)
      } else {
        alert("Invalid login.")
      }
    })
    .catch(error => {
      // Handle any errors that occurred during the fetch
      alert('Fetch error:', error);
    });
  }

  const cardSyle = {
    gridColumn: "span 3",
    borderColor: colors.grey[800],
    backgroundColor: colors.primary[400],
    display: "flex", 
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  }

  const handle_callback = (row, time) => {
    const rowData = times[names[row]];
    var selectedRow = null

    for (let i = 0; i < rowData.length; i++) {
      if (check_time_in(rowData[i].start, rowData[i].stop, time)) {
        selectedRow = rowData[i]
      }
    }
    console.log("Selected", selectedRow)
    if (selectedRow === null) {
      console.log("Create new")
      setStartTimeModal(time);
      setEndTimeModal(time);
      setVagter(1);
      setVoulenteers(1);
      setActiveId(-1);
      setArea(names[row]);
      setVolunteerClaimable(false);
      setVolunteerSwapable(false);
      setShiftNum(""+auth.token.settings.shift_mgr.shiftlimit_default);
    } else {
      console.log("Edit")
      setStartTimeModal(selectedRow.start);
      setEndTimeModal(selectedRow.stop);
      setVagter(selectedRow.amount);
      setVoulenteers(selectedRow.voulenteers);
      setActiveId(selectedRow.id);
      setArea(names[row]);
      setShiftNum(selectedRow.shiftNum);
      setVolunteerClaimable(selectedRow.volunteer_claimable);
      setVolunteerSwapable(selectedRow.volunteer_swapable);
    }
    setIsOpen(true);
    console.log(row)
  } 



  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card style={cardSyle} variant="outlined">
          <Box
            p={4}
            width="100%"
          >
            <Typography 
              variant="h4"
              color={colors.grey[300]}
              mb={1}
            >Timezone opsætning</Typography>

            <Box 
              display="flex"
              alignItems="center"
              gap="4px"
              pb={1}
              height="40px"
            >
              <Typography 
                fontWeight={500}
                width="68px"
                lineHeight="40px"
              >Mødested</Typography>
              <TextField 
                id="standard-basic" 
                variant="outlined" 
                onChange={event => setArea(event.target.value)} 
                value={area}
                style={{
                  marginBottom: "15px",
                  padding: "2px",
                  width: "575px",
                  height: "30px",
                  transform: "translateY(5px)"
                }}
                sx={{
                  input: {padding: "0px", paddingLeft: "10px", height: "30px"}
                }}
              />
            </Box>

            <Box
              display="flex"
              width="100%"
              gap="10px"
            >
              <Box>
                <TimeSelect label="Start" time={startTimeModal} setTime={setStartTimeModal} update={doUpdate}/>
                <TimeSelect label="Slut" time={endTimeModal} setTime={setEndTimeModal} update={doUpdate} />
                <Box 
                  display="flex"
                  alignItems="center"
                  gap="4px"
                  mb={1}
                >
                  <Typography 
                    fontWeight={500}
                    width="118px"
                  >Antal Vagter</Typography>
                  <Select
                    sx={{
                      width: "65px",
                      height: "30px"
                    }}
                    value={vagter}
                    onChange={(e) => setVagter(e.target.value)}
                  >
                  {range(0,10).map((i) => (
                    <MenuItem value={`${i+1}`} key={`${i+1}`}>{`${i+1}`}</MenuItem>
                  ))}
                  </Select>
                </Box>
                <Box 
                  display="flex"
                  alignItems="center"
                  gap="4px"
                  mb={1}
                >
                  <Typography 
                    fontWeight={500}
                    width="118px"
                  >Antal Frivillige</Typography>
                  <Select
                    sx={{
                      width: "65px",
                      height: "30px"
                    }}
                    value={voulenteers}
                    onChange={(e) => setVoulenteers(e.target.value)}
                  >
                  {range(0,8).map((i) => (
                    <MenuItem value={`${i+1}`} key={`${i+1}`}>{`${i+1}`}</MenuItem>
                  ))}
                  </Select>
                </Box>
                <hr />
                <Typography 
                  fontWeight={500}
                  width="260px"
                >Vagtlængde: {better_round(get_time_length(startTimeModal, endTimeModal)/4/vagter, 2)} timer</Typography>
                <Box display="flex" alignItems="center">
                  <Typography 
                    fontWeight={500}
                    lineHeight="30px"
                  >Anbefalet slut: <a href="#" onClick={() => {setEndTimeModal(recommendEnd); setDoUpdate(!doUpdate)}}>{recommendEnd}</a> (1.5 timer)</Typography>
                  {
                    timeMenuIsOpen ? 
                      <>
                        <ArrowLeft sx={{ scale: "1.5", cursor: "pointer" }} onClick={() => {setTimeMenuIsOpen(false)}} /> 
                        <Card style={{
                          borderColor: colors.grey[800],
                          backgroundColor: colors.primary[400],
                          padding: 10,
                          minWidth: "200px",
                          position: "absolute",
                          left: "296px",
                          zIndex: 200
                        }} variant="outlined">
                          <Typography 
                            fontWeight={500}
                            lineHeight="30px"
                          ><a href="#" onClick={() => {setTimeMenuIsOpen(false); setEndTimeModal(recommendEnd1); setDoUpdate(!doUpdate)}}>{recommendEnd1}</a> (2 timer)</Typography>
                          <Typography 
                            fontWeight={500}
                            lineHeight="30px"
                          ><a href="#" onClick={() => {setTimeMenuIsOpen(false); setEndTimeModal(recommendEnd2); setDoUpdate(!doUpdate)}}>{recommendEnd2}</a> (2.5 timer)</Typography>
                          <Typography 
                            fontWeight={500}
                            lineHeight="30px"
                          ><a href="#" onClick={() => {setTimeMenuIsOpen(false); setEndTimeModal(recommendEnd3); setDoUpdate(!doUpdate)}}>{recommendEnd3}</a> (3 timer)</Typography>
                        </Card>
                      </>
                    : 
                      <ArrowRight sx={{ scale: "1.5", cursor: "pointer" }} onClick={() => {setTimeMenuIsOpen(true)}} />
                  }

                </Box>
                <hr />
                <Box 
                  display="flex"
                  alignItems="center"
                  gap="4px"
                  mb={1}
                  title="En frivillig må ikke have flere vagter end ... vagter for at få tilgivet denne vagt"
                >
                  <Typography 
                    fontWeight={500}
                    width="118px"
                  >Vagtgrænse</Typography>
                  <Select
                    sx={{
                      width: "65px",
                      height: "30px"
                    }}
                    value={shiftNum}
                    onChange={(e) => setShiftNum(e.target.value)}
                  >
                  {range(0,8).map((i) => (
                    <MenuItem value={`${i+1}`} key={`${i+1}`}>{`${i+1}`}</MenuItem>
                  ))}
                  </Select>
                </Box>
                <Button 
                  variant="contained" 
                  size="large" 
                  color="info"
                  sx={{ mt: 2 }}
                  onClick={() => {handleSaveModal()}}
                >Gem</Button>
                <Button 
                  variant="contained" 
                  size="large" 
                  sx={{ mt: 2, ml: 1}}
                  onClick={() => {setIsOpen(false)}}
                >Anuller</Button>
                <Button 
                  variant="contained" 
                  size="large" 
                  color="error"
                  sx={{ mt: 2, ml: 1}}
                  onClick={() => {handleDeleteModal()}}
                >Slet</Button>
              </Box>
              {/*<Card style={{
                flex: 1,
                borderColor: colors.grey[800],
                backgroundColor: colors.primary[400],
                padding: 10,
                minWidth: "200px"
              }} variant="outlined">
                <Box 
                  display="flex"
                  width="100%"
                  height="100%"
                >
                  {range(0,vagter).map((x) => (
                    <Box 
                      display="flex"
                      flexDirection="column"
                      height="100%"
                    >
                    {range(0,voulenteers).map((y) => (
                      <Person2 sx={{fill: colors.grey[200] }} />
                    ))}
                    </Box>
                  ))}
                </Box>
              </Card>*/}
              <Box borderRight={`1px ${colors.grey[800]} solid`} />
              
              <Box width={shiftTimes.length > 5 ? "380px": "180px"}>
                <Typography 
                  fontWeight={500}
                >Vagtfordeling</Typography>
                <Box
                  textAlign="center"
                  gap="5px"
                  display="flex"
                  flexDirection="column"
                  flexWrap="wrap"
                  height="260px"
                >
                  {shiftTimes.map((item) => (
                    <Box 
                      display="flex"
                      alignItems="center"
                      gap="4px"
                    >
                      <Box
                        textAlign="center"
                        width="90px"
                        height="30px"
                        mb="5px"
                        backgroundColor={colors.grey[200]}
                        color={colors.primary[400]}
                        borderRadius="4px"
                      >
                        <Typography 
                          fontWeight={500}
                          lineHeight="30px"
                        >{item}</Typography>
                      </Box>

                      <Box display="flex" mb="6px" flexWrap="wrap" width="85px" height="42px">
                        {range(0,voulenteers).map((y) => (
                          <Person2 sx={{fill: colors.grey[200] }} />
                        ))}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box borderRight={`1px ${colors.grey[800]} solid`} />
              <Box width="300px">
                <Typography 
                  fontWeight={500}
                >Vagtbytteopsætning</Typography>
                <FormControlLabel 
                  title="Må den frivillige selv kunne mælde sig på vagten?" 
                  control={<Switch value={volunteerClaimable} />} 
                  label="Frivillig tilgængelig" 
                /> <br />
                <FormControlLabel 
                  title="Må de frivillige kunne bytte vagten internt?" 
                  control={<Switch value={volunteerSwapable} />} 
                  label="Frivillig byttelig" 
                />
              </Box>
            </Box>
          </Box>
          <Box flex="1" height="100%" position="relative">
            <IconButton 
              style={{ position: "absolute", right: 0, color: colors.grey[300] }} 
              onClick={() => {setIsOpen(false)}}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
        </Card>
      </Modal>

      <Card variant="outlined" className="gantt" sx={{ marginBottom: "2em" }}>
        <Box display="flex">
          <IndexColumn data={indexNames} />
          <Box overflow="scroll">
            <Box>
              <Header />
              <RowSpace callback={handle_callback} setActiveRow={setActiveRow} sx={{ cursor: "pointer" }}>
                {names.map(name => (
                  <Row active={name === names[activeRow]}>
                    {data.map(item => (
                      item.area === name ? (
                        <RowItem 
                          key={item.id}
                          name={item.start.split(" ")[0]} 
                          tstart={item.start} 
                          tend={item.stop} 
                          parts={item.amount} 
                          id={item.id} 
                          marked={!item.opdateret} 
                        />
                      ) : (
                        <></>
                      )
                    ))}
                  </Row>
                ))}
              </RowSpace>
            </Box>
          </Box>
        </Box>
      </Card>

      <Box display="flex" gap="2em">
        <TextField 
          id="standard-basic" 
          variant="outlined" 
          label="Mødested"
          onChange={event => setRowName(event.target.value)} 
          value={rowName}
          style={{
            width: "375px",
          }}
        />
        <Button
          variant="contained" 
          size="large" 
          onClick={() => {setNames([...names, rowName]); setTimes({...times, rowname: []})}}
        >Tilføj område</Button>
      </Box>
    </>
  )
}

export default Gantt;
