import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card, Select, MenuItem } from "@mui/material";
import { tokens, themeSettings } from "../../../theme";
import { useState, useEffect, useContext } from "react";

import { AuthContext } from '../../../context/AuthContext';
import { HeadContext } from '../../../context/HeadContext';

import Row from '../components/settingsRow';

import config from "../../../config";

const Setup = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);

  const [ data, setData ] = useState(null);

  useEffect(() => {
    fetch(
      `${config.baseurl}/admin/get_festival_settings`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Parse the response JSON
    })
    .then((data) => {
      // Handle the response data here
      console.log(data);
      setData(data.data);
    })
    .catch((error) => {
      // Handle any errors that occurred during the fetch
      alert("Fetch error:", error);
    });
  }, [])

  if (data === null) {
    return (
      <></>
    )
  }

  return (
    <>
      <Row name="Start Dato">
        <TextField 
          name="start_date" 
          variant="outlined" 
          placeholder="16/6"
          style={{
            width: "300px",
            marginRight: "2em"
          }}
          defaultValue={data.start_date}
        /> 
      </Row>
      <Row name="Tids offset">
        <Select
          name="time_offset"
          sx={{
            width: "300px",
            height: "30px"
          }}
          defaultValue={data.time_offset}
        >
          <MenuItem value="0" key="0">0</MenuItem>
          <MenuItem value="1" key="1">1</MenuItem>
          <MenuItem value="2" key="2">2</MenuItem>
          <MenuItem value="3" key="3">3</MenuItem>
          <MenuItem value="4" key="4">4</MenuItem>
          <MenuItem value="5" key="5">5</MenuItem>
          <MenuItem value="6" key="6">6</MenuItem>
          <MenuItem value="7" key="7">7</MenuItem>
          <MenuItem value="8" key="8">8</MenuItem>
          <MenuItem value="9" key="9">9</MenuItem>
          <MenuItem value="10" key="10">10</MenuItem>
          <MenuItem value="11" key="11">11</MenuItem>
          <MenuItem value="12" key="12">12</MenuItem>
          <MenuItem value="13" key="13">13</MenuItem>
          <MenuItem value="14" key="14">14</MenuItem>
          <MenuItem value="15" key="15">15</MenuItem>
          <MenuItem value="16" key="16">16</MenuItem>
          <MenuItem value="17" key="17">17</MenuItem>
          <MenuItem value="18" key="18">18</MenuItem>
          <MenuItem value="19" key="19">19</MenuItem>
          <MenuItem value="20" key="20">20</MenuItem>
          <MenuItem value="21" key="21">21</MenuItem>
          <MenuItem value="22" key="22">22</MenuItem>
          <MenuItem value="23" key="23">23</MenuItem>
        </Select>
      </Row>
      <Row name="Festival længde i dage">
        <Select
          name="day_count"
          sx={{
            width: "300px",
            height: "30px"
          }}
          defaultValue={data.day_count}
        >
          <MenuItem value="1" key="1">1 Dag</MenuItem>
          <MenuItem value="2" key="2">2 Dage</MenuItem>
          <MenuItem value="3" key="3">3 Dage</MenuItem>
          <MenuItem value="4" key="4">4 Dage</MenuItem>
          <MenuItem value="5" key="5">5 Dage</MenuItem>
          <MenuItem value="6" key="6">6 Dage</MenuItem>
          <MenuItem value="7" key="7">7 Dage</MenuItem>
          <MenuItem value="8" key="8">8 Dage</MenuItem>
          <MenuItem value="9" key="9">9 Dage</MenuItem>
          <MenuItem value="10" key="10">10 Dage</MenuItem>
        </Select>
      </Row>
    </>
  )
}

export default Setup;
