import { memo, useState, useEffect, useContext, useCallback, useRef } from "react";
import React from 'react';
import {
  Card,
  Box,
  Typography,
  useTheme,
  Tooltip,
} from "@mui/material";
import { tokens, themeSettings } from "../theme";
import { debounce, isEqual } from 'lodash';
import Draggable from "react-draggable";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { range, format_number, better_round } from "../utils/numbers";
import { convert_timestamp, get_time_length, timestamp_convert, floatToTimestamp } from "../utils/timeFunc";
import { AuthContext } from "../context/AuthContext";

import config from "../config";

const widthscale = 1.4;
const heightscale = 1;

export const RowSpace = ({children, sx, columns, scrollTo, onClick}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const auth = useContext(AuthContext);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef?.current !== null) {
      scrollRef.current.scrollIntoView();
    }
  }, [scrollRef])

  const [ oldPos, setOldPos ] = useState([0,0]);
  const [ offset, setOffset ] = useState(0);
  const offsetRef = useRef(null);

  const handleMouseMove = (e) => { 
    if (Math.abs(oldPos[1] - e.clientY) > 10) {
      //setActiveRow(Math.ceil((e.clientY - e.target.getBoundingClientRect().y) / (40 * heightscale)) - 1);
      setOffset(Math.ceil((e.clientY - offsetRef.current.getBoundingClientRect().y)/35));
      setOldPos([e.clientX, e.clientY]);
    }
  }

  const handleClick = (e) => { 
    try {
      if (e.target?.className?.includes("column") && onClick) {
        const y = Math.ceil((e.clientY - offsetRef.current.getBoundingClientRect().y)/35);
        const timestamp = floatToTimestamp((y-1)/4);

        const x = Math.ceil((e.clientX - offsetRef.current.getBoundingClientRect().x-110));
        const width = (offsetRef.current.getBoundingClientRect().width-110);
        const columnWidth = width / children.length;
        const column = columns[Math.ceil(x/columnWidth-1)];

        onClick(timestamp, column);
      }
    } catch {

    }
  }

  return (
    <Box 
      position="relative"
      sx={sx}
      display="flex"
      flexDirection="column"
      overflow="overlay"
      width="100%"
      onMouseMove={handleMouseMove}
      onClick={handleClick}
    >
      <Box 
        position="absolute"
        top={`${offset*35+12}px`}
        width="100%"
        height="35px"
        backgroundColor={colors.grey[800]+"44"}
      />
      <Box
        style={{
          pointerEvents: "none",
        }}
        ref={offsetRef}
        width="100%"
        height={`${35*24*4}px`}
        position="absolute"
        top="48px"
        left="0"
      >
        {range(0,24).map((h) => (
          range(0,4).map((m) => {
            return (
              <Box 
                width="100%"
                height="35px"
                borderBottom={`${m !== 3 ? '1' : '2'}px ${colors.grey[800]} solid`}
              />
            )
          })
        ))}
      </Box>

      <Box position="sticky" top="0" display="flex" backgroundColor={colors.greenAccent[600]} zIndex={20}>
        <Box width="110px" p="1em">
          <Typography>Tidspunkt</Typography>
        </Box>
        {columns.map((i) => (
          <Box flex="1" p="1em">
            <Typography>{i.name}</Typography>
          </Box>
        ))}
      </Box>

      <Box display="flex" width="100%" className="column-container">
        <Box 
          width="110px"
        >
          {range(0,24).map((h) => (
            range(0,4).map((m) => {
              if (h+1 === parseInt(scrollTo.split(":")[0]) && m*15 === parseInt(scrollTo.split(":")[1])) {
                return (
                  <Box 
                    backgroundColor={colors.primary[400]} 
                    pt="0.5em" 
                    pl="1em" 
                    height="35px" 
                    ref={scrollRef}
                  >
                    <Typography>{format_number(h, 2)}:{format_number(m*15, 2)}</Typography>
                  </Box>
                )
              } else {
                return (
                  <Box 
                    backgroundColor={colors.primary[400]} 
                    pt="0.5em" 
                    pl="1em" 
                    height="35px" 
                  >
                    <Typography>{format_number(h, 2)}:{format_number(m*15, 2)}</Typography>
                  </Box>
                )
              }
            })
          ))}
        </Box>
        {children}
      </Box>
    </Box>
  )
}

export const LColumn = ({ children, sx }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box borderLeft={`1px ${colors.grey[900]} solid`} flex={1} position="relative" className="column" sx={sx}>
      {children}
    </Box>
  )
}

export const Column = memo(LColumn);

export const ColumnItem = ({ name, start, stop, length, onClick, sx }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const top_offset = (convert_timestamp(start) - convert_timestamp("00:00 " + start.split(" ")[1])) / 1000 / 60 / 15 
  const shift_length = get_time_length(start, stop);

  return (
    <Box 
      position="absolute" 
      top={`${top_offset * 35}px`}
      backgroundColor={colors.blueAccent[800]}
      border={`1px ${colors.blueAccent[700]} solid`}
      width="100%"
      height={`${35*shift_length}px`}
      padding="1em"
      borderRadius="5px"
      overflow="hidden"
      onClick={onClick}
      sx={sx}
    >
      <Typography fontSize="16px" pb="0.5em">Mød op {name}</Typography>
      <Typography fontSize="12px">{start.split(" ")[0]} - {stop.split(" ")[0]}</Typography>
      <Typography fontSize="12px">({better_round(length/60, 2)} timer)</Typography>
    </Box>
  )
}

// A dragable column item
export const LColumnStageItem = ({ name, start, stop, test_length, onClick, sx, save_drag }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const top_offset = (convert_timestamp(start) - convert_timestamp("00:00 " + start.split(" ")[1])) / 1000 / 60 / 15 
  const shift_length = get_time_length(start, stop);

  const [ isDragging, setIsDragging ] = useState(false);
  const [ y, setY ] = useState(top_offset*35);
  const dragRef = useRef(null);

  useEffect(() => {
    if (isDragging) {
      const start = floatToTimestamp((y/35)/4)
      const stop = floatToTimestamp((y/35+shift_length)/4)
      console.log(start, stop)

      if (save_drag) {
        save_drag(start, stop)
      }
    }
  }, [y])

  return (
    <>
      <Draggable 
        grid={[35, 35]} 
        axis='y'
        handle=".handle"
        onStart={(e, data) => (setIsDragging(true))}
        onStop={(e, data) => {
          setTimeout(() => setIsDragging(false), 200)
          setY(data.y+test_length*35);
        }}
        style={{
          zIndex: isDragging ? 50 : 5 ,
          position: "absolute"
        }}
        position={{x: 0, y: y-test_length*35}}
      >
        <Box position="relative" height="0">
          <Box 
            position="absolute"
            backgroundColor={colors.blueAccent[800]}
            border={`1px ${colors.blueAccent[700]} solid`}
            width="100%"
            height={`${test_length*35}px`}
            borderRadius="5px"
            onClick={!isDragging ? onClick : undefined}
            zIndex={isDragging ? 50 : 5}
            sx={{
              ...sx,
              background: `repeating-linear-gradient(-45deg, ${colors.blueAccent[800]}, ${colors.blueAccent[800]} 4px, black 4px, black 8px)`
            }}
          />
          <Box 
            position="absolute"
            top={`${test_length*35}px`}
            backgroundColor={colors.blueAccent[800]}
            border={`1px ${colors.blueAccent[700]} solid`}
            width="100%"
            height={`${35*shift_length}px`}
            padding="1em"
            borderRadius="5px"
            overflow="hidden"
            onClick={!isDragging ? onClick : undefined}
            zIndex={isDragging ? 50 : 5}
            sx={{
              ...sx,
            }}
          >
            <Box display="flex" justifyContent="space-between">
              <Typography fontSize="16px" pb="0.5em">{name}</Typography>
              <DragIndicatorIcon className="handle" style={{ cursor: "grab" }}/>
            </Box>
            <Typography fontSize="12px">{start.split(" ")[0]} - {stop.split(" ")[0]}</Typography>
          </Box>
        </Box>
      </Draggable>
    </>
  )
}

export const ColumnStageItem = memo(LColumnStageItem);
