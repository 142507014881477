import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card } from "@mui/material";
import { tokens, themeSettings } from "../../theme";
import { useState, useEffect, useContext } from "react";

import { AuthContext } from '../../context/AuthContext';
import { HeadContext } from '../../context/HeadContext';
import { SnackBarContext } from "../../context/SnackBarContext";

import config from "../../config";
import { NotifSnackBar } from "../../context/SnackBarContext";

import Setup from "./views/setup";
import Tickets from "./views/tickets"
import Shifts from "./views/shifts";

const RowItem = ({name, selected, setSelected}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      width="250px"
      backgroundColor={colors.primary[400]}
      p="5px"
      pl="10px"
      borderRadius="3px"
      style={{ cursor: "pointer" }}
      borderLeft={name === selected ? 
                  `solid #6870fa 5px` 
                  : 
                  `solid ${colors.primary[400]} 5px`}
      onClick={() => {setSelected(name)}}
    >
      <Typography 
        color={name === selected ? "#6870fa" : colors.grey[200]}
      >{name}</Typography>
    </Box>
  )
}

const IndexSettings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);
  const snackBar = useContext(SnackBarContext);

  const items = [
    "Festival opsætning",
    "Moduler",
    "Vagter",
    "Biletter"
  ]
  const [ selected, setSelected ] = useState(items[0]);

  useEffect(() => {
    head.setData({location: ["FrNet", "Indstillinger", selected]})
  }, [selected])

  const save = (e) => {
    const target = e.target;
    const data = {};

    for (let i = 0; i < target.length; i++) {
      if (target[i].value !== undefined) {
        data[target[i].name] = target[i].value
      }
    }

    console.log("Saving", data)

    fetch(
      `${config.baseurl}/admin/save_settings`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({data: data, category: selected})
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      snackBar.setText("Indstillinger gemt, log ind og ud før at de tager effekt")
    })
  }

  return (
    <>
      <Box 
        gap="1em"
        display="flex"
        height="100vh"
      >
        <Box 
          gap="10px"
          display="flex"
          flexDirection="column"
        >
          {items.map((name) => (
            <RowItem name={name} selected={selected} setSelected={setSelected} />
          ))}
        </Box>
        <Box flexGrow="1">
          <Typography variant="h4" style={{ marginBottom: "1em" }}>{selected}</Typography>
          <form onSubmit={(e) => {e.preventDefault(); save(e)}}>
            {selected === "Festival opsætning" ? (
              <Setup />
            ) : selected === "Biletter" ? (
              <Tickets />
            ) : selected === "Vagter" ? (
              <Shifts />
            ) : (<></>)}
            <Button
              variant="contained" 
              size="large" 
              type="submit"
            >Gem</Button>
          </form>
        </Box>
      </Box>
    </>
  )
}

export default IndexSettings;
