import { NodeEditor } from "flume";
import { FlumeConfig, Colors, Controls } from 'flume';
import { RootEngine } from 'flume';
import { useRootEngine } from 'flume';

export const flumeConfig = new FlumeConfig()
flumeConfig
  .addPortType({
    type: "string",
    name: "string",
    label: "Tekst",
    color: Colors.green,
    controls: [
      Controls.text({
        name: "string",
        label: "Text"
      })
    ]
  })
  .addPortType({
    type: "number",
    name: "number",
    label: "Tal",
    color: Colors.red,
    controls: [
      Controls.number({
        name: "number",
        label: "Number"
      })
    ]
  })
  .addNodeType({
    type: "fetch_int",
    label: "Hent værdi (int)",
    description: "Henter en værdi fra billetdataen som heltal",
    inputs: ports => [
      ports.string({
        name: "title",
        label: "Navn"
      })
    ],
    outputs: ports => [
      ports.number()
    ]
  })
  .addNodeType({
    type: "fetch_str",
    label: "Hænt værdi (str)",
    description: "Henter en værdi fra billetdataen som tekst",
    inputs: ports => [
      ports.string({
        name: "title",
        label: "Navn"
      })
    ],
    outputs: ports => [
      ports.string()
    ]
  })
  .addNodeType({
    type: "number",
    label: "Nummer",
    description: "En numerisk værdi",
    initialWidth: 160,
    inputs: ports => [
      ports.number()
    ],
    outputs: ports => [
      ports.number()
    ]
  })
  .addNodeType({
    type: "input",
    label: "User input",
    description: "Få et input fra brugeren",
    initialWidth: 160,
    inputs: ports => [
      ports.string({
        name: "title",
        label: "Navn på popup"
      })
    ],
    outputs: ports => [
      ports.string()
    ]
  })
  .addNodeType({
    type: "add",
    label: "Adder",
    description: "Lægger to tal sammen",
    initialWidth: 160,
    inputs: ports => [
      ports.number({
        name: "num1",
        label: "Number"
      }),
      ports.number({
        name: "num2",
        label: "Number"
      })
    ],
    outputs: ports => [
      ports.number()
    ]
  })
  .addRootNodeType({
    type: "save",
    label: "Gem værdi",
    description: "Gemmer et heltal",
    initialWidth: 160,
    inputs: ports => (data, conns) => {
      console.log(conns);
      let out = [
        ports.string({
          name: "name",
          label: "Navn"
        }),
      ]
      if (conns.inputs?.str === undefined) {
        out.push(ports.number({
          name: "num",
          label: "Værdi"
        }))
      }
      if (conns.inputs?.num === undefined) {
        out.push(ports.string({
          name: "str",
          label: "Værdi"
        }))
      }
      return out;
    },
    outputs: ports => [
    ]
  })


export const resolvePorts = (portType, data) => {
  switch (portType) {
    case 'string':
      return data.string
    case 'number':
      return data.number
    default:
      return data
  }
}
