import { Box, Button, IconButton, Typography, useTheme, Table, TextField, Card, Select, MenuItem, Tooltip, Checkbox, Switch, FormControlLabel, RadioGroup, Radio } from "@mui/material";
import { tokens, themeSettings } from "../../../theme";
import { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import LaunchIcon from '@mui/icons-material/Launch';

import { AuthContext } from '../../../context/AuthContext';
import { HeadContext } from '../../../context/HeadContext';
import { Header, IndexColumn, Row, RowItem, RowSpace } from "../../../components/gantt2";
import { useNavigate, Link } from "react-router-dom";

import config from "../../../config";

const PdfGroup = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const params = useParams();
  const navigate = useNavigate();

  const auth = useContext(AuthContext);
  const head = useContext(HeadContext);

  const [ groups, setGroups ] = useState([]);
  const [ sort, setSort ] = useState("time");
  const [ selected, setSelected ] = useState("");
  const [ title, setTitle ] = useState("");
  const [ URI, setURI ] = useState("");

  const getData = () => {
    fetch(`${config.baseurl}/group/get_groups`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        // Handle the response data here
        console.log(data);
        setGroups(data.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.log(error);
        alert("Fetch error:", error);
      });
  }

  useEffect(() => {
    head.setData({location: ["FrNet", "Pdf Udskrivning", "Gruppeoversigt"]});
    getData();
  }, [])

  const get_pdf = () => {
    fetch(
      `${config.baseurl}/pdf/groupspdf`,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          group: selected,
          title: title,
          sort: sort,
        })
      }
    ).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      getData();
      return response.json(); // Parse the response JSON
    })
    .then(data => {
      // Handle the response data here
      console.log(data);
      if (data.status) {
        setURI(config.baseurl +"/"+ data.file)
      } else {
        alert("Invalid login.")
      }
    })
    .catch(error => {
      // Handle any errors that occurred during the fetch
      alert('Fetch error:', error);
    });
  }
  console.log(URI)

  return (
    <>
      <Box 
        width="100%"
        height="calc(100vh - 110px)"
        display="flex"
        gap="2em"
      >
        <Box flex="2" height="100%" display="flex" flexDirection="column">
          <TextField 
            id="standard-basic" 
            label="Tittel til PDF"
            onChange={(e) => {setTitle(e.target.value)}}
            value={title}
            style={{
              width: "100%",
            }}
          />
          <Box mb="2em" mt="2em">
            <Typography fontWeight="500">Sorter efter</Typography>
              <RadioGroup
                defaultValue="time"
                row
                value={sort}
                onChange={(e) => {setSort(e.target.value)}}
              >
                <FormControlLabel value="time" control={<Radio />} label="Tidspunkt" />
                <FormControlLabel value="name" control={<Radio />} label="Navn" />
                <FormControlLabel value="area" control={<Radio />} label="Mødested" />
            </RadioGroup>
          </Box>
          <TableContainer component={Paper} style={{ flexGrow: "1", marginBottom: "2em" }}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Gruppe</TableCell>
                  <TableCell>Personer</TableCell>
                  <TableCell>Vagter</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groups.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ 
                      '&:last-child td, &:last-child th': { border: 0 },
                      cursor: "pointer"
                    }}
                    onClick={() => {setSelected(row.id); setTitle(`Oversigt over ${row.name} vagtplanen`)}}
                  >
                    <TableCell>
                      <Checkbox 
                        sx={{ margin: "-5px" }}
                        checked={selected === row.id}
                      />
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.people}</TableCell>
                    <TableCell>{row.shifts}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained" 
            size="large" 
            onClick={get_pdf}
          >Generer pdf</Button>
        </Box>

        <Box
          flex="3"
        >
          {URI ? (
            <object 
              data={URI} 
              type="application/pdf" 
              width="100%" 
              height="100%">
                <p>Ingen PDF klar enu, brug knappen "GENERER PDF"</p>
            </object>
          ) : (
            <Box
              width="100%" 
              height="100%"
              backgroundColor={colors.primary[400]}
            />
          )}
        </Box>
      </Box>
    </>
  )
}

export default PdfGroup;
